import React from 'react';
import { ArrowRight, Heart, Users, Calendar, Music, Star, Coffee } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Volunteer with AMF",
    description: "Join our community of passionate volunteers and help create extraordinary musical experiences.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  intro: {
    title: "Be Part of Something Extraordinary",
    description: "Our volunteers are the heartbeat of the Atlantic Music Festival, helping us create memorable experiences for both artists and audiences. Whether you're a music enthusiast or simply love being part of a vibrant community, there's a place for you in our volunteer family."
  },
  opportunities: [
    {
      icon: Music,
      title: "Concert Ushering",
      description: "Welcome and guide audience members, distribute programs, and help create a warm, inviting atmosphere at our performances.",
      commitment: "2-4 hours per concert"
    },
    {
      icon: Calendar,
      title: "Event Support",
      description: "Assist with setup and coordination of special events, masterclasses, and community programs throughout the festival.",
      commitment: "Flexible scheduling"
    },
    {
      icon: Users,
      title: "Community Ambassador",
      description: "Help connect our festival with the local community through outreach activities and audience development initiatives.",
      commitment: "4-6 hours per week"
    },
    {
      icon: Coffee,
      title: "Artist Hospitality",
      description: "Support our artists by helping with transportation, accommodation logistics, and creating a welcoming environment.",
      commitment: "Varies by assignment"
    },
    {
      icon: Star,
      title: "Administrative Support",
      description: "Assist our staff with various administrative tasks, from office support to festival operations.",
      commitment: "Flexible hours"
    }
  ],
  benefits: {
    title: "Volunteer Benefits",
    items: [
      "Behind-the-scenes access to festival events",
      "Invitation to volunteer appreciation events",
      "Meeting world-class musicians",
      "Contributing to your community's cultural life",
      "Building connections with fellow music lovers",
      "Professional development opportunities"
    ]
  },
  cta: {
    title: "Ready to Join Us?",
    description: "Fill out our volunteer interest form, and we'll contact you with more information about opportunities that match your interests and availability.",
    button: {
      text: "Express Interest",
      url: "/contact-us"
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    intro: {
      wrapper: "text-center max-w-3xl mx-auto mb-24",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 leading-relaxed"
    },
    opportunities: {
      grid: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8",
      card: {
        wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg group hover:bg-white transition-all duration-500",
        icon: "w-12 h-12 text-[#E63946] mb-6",
        title: "font-serif text-2xl mb-4",
        description: "text-[#1D3557]/70 leading-relaxed mb-4",
        commitment: "text-sm text-[#457B9D]"
      }
    },
    benefits: {
      wrapper: "bg-[#1D3557] text-white rounded-lg p-12",
      title: "font-serif text-3xl mb-8 text-center",
      grid: "grid grid-cols-1 md:grid-cols-2 gap-6",
      item: "flex items-center gap-3",
      icon: "text-[#E63946]"
    },
    cta: {
      wrapper: "text-center max-w-2xl mx-auto",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const Volunteer = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.intro.wrapper}>
            <h2 className={CONFIG.styles.intro.title}>{CONFIG.intro.title}</h2>
            <p className={CONFIG.styles.intro.description}>{CONFIG.intro.description}</p>
          </div>

          <div className={CONFIG.styles.opportunities.grid}>
            {CONFIG.opportunities.map((opportunity, index) => {
              const Icon = opportunity.icon;
              return (
                <div key={index} className={CONFIG.styles.opportunities.card.wrapper}>
                  <Icon className={CONFIG.styles.opportunities.card.icon} />
                  <h3 className={CONFIG.styles.opportunities.card.title}>{opportunity.title}</h3>
                  <p className={CONFIG.styles.opportunities.card.description}>{opportunity.description}</p>
                  <p className={CONFIG.styles.opportunities.card.commitment}>{opportunity.commitment}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.benefits.wrapper}>
            <h2 className={CONFIG.styles.benefits.title}>{CONFIG.benefits.title}</h2>
            <div className={CONFIG.styles.benefits.grid}>
              {CONFIG.benefits.items.map((benefit, index) => (
                <div key={index} className={CONFIG.styles.benefits.item}>
                  <Heart className={CONFIG.styles.benefits.icon} size={16} />
                  <span>{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.cta.wrapper}>
            <h2 className={CONFIG.styles.cta.title}>{CONFIG.cta.title}</h2>
            <p className={CONFIG.styles.cta.description}>{CONFIG.cta.description}</p>
            <a href={CONFIG.cta.button.url} className={CONFIG.styles.cta.button.wrapper}>
              <span className={CONFIG.styles.cta.button.text}>
                {CONFIG.cta.button.text}
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className={CONFIG.styles.cta.button.background} />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Volunteer;