import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import concertData from '../../data/concerts/concerts.json';
import ConcertHero from './components/ConcertHero';
import MonthFilter from './components/MonthFilter';
import ConcertCard from './components/ConcertCard';

const CONCERTS_PER_PAGE = 10;

const ConcertsListing = () => {
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [displayedConcerts, setDisplayedConcerts] = useState<typeof concertData>([]);
  const [page, setPage] = useState(1);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false
  });

  const concerts = concertData;
  const months = [...new Set(concerts.map(concert => 
    format(new Date(concert.time), 'MMMM yyyy')
  ))].sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  const filteredConcerts = selectedMonth
    ? concerts.filter(concert => 
        format(new Date(concert.time), 'MMMM yyyy') === selectedMonth
      )
    : concerts;

  useEffect(() => {
    setDisplayedConcerts(filteredConcerts.slice(0, page * CONCERTS_PER_PAGE));
  }, [page, selectedMonth, filteredConcerts]);

  useEffect(() => {
    if (inView && displayedConcerts.length < filteredConcerts.length) {
      setPage(prev => prev + 1);
    }
  }, [inView, displayedConcerts.length, filteredConcerts.length]);

  useEffect(() => {
    setPage(1);
  }, [selectedMonth]);

  const currentYear = new Date().getFullYear();
  const seasonYears = `${currentYear - 1}–${currentYear}`;

  return (
    <main className="min-h-screen bg-white">
      <ConcertHero seasonYears={seasonYears} />
      
      <MonthFilter
        months={months}
        selectedMonth={selectedMonth}
        onMonthSelect={setSelectedMonth}
      />

      {/* Concert Timeline */}
      <div className="max-w-screen-2xl mx-auto px-8 py-24">
        <div className="relative">
          {/* Timeline Line */}
          <div className="absolute left-0 top-0 bottom-0 w-px bg-[#1D3557]/10 md:left-1/2" />

          {/* Concert Items */}
          <div className="space-y-32">
            {displayedConcerts.map((concert, index) => (
              <ConcertCard
                key={concert.time}
                concert={concert}
                isEven={index % 2 === 0}
              />
            ))}

            {/* Infinite Scroll Trigger */}
            {displayedConcerts.length < filteredConcerts.length && (
              <div ref={ref} className="h-10" />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ConcertsListing;