import { COUNTRIES } from '../config/countries';

export const getCountries = () => {
  return COUNTRIES;
};

export const getCountryByCode = (code: string) => {
  return COUNTRIES.find(country => country.code === code);
};

export const getNationality = (countryCode: string) => {
  const country = getCountryByCode(countryCode);
  return country?.nationality || '';
};

export const formatAddress = (address: {
  street: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}): string => {
  const country = getCountryByCode(address.country);
  const parts = [
    address.street,
    address.street2,
    address.city,
    address.state,
    address.postalCode,
    country?.name
  ].filter(Boolean);

  return parts.join(', ');
};