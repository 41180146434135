import React from 'react';
import { Check } from 'lucide-react';

interface SaveIndicatorProps {
  isSaving: boolean;
  lastSaved?: Date;
}

const CONFIG = {
  styles: {
    wrapper: "fixed bottom-6 left-6 z-50 flex items-center gap-2 px-4 py-2 bg-white border border-[#1D3557]/20 rounded-md shadow-lg",
    icon: {
      wrapper: "w-4 h-4",
      saving: "animate-spin text-[#1D3557]/40",
      saved: "text-green-500"
    },
    text: "text-sm text-[#1D3557]/60"
  }
};

const SaveIndicator: React.FC<SaveIndicatorProps> = ({ isSaving, lastSaved }) => {
  if (!isSaving && !lastSaved) return null;

  return (
    <div className={CONFIG.styles.wrapper}>
      {isSaving ? (
        <>
          <svg className={CONFIG.styles.icon.saving} viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          <span className={CONFIG.styles.text}>Saving...</span>
        </>
      ) : (
        <>
          <Check className={CONFIG.styles.icon.saved} />
          <span className={CONFIG.styles.text}>
            Saved {lastSaved?.toLocaleTimeString()}
          </span>
        </>
      )}
    </div>
  );
};

export default SaveIndicator;