import React from 'react';
import { Mail, Phone, MapPin, Clock, AlertCircle } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Contact Us",
    description: "Get in touch with us for any inquiries about the Atlantic Music Festival.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  locations: {
    mailing: {
      title: "Mailing Address",
      address: [
        "Atlantic Music Festival",
        "228 Park Ave S #31975",
        "New York, NY 10003"
      ]
    }
  },
  departments: [
    {
      title: "Festival Office",
      email: "support@atlanticmusicfestival.org",
      phone: "888-704-1311",
      hours: "Weekdays, 10AM - 4PM EST",
      note: "Solicitation prohibited"
    },
    {
      title: "Admissions Office",
      email: "admissions@atlanticmusicfestival.org",
      phone: "888-704-1311",
      hours: "Weekdays, 10AM - 4PM EST",
      important: "Please submit questions by email until you receive your audition result for the most efficient response."
    }
  ],
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    grid: "grid grid-cols-1 md:grid-cols-2 gap-16",
    card: {
      wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg",
      title: "font-serif text-2xl mb-6",
      content: "space-y-6"
    },
    address: {
      line: "text-[#1D3557]/70"
    },
    contact: {
      item: "flex items-center gap-3 text-[#1D3557]/70",
      icon: "text-[#E63946]"
    },
    important: {
      wrapper: "bg-[#E63946]/10 p-4 rounded-lg",
      text: "text-[#E63946] flex items-start gap-2"
    }
  }
};

const Contact = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      {/* Mailing Address */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.card.wrapper}>
            <h2 className={CONFIG.styles.card.title}>{CONFIG.locations.mailing.title}</h2>
            <div className={CONFIG.styles.card.content}>
              <div className={CONFIG.styles.contact.item}>
                <MapPin className={CONFIG.styles.contact.icon} size={20} />
                <div>
                  {CONFIG.locations.mailing.address.map((line, index) => (
                    <div key={index} className={CONFIG.styles.address.line}>{line}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Information */}
      <section className={`${CONFIG.styles.section} bg-[#1D3557]/[0.02]`}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.grid}>
            {CONFIG.departments.map((dept, index) => (
              <div key={index} className={CONFIG.styles.card.wrapper}>
                <h2 className={CONFIG.styles.card.title}>{dept.title}</h2>
                <div className={CONFIG.styles.card.content}>
                  {dept.important && (
                    <div className={CONFIG.styles.important.wrapper}>
                      <p className={CONFIG.styles.important.text}>
                        <AlertCircle size={20} />
                        <span>{dept.important}</span>
                      </p>
                    </div>
                  )}
                  
                  <div className={CONFIG.styles.contact.item}>
                    <Mail className={CONFIG.styles.contact.icon} size={20} />
                    <span>{dept.email}</span>
                  </div>

                  <div className={CONFIG.styles.contact.item}>
                    <Phone className={CONFIG.styles.contact.icon} size={20} />
                    <span>{dept.phone}</span>
                  </div>

                  <div className={CONFIG.styles.contact.item}>
                    <Clock className={CONFIG.styles.contact.icon} size={20} />
                    <span>{dept.hours}</span>
                  </div>

                  {dept.note && (
                    <p className="text-sm text-[#1D3557]/60 italic">
                      {dept.note}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;