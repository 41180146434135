import React from 'react';
import FormField from '../FormField';
import { COUNTRIES } from '../../../config/countries';
import { US_STATES } from '../../../config/states';

interface AddressFieldsProps {
  formData: {
    streetAddress?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
  onChange: (data: Partial<AddressFieldsProps['formData']>) => void;
}

const AddressFields: React.FC<AddressFieldsProps> = ({ 
  formData = {
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US'
  }, 
  onChange 
}) => {
  const handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    onChange({ [field]: e.target.value });

    // Reset state when country changes
    if (field === 'country' && e.target.value !== 'US') {
      onChange({ state: '' });
    }
  };

  return (
    <div className="space-y-6">
      <FormField
        label="Street Address"
        type="text"
        value={formData.streetAddress}
        onChange={handleInputChange('streetAddress')}
        placeholder="Enter your street address"
        required
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormField
          label="City"
          type="text"
          value={formData.city}
          onChange={handleInputChange('city')}
          placeholder="Enter your city"
          required
        />

        <FormField
          label="Country"
          type="select"
          value={formData.country}
          onChange={handleInputChange('country')}
          required
        >
          <option value="">Select country</option>
          {COUNTRIES.map(country => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </FormField>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {formData.country === 'US' ? (
          <FormField
            label="State"
            type="select"
            value={formData.state}
            onChange={handleInputChange('state')}
            required
          >
            <option value="">Select state</option>
            {US_STATES.map(state => (
              <option key={state.code} value={state.code}>
                {state.name}
              </option>
            ))}
          </FormField>
        ) : (
          <FormField
            label="State/Province"
            type="text"
            value={formData.state}
            onChange={handleInputChange('state')}
            placeholder="Enter your state/province"
          />
        )}

        <FormField
          label="Postal Code"
          type="text"
          value={formData.postalCode}
          onChange={handleInputChange('postalCode')}
          placeholder="Enter your postal code"
          required
        />
      </div>
    </div>
  );
};

export default AddressFields;