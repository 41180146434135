import { DIRECTUS_CONFIG } from '../config/directus';

export async function fetchFromDirectus(query: string, variables = {}) {
  try {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(import.meta.env.VITE_DIRECTUS_GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_DIRECTUS_ACCESS_TOKEN}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify({ query, variables }),
      signal: controller.signal
    });

    clearTimeout(timeout);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data;
  } catch (error) {
    console.warn('Warning: Failed to fetch from Directus:', error);
    return null;
  }
}

export const IMAGE_SIZES = {
  hero: { width: 1920, height: 1080, quality: 85, fit: 'cover' as const },
  feature: { width: 800, height: 600, quality: 85, fit: 'cover' as const },
  gallery: { width: 600, height: 400, quality: 85, fit: 'cover' as const },
  galleryLarge: { width: 1200, height: 800, quality: 85, fit: 'cover' as const }
} as const;

export const getAssetUrl = (
  fileId: string, 
  options: {
    width?: number;
    height?: number;
    quality?: number;
    fit?: 'contain' | 'cover' | 'inside' | 'outside';
  } = {}
) => {
  if (!fileId) {
    throw new Error('File ID is required');
  }
  
  const baseUrl = import.meta.env.VITE_DIRECTUS_URL;
  const params = new URLSearchParams();
  
  if (options.width) params.append('width', options.width.toString());
  if (options.height) params.append('height', options.height.toString());
  if (options.quality) params.append('quality', options.quality.toString());
  if (options.fit) params.append('fit', options.fit);

  const queryString = params.toString();
  return `${baseUrl}/assets/${fileId}${queryString ? `?${queryString}` : ''}`;
};