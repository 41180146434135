import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HIGHLIGHTS = [
  {
    title: "Private Instruction",
    description: "Weekly private lessons and coachings with distinguished faculty members",
    imageId: "b8d5fcda-9038-4368-bf5f-e2077217c34e",
    position: "left"
  },
  {
    title: "Performance Opportunities",
    description: "From intimate art song recitals to fully staged opera scenes, develop your artistry through diverse performance experiences",
    imageId: "034e9bd4-baf8-4092-b1b1-711801853371",
    position: "right"
  },
  {
    title: "Masterclasses",
    description: "Learn from acclaimed guest artists in intensive masterclass settings that challenge and inspire",
    imageId: "4c8b475b-7eb3-4e4f-9704-e12fa7c86453",
    position: "center"
  },
  {
    title: "Collaborative Arts",
    description: "Work alongside pianists, composers, and instrumentalists to create compelling musical interpretations",
    imageId: "8c74e94c-751d-4754-8dd5-ab9c7e2b5d28",
    position: "right"
  }
];

const ProgramHighlights = () => {
  return (
    <section className="py-24">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-7xl mb-24 max-w-xl">
          Transform Your Artistry
        </h2>

        <div className="space-y-32">
          {HIGHLIGHTS.map((highlight, index) => (
            <div 
              key={index}
              className={`flex flex-col ${
                highlight.position === 'right' ? 'md:items-end' : 
                highlight.position === 'center' ? 'md:items-center' : ''
              }`}
            >
              <div className={`relative max-w-3xl ${
                highlight.position === 'right' ? 'md:text-right' :
                highlight.position === 'center' ? 'text-center' : ''
              }`}>
                {/* Decorative line */}
                <div className="absolute -left-8 top-0 bottom-0 w-px bg-gradient-to-b from-[#E63946] to-transparent" />

                <div className="relative">
                  <div className="aspect-[16/9] mb-8 overflow-hidden">
                    <img
                      src={getAssetUrl(highlight.imageId, {
                        width: 1200,
                        height: 675,
                        quality: 85,
                        fit: 'cover'
                      })}
                      alt={highlight.title}
                      className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                    />
                  </div>

                  <h3 className="font-serif text-4xl mb-4">{highlight.title}</h3>
                  <p className="text-lg text-[#1D3557]/70 leading-relaxed max-w-xl">
                    {highlight.description}
                  </p>

                  {/* Decorative elements */}
                  <div className="absolute -right-16 top-1/2 -translate-y-1/2 w-12 h-px bg-[#E63946]" />
                  <div className="absolute -left-16 bottom-0 w-12 h-px bg-[#457B9D]" />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Program Features */}
        <div className="mt-32 grid grid-cols-1 md:grid-cols-2 gap-16">
          <div>
            <h3 className="font-serif text-3xl mb-8">Professional Development</h3>
            <ul className="space-y-4">
              <li className="flex items-center gap-4">
                <span className="w-8 h-px bg-[#E63946]" />
                <span className="text-[#1D3557]/70">Career strategy workshops</span>
              </li>
              <li className="flex items-center gap-4">
                <span className="w-8 h-px bg-[#E63946]" />
                <span className="text-[#1D3557]/70">Audition preparation</span>
              </li>
              <li className="flex items-center gap-4">
                <span className="w-8 h-px bg-[#E63946]" />
                <span className="text-[#1D3557]/70">Industry networking</span>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-serif text-3xl mb-8">Additional Resources</h3>
            <ul className="space-y-4">
              <li className="flex items-center gap-4">
                <span className="w-8 h-px bg-[#457B9D]" />
                <span className="text-[#1D3557]/70">Professional recordings of performances</span>
              </li>
              <li className="flex items-center gap-4">
                <span className="w-8 h-px bg-[#457B9D]" />
                <span className="text-[#1D3557]/70">Language and diction coaching</span>
              </li>
              <li className="flex items-center gap-4">
                <span className="w-8 h-px bg-[#457B9D]" />
                <span className="text-[#1D3557]/70">Acting and movement workshops</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgramHighlights;