import React from 'react';
import { ArrowRight } from 'lucide-react';

const PROGRAMS = [
  {
    title: "Composition Program",
    description: "For composers seeking to push boundaries and create new musical landscapes.",
    url: "/the-institute/programs/composition-program"
  },
  {
    title: "Conducting Program",
    description: "For emerging conductors ready to lead and inspire.",
    url: "/the-institute/programs/conducting-program"
  },
  {
    title: "Instrumental Program",
    description: "For instrumentalists pursuing excellence in performance.",
    url: "/the-institute/programs/instrumental-program"
  },
  {
    title: "Piano Institute",
    description: "For pianists dedicated to mastering their craft.",
    url: "/the-institute/programs/piano-institute-and-seminar"
  },
  {
    title: "Vocal Arts Institute",
    description: "For vocalists advancing their artistry and technique.",
    url: "/the-institute/programs/vocal-institute"
  }
];

const ProgramSelector = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="font-serif text-4xl mb-6">Available Programs</h2>
          <p className="text-lg text-[#1D3557]/70">
            To receive consideration for fellowship, applicants must apply to one of the following programs.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {PROGRAMS.map((program, index) => (
            <a 
              key={index}
              href={program.url}
              className="group bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition-all duration-500"
            >
              <h3 className="font-serif text-2xl mb-4">{program.title}</h3>
              <p className="text-[#1D3557]/70 mb-6">{program.description}</p>
              <span className="flex items-center gap-2 text-[#E63946]">
                <span className="text-sm">Learn More</span>
                <ArrowRight 
                  size={16} 
                  className="group-hover:translate-x-2 transition-transform duration-300"
                />
              </span>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProgramSelector;