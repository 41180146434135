import React from 'react';

interface AuditionSectionProps {
  requirements: string[];
}

const AuditionSection: React.FC<AuditionSectionProps> = ({ requirements }) => {
  return (
    <section className="py-32 relative overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-4xl">
          <h2 className="font-serif text-7xl mb-16 relative">
            Audition Requirements
            <div className="absolute -left-8 -bottom-8 w-24 h-24 border-l-2 border-b-2 border-[#E63946] opacity-20" />
          </h2>

          <div className="space-y-12">
            {requirements.map((requirement, index) => (
              <div 
                key={index}
                className="relative pl-16"
              >
                <div 
                  className="absolute left-0 top-0 text-4xl font-serif text-[#E63946]/20"
                  style={{ fontFeatureSettings: "'tnum' on, 'lnum' on" }}
                >
                  {(index + 1).toString().padStart(2, '0')}
                </div>
                <p className="text-xl text-[#1D3557]/70 leading-relaxed">
                  {requirement}
                </p>
              </div>
            ))}
          </div>

          <div className="mt-16 p-8 bg-[#1D3557]/[0.02] rounded-lg">
            <p className="text-[#457B9D] italic">
              Note: While adhering to requirements is important, we understand that exact matches may not always be possible. Present your strongest work that best demonstrates your current level and artistic vision.
            </p>
          </div>
        </div>
      </div>

      {/* Decorative musical staff lines */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px"
            style={{
              background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.02 - i * 0.004}), transparent)`,
              top: `${20 + i * 8}%`,
              transform: `rotate(${-2 + i * 0.5}deg)`,
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default AuditionSection;