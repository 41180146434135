import React from 'react';
import RadioGroup from './RadioGroup';

interface InstrumentSelectionProps {
  value: string;
  onChange: (value: string) => void;
}

const CONFIG = {
  groups: [
    {
      name: 'Woodwinds',
      instruments: [
        { value: 'flute', label: 'Flute' },
        { value: 'oboe', label: 'Oboe' },
        { value: 'clarinet', label: 'Clarinet' },
        { value: 'bassoon', label: 'Bassoon' }
      ]
    },
    {
      name: 'Brass',
      instruments: [
        { value: 'french-horn', label: 'French Horn' },
        { value: 'trumpet', label: 'Trumpet' },
        { value: 'tenor-trombone', label: 'Tenor Trombone' },
        { value: 'bass-trombone', label: 'Bass Trombone' },
        { value: 'tuba', label: 'Tuba' }
      ]
    },
    {
      name: 'Percussion',
      instruments: [
        { value: 'percussion', label: 'Percussion' }
      ]
    },
    {
      name: 'Keyboard',
      instruments: [
        { value: 'piano', label: 'Piano' },
        { value: 'harp', label: 'Harp' }
      ]
    },
    {
      name: 'Strings',
      instruments: [
        { value: 'violin', label: 'Violin' },
        { value: 'viola', label: 'Viola' },
        { value: 'cello', label: 'Cello' },
        { value: 'double-bass', label: 'Double Bass' }
      ]
    }
  ],
  auxiliaryNote: "Select your primary instrument. If you also perform on auxiliary instruments (e.g., Piccolo for Flute, English Horn for Oboe), you'll have opportunities to indicate this later in your application.",
  styles: {
    wrapper: "space-y-8",
    group: {
      wrapper: "space-y-4",
      title: "text-sm font-medium text-[#457B9D]"
    },
    note: "text-sm text-[#1D3557]/60 mb-6 max-w-2xl"
  }
};

const InstrumentSelection: React.FC<InstrumentSelectionProps> = ({
  value,
  onChange
}) => {
  return (
    <div>
      <h4 className="text-sm font-medium mb-4">Select Your Instrument</h4>
      <p className={CONFIG.styles.note}>{CONFIG.auxiliaryNote}</p>
      <div className={CONFIG.styles.wrapper}>
        {CONFIG.groups.map((group) => (
          <div key={group.name} className={CONFIG.styles.group.wrapper}>
            <h5 className={CONFIG.styles.group.title}>{group.name}</h5>
            <RadioGroup
              options={group.instruments}
              value={value}
              onChange={onChange}
              name="instrument"
              inline={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstrumentSelection;