import React from 'react';
import { getAssetUrl } from '../../utils/directus';

interface Faculty {
  name: string;
  profileImage?: string;
  affiliation?: string;
  biography?: string;
  instruments?: Array<{
    value: string;
    label: string;
  }>;
}

interface FacultySectionProps {
  selectedInstrument?: string;
  faculty: Faculty[];
  title?: string;
  description?: string;
}

const FacultySection: React.FC<FacultySectionProps> = ({
  selectedInstrument = '',
  faculty = [],
  title = "Distinguished Faculty",
  description = "Learn from and work alongside world-renowned musicians who are shaping the future of classical music."
}) => {
  // Filter faculty based on selected instrument if one is selected
  const filteredFaculty = selectedInstrument
    ? faculty.filter(f => f.instruments?.some(i => i.value === selectedInstrument))
    : faculty;

  return (
    <section className="py-16 md:py-24 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-3xl mx-auto mb-16 text-center">
          <h2 className="font-serif text-4xl mb-6">{title}</h2>
          <p className="text-lg text-[#1D3557]/80">
            {selectedInstrument 
              ? "Learn from and work alongside world-renowned musicians specializing in your instrument."
              : description}
          </p>
        </div>

        {filteredFaculty.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {filteredFaculty.map((member, index) => (
              <div key={index} className="group">
                <div className="aspect-[3/4] mb-4 overflow-hidden rounded-lg">
                  {member.profileImage ? (
                    <img
                      src={getAssetUrl(member.profileImage, {
                        width: 400,
                        height: 600,
                        quality: 85,
                        fit: 'cover'
                      })}
                      alt={member.name}
                      className="w-full h-full object-cover filter grayscale group-hover:grayscale-0 scale-105 group-hover:scale-100 transition-all duration-700"
                    />
                  ) : (
                    <div className="w-full h-full bg-[#1D3557]/5 flex items-center justify-center">
                      <span className="font-serif text-4xl text-[#1D3557]/20">
                        {member.name[0]}
                      </span>
                    </div>
                  )}
                </div>
                <h3 className="font-serif text-xl mb-1">{member.name}</h3>
                {member.affiliation && (
                  <p className="text-sm text-[#457B9D]">{member.affiliation}</p>
                )}
                {member.instruments && member.instruments.length > 0 && (
                  <p className="text-sm text-[#1D3557]/60 mt-1">
                    {member.instruments.map(i => i.label).join(', ')}
                  </p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center py-12 text-[#1D3557]/60">
            {selectedInstrument 
              ? "Faculty information for this instrument will be announced soon."
              : "Faculty information will be announced soon."}
          </p>
        )}
      </div>
    </section>
  );
};

export default FacultySection;