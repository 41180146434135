import React, { createContext, useContext, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface Announcement {
  id: string;
  message: string;
  variant?: 'light' | 'dark';
  excludedPaths?: string[];
}

interface AnnouncementContextType {
  announcements: Announcement[];
}

const AnnouncementContext = createContext<AnnouncementContextType | undefined>(undefined);

// Current active announcements
const ACTIVE_ANNOUNCEMENTS: Announcement[] = [
  {
    id: 'season-2025',
    message: 'You are viewing information for the 2024 season. Information for the 2025 season (June 30 - August 10) will be announced soon.',
    variant: 'light',
    excludedPaths: ['/'] // Only exclude homepage
  }
];

export const useAnnouncements = () => {
  const context = useContext(AnnouncementContext);
  const location = useLocation();

  if (!context) {
    throw new Error('useAnnouncements must be used within an AnnouncementProvider');
  }

  // Filter announcements based on exact current path
  const visibleAnnouncements = context.announcements.filter(announcement => {
    if (!announcement.excludedPaths) return true;
    return !announcement.excludedPaths.includes(location.pathname);
  });

  return { announcements: visibleAnnouncements };
};

export const AnnouncementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AnnouncementContext.Provider value={{ announcements: ACTIVE_ANNOUNCEMENTS }}>
      {children}
    </AnnouncementContext.Provider>
  );
};