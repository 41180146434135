import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const JOURNEY_SECTIONS = [
  {
    imageId: "1d2455fb-a2e1-4c92-be40-e773081c38d9",
    title: "Masterclasses",
    description: "Receive personalized instruction from world-renowned pianists and pedagogues in a supportive and encouraging environment.",
    position: "right"
  },
  {
    imageId: "fa7c167a-7b91-4eb7-a457-78fa721f3009",
    title: "Advanced Workshops",
    description: "Explore touch, tone production, phrasing, and interpretation to develop your unique voice as a pianist.",
    position: "left"
  },
  {
    imageId: "79a6308e-b927-4309-bb73-b5fddabdc6c4",
    title: "Career Development",
    description: "Build your network, explore diverse career paths, and deepen your understanding of the music industry through targeted workshops and mentorship.",
    position: "center"
  },
  {
    imageId: "2a26a9cc-08e6-48d5-89e4-591af3ccc3e5",
    title: "Performance Excellence",
    description: "Prepare for auditions, recitals, and concerto performances through structured private lessons and expert faculty guidance.",
    position: "right"
  }
];

const ArtisticJourney = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-7xl mb-24">Your Artistic Journey</h2>

        <div className="space-y-32">
          {JOURNEY_SECTIONS.map((section, index) => (
            <div 
              key={index}
              className={`flex flex-col ${
                section.position === 'right' ? 'md:items-end' : 
                section.position === 'center' ? 'md:items-center' : ''
              }`}
            >
              <div className={`relative max-w-3xl ${
                section.position === 'right' ? 'md:text-right' :
                section.position === 'center' ? 'text-center' : ''
              }`}>
                {/* Decorative line */}
                <div className="absolute -left-8 top-0 bottom-0 w-px bg-gradient-to-b from-[#E63946] to-transparent" />

                <div className="relative">
                  <div className="aspect-[16/9] mb-8 overflow-hidden">
                    <img
                      src={getAssetUrl(section.imageId, {
                        width: 1200,
                        height: 675,
                        quality: 85,
                        fit: 'cover'
                      })}
                      alt={section.title}
                      className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                    />
                  </div>

                  <h3 className="font-serif text-4xl mb-4">{section.title}</h3>
                  <p className="text-lg text-[#1D3557]/70 leading-relaxed max-w-xl">
                    {section.description}
                  </p>

                  {/* Decorative elements */}
                  <div className="absolute -right-16 top-1/2 -translate-y-1/2 w-12 h-px bg-[#E63946]" />
                  <div className="absolute -left-16 bottom-0 w-12 h-px bg-[#457B9D]" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ArtisticJourney;