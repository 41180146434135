import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getAssetUrl, IMAGE_SIZES } from '../utils/directus';

const CONFIG = {
  title: "Our Legacy",
  description: "For fifteen seasons, we've been nurturing exceptional talent and bringing transformative musical experiences to our community. Your support helps us continue this legacy.",
  achievements: [
    {
      number: "15",
      label: "Seasons",
      description: "Of artistic excellence and musical innovation"
    },
    {
      number: "400+",
      label: "Concerts",
      description: "Free performances open to the public"
    },
    {
      number: "700+",
      label: "Premieres",
      description: "New works debuted at the festival"
    },
    {
      number: "600+",
      label: "Artists",
      description: "Fellowship recipients since inception"
    }
  ],
  images: [
    {
      url: getAssetUrl("212de122-1ed6-4f21-af96-2a3d74f41b05", IMAGE_SIZES.feature),
      alt: "AMF Orchestra and Soloists",
      color: "#457B9D"
    },
    {
      url: getAssetUrl("03fe39ae-7a8b-4b73-88e9-a0ff63f9ba4e", IMAGE_SIZES.feature),
      alt: "AMF Concert",
      color: "#E63946"
    }
  ],
  quote: {
    text: "Every contribution to AMF helps shape the future of classical music, nurturing the next generation of extraordinary artists."
  },
  cta: {
    title: "Support Our Mission",
    description: "Your gift enables us to provide full scholarships to talented musicians, present free concerts to our community, and commission new works that advance the classical music repertoire.",
    primaryButton: {
      text: "Make a Gift",
      url: "/get-involved"
    },
    secondaryButton: {
      text: "Other Ways to Give",
      url: "/get-involved#ways-to-give"
    }
  }
};

const Impact: React.FC = () => {
  return (
    <section className="py-16 md:py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16">
          <div className="col-span-1 md:col-span-4">
            <h3 className="font-serif text-4xl md:text-6xl relative inline-block">
              {CONFIG.title}
              <div className="absolute -left-4 md:-left-8 -bottom-4 md:-bottom-8 w-12 md:w-16 h-12 md:h-16 bg-[#F6BD60] opacity-20 -z-10" />
            </h3>
          </div>
          <div className="col-span-1 md:col-span-8">
            <p className="font-light text-lg leading-relaxed tracking-wide text-[#1D3557]/80 max-w-2xl">
              {CONFIG.description}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16 mt-16">
          <div className="col-span-1 md:col-span-5 space-y-12 md:space-y-16">
            {CONFIG.achievements.map((achievement, index) => (
              <div
                key={index}
                className="group relative pl-12 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-8 before:h-[1px] before:bg-[#1D3557]/20 before:group-hover:bg-[#E63946] before:transition-colors before:duration-500"
              >
                <div className="font-serif text-4xl md:text-5xl">{achievement.number}</div>
                <div className="text-sm tracking-widest uppercase text-[#457B9D]">{achievement.label}</div>
                <div className="font-light text-sm tracking-wide text-[#1D3557]/60 pl-1">{achievement.description}</div>
              </div>
            ))}
          </div>

          <div className="col-span-1 md:col-span-7 space-y-16 md:space-y-24">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {CONFIG.images.map((image, index) => (
                <div key={index} className="relative overflow-hidden">
                  <img
                    src={image.url}
                    alt={image.alt}
                    className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                  />
                  <div 
                    className="absolute inset-0 mix-blend-color opacity-10 hover:opacity-0 transition-opacity duration-700"
                    style={{ backgroundColor: image.color }}
                  />
                </div>
              ))}
            </div>

            <blockquote className="relative pl-12">
              <div className="absolute left-0 top-0 w-8 h-8 bg-[#F6BD60] opacity-20" />
              <p className="font-serif text-2xl md:text-3xl leading-relaxed italic mb-8 relative z-10">
                {CONFIG.quote.text}
              </p>
            </blockquote>

            <div className="bg-[#1D3557]/[0.02] p-8 md:p-12 relative">
              <div className="absolute top-0 left-0 w-1 h-full bg-[#E63946]" />
              <h4 className="font-serif text-2xl mb-6">{CONFIG.cta.title}</h4>
              <p className="font-light leading-relaxed tracking-wide text-[#1D3557]/80 max-w-xl mb-8">
                {CONFIG.cta.description}
              </p>
              <div className="flex flex-col sm:flex-row items-start gap-6">
                <a href={CONFIG.cta.primaryButton.url} className="relative overflow-hidden group w-full sm:w-auto">
                  <span className="block px-8 py-4 border-2 border-[#1D3557]">
                    <span className="relative z-10 flex items-center justify-center sm:justify-start gap-3 text-sm tracking-wide group-hover:text-white transition-colors duration-500">
                      {CONFIG.cta.primaryButton.text}
                      <ArrowRight size={14} className="group-hover:translate-x-2 transition-transform duration-500" />
                    </span>
                  </span>
                  <div className="absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
                </a>
                <a href={CONFIG.cta.secondaryButton.url} className="group w-full sm:w-auto">
                  <span className="flex items-center justify-center sm:justify-start gap-4 text-sm tracking-wide artistic-border">
                    {CONFIG.cta.secondaryButton.text}
                    <ArrowRight size={16} className="text-[#E63946] group-hover:translate-x-2 transition-transform duration-500" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Impact;