import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/institute/HeroSection';
import ProgramOverview from '../components/institute/ProgramOverview';
import FellowshipSection from '../components/institute/FellowshipSection';
import ResourcesSection from '../components/institute/ResourcesSection';

const TheInstitute = () => {
  return (
    <div>
      <Header variant="dark" />
      <HeroSection />
      <ProgramOverview />
      <FellowshipSection />
      <ResourcesSection />
      <Footer />
    </div>
  );
};

export default TheInstitute;