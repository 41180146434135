import React from 'react';
import ReviewSection from '../ReviewSection';

const CONFIG = {
  title: "Survey",
  fields: [
    { key: 'heardFrom', label: 'How did you hear about us?' },
    { key: 'teacherName', label: "Teacher's Name" }
  ]
};

interface SurveyReviewProps {
  data: any;
}

const SurveyReview: React.FC<SurveyReviewProps> = ({ data }) => {
  if (!data) return null;

  return (
    <ReviewSection
      title={CONFIG.title}
      fields={CONFIG.fields}
      data={data}
    />
  );
};

export default SurveyReview;