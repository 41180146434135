import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('c09d9f74-4ff1-4598-ac07-4e8c9eeffb49', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="Conductor leading orchestra"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        
        {/* Conducting gesture lines */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className="absolute h-px bg-white/10"
              style={{
                width: '40%',
                left: '30%',
                top: `${20 + i * 8}%`,
                transform: `rotate(${-20 + i * 5}deg)`,
                animation: 'gesture-flow 3s ease-in-out infinite',
                animationDelay: `${i * 0.15}s`
              }}
            />
          ))}
        </div>
      </div>
      
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-3xl">
          <h1 className="font-serif text-8xl text-white mb-8 relative">
            Conducting Program
            <div className="absolute -right-16 top-1/2 -translate-y-1/2 w-12 h-32">
              <svg className="w-full h-full" viewBox="0 0 48 128">
                <path
                  d="M24 0v128"
                  className="stroke-white/20 animate-draw"
                  strokeWidth="2"
                  fill="none"
                  strokeDasharray="128"
                  strokeDashoffset="128"
                />
              </svg>
            </div>
          </h1>
          <p className="text-2xl text-white/80 font-light leading-relaxed">
            Master the art of leadership through intensive podium time and mentorship from renowned conductors.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;