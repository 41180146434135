import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const OPPORTUNITIES = [
  {
    title: "Premiere New Works",
    description: "As a resident ensemble, fellows have the unique privilege of premiering works by our community of over 40 composers throughout the season.",
    imageId: "5f430d44-005a-415a-afea-a28adfcafc84",
    color: "#E63946"
  },
  {
    title: "MUGIC™ Lab",
    description: "Explore groundbreaking intersections of technology and music through our innovative motion sensor program and electronic music initiatives.",
    imageId: "16969daf-a600-46ca-aca7-13e385ecc2ce",
    color: "#457B9D"
  },
  {
    title: "Professional Growth",
    description: "Develop essential skills in contemporary music performance, interpretation, and collaboration while building a network in the new music community.",
    imageId: "d6861754-d6b0-415f-871f-faef69cd233e",
    color: "#F6BD60"
  }
];

const OpportunitiesSection = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-7xl mb-24">Opportunities</h2>

        <div className="space-y-32">
          {OPPORTUNITIES.map((opportunity, index) => (
            <div 
              key={index}
              className={`flex flex-col ${
                index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
              } gap-16 items-center`}
            >
              {/* Image */}
              <div className="flex-1 relative">
                <div className="aspect-[4/3] overflow-hidden rounded-lg">
                  <img
                    src={getAssetUrl(opportunity.imageId, {
                      width: 800,
                      height: 600,
                      quality: 85,
                      fit: 'cover'
                    })}
                    alt={opportunity.title}
                    className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                  />
                </div>
                
                {/* Decorative elements */}
                <div 
                  className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2"
                  style={{ borderColor: `${opportunity.color}20` }}
                />
              </div>

              {/* Content */}
              <div className="flex-1">
                <div className="relative pl-12">
                  {/* Vertical line */}
                  <div 
                    className="absolute left-0 top-0 bottom-0 w-1"
                    style={{ 
                      background: `linear-gradient(to bottom, ${opportunity.color}, transparent)` 
                    }}
                  />
                  
                  <h3 className="font-serif text-5xl mb-8">{opportunity.title}</h3>
                  <p className="text-xl text-[#1D3557]/70 leading-relaxed">
                    {opportunity.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Abstract notation lines */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(8)].map((_, i) => (
            <div
              key={i}
              className="absolute w-full h-px"
              style={{
                background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.02 - i * 0.002}), transparent)`,
                top: `${20 + i * 10}%`,
                transform: `rotate(${-10 + i * 2}deg)`,
                animation: `floatLine ${4 + i * 0.5}s ease-in-out infinite`,
                animationDelay: `${i * 0.2}s`
              }}
            />
          ))}
        </div>
      </div>

      <style>{`
        @keyframes floatLine {
          0%, 100% { transform: rotate(var(--rotation)) translateY(0); }
          50% { transform: rotate(var(--rotation)) translateY(-10px); }
        }
      `}</style>
    </section>
  );
};

export default OpportunitiesSection;