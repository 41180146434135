import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import FestivalDates from '../components/fees/FestivalDates';
import ApplicationFees from '../components/fees/ApplicationFees';
import TuitionFees from '../components/fees/TuitionFees';
import ImportantNotes from '../components/fees/ImportantNotes';
import { APPLICATION_CONFIG } from '../config/application';

const CONFIG = {
  header: {
    title: "Fees, Dates & Deadlines",
    description: "Important information about application deadlines, program fees, and payment schedules.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16"
  }
};

const FeesAndDeadlines = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      {/* Application Status */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="font-serif text-4xl mb-6">Application Status</h2>
            <p className="text-xl text-[#1D3557]/80 mb-8">
              {APPLICATION_CONFIG.status.message}
            </p>
          </div>
        </div>
      </section>

      {/* Festival Dates */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <FestivalDates />
        </div>
      </section>

      {/* Application Fees */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <ApplicationFees />
        </div>
      </section>

      {/* Tuition Fees */}
      <section className={`${CONFIG.styles.section} bg-[#1D3557]/[0.02]`}>
        <div className={CONFIG.styles.container}>
          <TuitionFees />
        </div>
      </section>

      {/* Important Notes */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <ImportantNotes />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default FeesAndDeadlines;