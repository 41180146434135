import React from 'react';
import { ArrowRight } from 'lucide-react';
import { APPLICATION_CONFIG } from '../config/application';

const CONFIG = {
  title: "Join Our Next Season",
  description: "Experience a transformative summer of musical excellence and artistic growth.",
  styles: {
    background: "bg-[#457B9D]/[0.04]",
    title: "font-serif text-3xl text-[#1D3557]",
    description: "font-light text-[#1D3557]/70",
    deadline: "text-sm tracking-wide text-[#E63946]"
  }
};

const JoinNextSeason = () => {
  return (
    <div className={`w-full ${CONFIG.styles.background}`}>
      <div className="max-w-screen-2xl mx-auto px-16 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
          <div className="space-y-4">
            <h4 className={CONFIG.styles.title}>{CONFIG.title}</h4>
            <p className={`${CONFIG.styles.description} max-w-md leading-relaxed`}>
              {CONFIG.description}
            </p>
            <div className={CONFIG.styles.deadline}>
              {APPLICATION_CONFIG.status.message}
            </div>
          </div>
          <div className="flex justify-start md:justify-end">
            <a 
              href="/the-institute/online-application" 
              className="relative overflow-hidden group"
            >
              <span className="block px-12 py-4 border-2 border-[#1D3557]">
                <span className="relative z-10 flex items-center gap-3 text-sm tracking-wide
                             group-hover:text-white transition-colors duration-500">
                  Learn About Application Process
                  <ArrowRight size={16} className="group-hover:translate-x-2 transition-transform duration-500" />
                </span>
              </span>
              <div className="absolute inset-0 bg-[#1D3557] -translate-y-full 
                          group-hover:translate-y-0 transition-transform duration-500" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinNextSeason;