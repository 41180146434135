import { FIREBASE_PATHS } from './firebase';

export const STORAGE_CONFIG = {
  // Use paths from Firebase config
  paths: FIREBASE_PATHS.storage.types,

  // Size limits for different file types
  maxSizes: {
    resume: 5 * 1024 * 1024, // 5MB
    portfolio: 50 * 1024 * 1024, // 50MB
    documents: 10 * 1024 * 1024 // 10MB
  },

  // Allowed file types for each category
  allowedTypes: {
    resume: ['application/pdf'],
    portfolio: ['audio/mpeg', 'application/pdf'],
    documents: ['application/pdf']
  },

  // Helper function to construct storage paths
  getPath: (userId: string, type: keyof typeof FIREBASE_PATHS.storage.types, filename: string) => {
    return FIREBASE_PATHS.storage.getFilePath(userId, type, filename);
  },

  // Helper function to validate file type
  isAllowedType: (type: keyof typeof FIREBASE_PATHS.storage.types, mimeType: string) => {
    return STORAGE_CONFIG.allowedTypes[type].includes(mimeType);
  },

  // Helper function to validate file size
  isAllowedSize: (type: keyof typeof FIREBASE_PATHS.storage.types, size: number) => {
    return size <= STORAGE_CONFIG.maxSizes[type];
  }
};