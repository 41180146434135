import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { PortfolioItem } from '../types';
import { PORTFOLIO_CONFIG } from '../config';
import SortablePortfolioItem from './SortablePortfolioItem';

interface PortfolioListProps {
  items: PortfolioItem[];
  onReorder: (items: PortfolioItem[]) => void;
  onEdit: (id: string, updates: Partial<PortfolioItem>) => void;
  onRemove: (id: string) => void;
}

const PortfolioList: React.FC<PortfolioListProps> = ({
  items,
  onReorder,
  onEdit,
  onRemove,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      onReorder(arrayMove(items, oldIndex, newIndex));
    }
  };

  if (items.length === 0) {
    return (
      <p className={PORTFOLIO_CONFIG.styles.list.empty}>
        No items added yet.
      </p>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(item => ({ id: item.id }))}
        strategy={verticalListSortingStrategy}
      >
        <div className="space-y-4 mt-8">
          {items.map(item => (
            <SortablePortfolioItem
              key={item.id}
              item={item}
              onEdit={(updates) => onEdit(item.id, updates)}
              onRemove={() => onRemove(item.id)}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default PortfolioList;