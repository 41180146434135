export const PORTFOLIO_CONFIG = {
  maxItems: 10,
  maxFileSize: 50 * 1024 * 1024, // 50MB
  acceptedFileTypes: {
    'audio/mpeg': ['.mp3'],
    'application/pdf': ['.pdf']
  },
  allowedLinkTypes: ['youtube.com', 'vimeo.com'],
  styles: {
    section: {
      wrapper: "space-y-8",
      title: "text-lg font-medium mb-6",
      description: "text-sm text-[#1D3557]/60 mb-8"
    },
    form: {
      wrapper: "space-y-6",
      field: {
        wrapper: "space-y-2",
        label: "block text-sm font-medium text-[#1D3557]",
        input: "w-full px-4 py-3 border border-[#1D3557]/20 rounded-md text-[#1D3557] placeholder:text-[#1D3557]/40 focus:outline-none focus:border-[#E63946] transition-colors"
      },
      error: "text-sm text-[#E63946] mt-1",
      buttons: {
        wrapper: "flex justify-end gap-4 mt-8",
        cancel: "px-4 py-2 text-sm text-[#1D3557] hover:text-[#E63946] transition-colors",
        submit: "px-4 py-2 text-sm bg-[#1D3557] text-white rounded hover:bg-[#E63946] transition-colors"
      }
    },
    preview: {
      audio: {
        wrapper: "bg-gray-50 p-4 rounded-lg"
      },
      pdf: {
        wrapper: "w-full aspect-[4/3] bg-gray-50 rounded-lg overflow-hidden",
        iframe: "w-full h-full border-0"
      },
      link: {
        wrapper: "w-full aspect-video bg-gray-50 rounded-lg overflow-hidden",
        iframe: "w-full h-full border-0"
      }
    },
    list: {
      wrapper: "space-y-4",
      empty: "text-center py-8 text-[#1D3557]/40"
    },
    item: {
      wrapper: "bg-white p-6 rounded-lg shadow-sm border border-[#1D3557]/10",
      header: {
        wrapper: "flex items-start justify-between mb-4",
        title: "text-lg font-medium",
        actions: "flex items-center gap-2"
      },
      content: {
        wrapper: "space-y-4",
        description: "text-sm text-[#1D3557]/60"
      }
    },
    uploader: {
      wrapper: "border-2 border-dashed border-[#1D3557]/20 rounded-lg p-8 text-center hover:border-[#1D3557]/40 transition-colors",
      content: {
        wrapper: "flex flex-col items-center space-y-4",
        icon: "w-8 h-8 text-[#1D3557]/40",
        text: "text-[#1D3557]/60",
        subtext: "text-sm text-[#1D3557]/40"
      }
    },
    modal: {
      overlay: "fixed inset-0 bg-black/50 z-50",
      wrapper: "fixed inset-0 z-50 flex items-center justify-center p-4",
      content: "bg-white rounded-lg shadow-xl max-w-lg w-full max-h-[90vh] overflow-y-auto",
      header: {
        wrapper: "flex items-center justify-between p-6 border-b border-[#1D3557]/10",
        title: "text-lg font-medium",
        close: "text-[#1D3557]/40 hover:text-[#E63946] transition-colors"
      },
      body: "p-6"
    }
  }
} as const;