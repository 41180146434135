import React from 'react';
import { ArrowRight } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { VENUE_CONFIG } from '../config/venue';

const CONFIG = {
  hero: {
    image: "/images/amf/welcome/8.jpg",
    title: "This Will Be Our Reply",
    subtitle: "Solbong Kim, Artistic Director"
  },
  quote: {
    text: "This will be our reply to violence: to make music more intensely, more beautifully, more devotedly than ever before.",
    author: "Leonard Bernstein"
  },
  content: {
    introduction: {
      title: "Welcome to the Atlantic Music Festival",
      paragraphs: [
        "Since 2009, the Atlantic Music Festival has been a sanctuary for extraordinary musical talent, fostering an environment where promising young musicians from around the world come together to perform, learn from established masters, and realize their full artistic potential.",
        "Over the past fifteen years, I have had the privilege of witnessing the growth of our festival community. We have been moved by the passion, beauty, and youthful exuberance of our artists, experiencing history in the making as world premieres by tomorrow's most promising composers were heard alongside timeless masterworks."
      ]
    },
    sections: [
      {
        title: "Our Vision",
        content: "Many wonder what draws such extraordinary talent to our festival. While our distinguished faculty and guest artists are certainly influential factors, what truly sets AMF apart is our unwavering commitment to using music as a means of cultivating awareness of our musicians' place within their communities."
      },
      {
        title: "Fellowship Program",
        content: "Our fellowship program stands as a cornerstone of our mission to nurture emerging artists. The opportunity for young musicians to study, create, and perform in an environment free from financial burden has yielded remarkable results. In the last decade, over 500 musicians have received fellowships and now hold key positions within major orchestras, ensembles, and academic institutions."
      },
      {
        title: "Free Concerts",
        content: "We remain committed to presenting our spectacular concerts to the public free of charge. Our season includes more than fifty performances featuring world premieres, vocal works, solo recitals, chamber music, and orchestral masterpieces."
      }
    ],
    closing: {
      paragraphs: [
        "The Atlantic Music Festival's ability to carry out its vision depends upon the generosity of its patrons and sponsors--those who identify with the importance of our mission and join us in carrying it forward. Your tax-deductible contribution allows AMF to provide fellowships to young musicians of exceptional dedication and ability, brings world-renowned concert artists to our community, and helps create vibrant cultural experiences.",
        "I invite you, along with your families, friends, and colleagues, to join us at the Atlantic Music Festival: a breeding ground for new ideas and music-making of the highest order--a landmark for the sound of the 21st century. I'll see you there!"
      ],
      signature: {
        name: "Solbong Kim",
        title: "Artistic Director"
      }
    }
  },
  cta: {
    title: "Support Our Vision",
    description: "Help us continue providing opportunities for extraordinary talent and bringing world-class music to our community.",
    button: {
      text: "Make a Gift",
      url: "/support"
    }
  },
  styles: {
    hero: {
      wrapper: "relative min-h-screen flex items-center",
      overlay: "absolute inset-0 bg-gradient-to-r from-black/80 to-transparent",
      content: "relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20",
      title: "font-serif text-6xl md:text-8xl text-white mb-8",
      subtitle: "text-xl text-white/80"
    },
    quote: {
      wrapper: "py-16 md:py-24",
      container: "max-w-screen-2xl mx-auto px-8 md:px-16",
      text: "font-serif text-2xl md:text-4xl italic max-w-4xl mx-auto leading-relaxed text-center",
      author: "block text-sm tracking-wide text-[#1D3557]/60 mt-6 text-center"
    },
    content: {
      section: "py-16 md:py-24",
      container: "max-w-screen-2xl mx-auto px-8 md:px-16",
      intro: {
        title: "font-serif text-4xl mb-8",
        text: "text-lg text-[#1D3557]/80 leading-relaxed max-w-3xl"
      },
      grid: "grid grid-cols-1 md:grid-cols-3 gap-16",
      card: {
        wrapper: "space-y-4",
        title: "font-serif text-2xl",
        text: "text-[#1D3557]/70 leading-relaxed"
      },
      closing: {
        wrapper: "max-w-3xl mx-auto space-y-8",
        text: "text-lg text-[#1D3557]/80 leading-relaxed",
        signature: {
          wrapper: "mt-12",
          name: "font-serif text-xl",
          title: "text-[#1D3557]/60"
        }
      }
    },
    cta: {
      wrapper: "bg-[#1D3557]/[0.02] py-16 md:py-24",
      container: "max-w-screen-2xl mx-auto px-8 md:px-16 text-center",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 max-w-2xl mx-auto mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const Welcome = () => {
  return (
    <div>
      <Header variant="dark" />
      
      {/* Hero */}
      <div className={CONFIG.styles.hero.wrapper}>
        <div className="absolute inset-0">
          <img 
            src={CONFIG.hero.image}
            alt="Atlantic Music Festival"
            className="w-full h-full object-cover"
          />
          <div className={CONFIG.styles.hero.overlay} />
        </div>
        <div className={CONFIG.styles.hero.content}>
          <h1 className={CONFIG.styles.hero.title}>{CONFIG.hero.title}</h1>
          <p className={CONFIG.styles.hero.subtitle}>{CONFIG.hero.subtitle}</p>
        </div>
      </div>

      {/* Quote */}
      <div className={CONFIG.styles.quote.wrapper}>
        <div className={CONFIG.styles.quote.container}>
          <blockquote className={CONFIG.styles.quote.text}>
            "{CONFIG.quote.text}"
          </blockquote>
          <span className={CONFIG.styles.quote.author}>— {CONFIG.quote.author}</span>
        </div>
      </div>

      {/* Introduction */}
      <div className={CONFIG.styles.content.section}>
        <div className={CONFIG.styles.content.container}>
          <h2 className={CONFIG.styles.content.intro.title}>
            {CONFIG.content.introduction.title}
          </h2>
          {CONFIG.content.introduction.paragraphs.map((paragraph, index) => (
            <p key={index} className={`${CONFIG.styles.content.intro.text} mb-6`}>
              {paragraph}
            </p>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className={CONFIG.styles.content.section}>
        <div className={CONFIG.styles.content.container}>
          <div className={CONFIG.styles.content.grid}>
            {CONFIG.content.sections.map((section, index) => (
              <div key={index} className={CONFIG.styles.content.card.wrapper}>
                <h3 className={CONFIG.styles.content.card.title}>{section.title}</h3>
                <p className={CONFIG.styles.content.card.text}>{section.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Closing */}
      <div className={CONFIG.styles.content.section}>
        <div className={CONFIG.styles.content.container}>
          <div className={CONFIG.styles.content.closing.wrapper}>
            {CONFIG.content.closing.paragraphs.map((paragraph, index) => (
              <p key={index} className={CONFIG.styles.content.closing.text}>
                {paragraph}
              </p>
            ))}
            <div className={CONFIG.styles.content.closing.signature.wrapper}>
              <p className={CONFIG.styles.content.closing.signature.name}>
                {CONFIG.content.closing.signature.name}
              </p>
              <p className={CONFIG.styles.content.closing.signature.title}>
                {CONFIG.content.closing.signature.title}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA */}
      <div className={CONFIG.styles.cta.wrapper}>
        <div className={CONFIG.styles.cta.container}>
          <h2 className={CONFIG.styles.cta.title}>{CONFIG.cta.title}</h2>
          <p className={CONFIG.styles.cta.description}>{CONFIG.cta.description}</p>
          <a href={CONFIG.cta.button.url} className={CONFIG.styles.cta.button.wrapper}>
            <span className={CONFIG.styles.cta.button.text}>
              {CONFIG.cta.button.text}
              <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
            </span>
            <div className={CONFIG.styles.cta.button.background} />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Welcome;