import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background */}
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('052b19fc-d7c9-4974-919a-ec9aeac42f14', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="AMF Fellows performing"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/95 via-black/80 to-transparent" />
        
        {/* Animated lines representing musical staves */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(5)].map((_, i) => (
            <div
              key={i}
              className="absolute w-full h-px bg-white/10"
              style={{
                top: `${30 + i * 5}%`,
                transform: `scaleX(${0.8 + i * 0.05})`,
                animation: `staffLine ${6 + i}s ease-in-out infinite`,
                animationDelay: `${i * 0.2}s`
              }}
            />
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-4xl">
          {/* Main Title with dramatic reveal */}
          <div className="overflow-hidden mb-12">
            <div className="transform hover:scale-105 transition-transform duration-700">
              <div className="flex flex-col gap-4">
                <h1 className="font-serif text-[8rem] leading-none text-white">
                  Fellowship
                </h1>
                <h1 className="font-serif text-[8rem] leading-none text-white">
                  Program
                </h1>
              </div>
            </div>
          </div>

          {/* Description with side line */}
          <div className="relative pl-12 max-w-2xl">
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#E63946] to-transparent" />
            <p className="text-2xl text-white/80 font-light leading-relaxed">
              A transformative residency where exceptional talent meets unparalleled opportunity. Up to 40 fellowships awarded annually, providing complete financial coverage for a six-week immersion in artistic excellence.
            </p>
          </div>

          {/* Key Statistics */}
          <div className="mt-16 grid grid-cols-3 gap-8">
            <div className="text-white/90">
              <div className="font-serif text-5xl mb-2">40+</div>
              <div className="text-sm tracking-wide text-white/60">Fellows per Season</div>
            </div>
            <div className="text-white/90">
              <div className="font-serif text-5xl mb-2">6</div>
              <div className="text-sm tracking-wide text-white/60">Week Residency</div>
            </div>
            <div className="text-white/90">
              <div className="font-serif text-5xl mb-2">500+</div>
              <div className="text-sm tracking-wide text-white/60">Alumni Worldwide</div>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
        <div className="w-[1px] h-24 bg-gradient-to-b from-white/0 via-white/50 to-white/0 animate-pulse" />
      </div>

      <style>{`
        @keyframes staffLine {
          0%, 100% { 
            transform: scaleX(0.95) rotate(var(--rotation)); 
            opacity: 0.1; 
          }
          50% { 
            transform: scaleX(1.05) rotate(var(--rotation)); 
            opacity: 0.3; 
          }
        }
      `}</style>
    </div>
  );
};

export default HeroSection;