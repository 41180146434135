import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ApplicationProvider } from './contexts/ApplicationContext';
import { AudioProvider } from './contexts/AudioContext';
import { AnnouncementProvider } from './contexts/AnnouncementContext';
import App from './App';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ApplicationProvider>
          <AudioProvider>
            <AnnouncementProvider>
              <App />
            </AnnouncementProvider>
          </AudioProvider>
        </ApplicationProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);