import React from 'react';
import { ArrowRight } from 'lucide-react';

const REQUIREMENTS = [
  {
    title: "Age & Attendance",
    items: [
      "Must be 18 or older",
      "Full attendance required for entire residency",
      "No late arrivals or early departures permitted",
      "Available for performance every day during residency"
    ]
  },
  {
    title: "Available Positions",
    items: [
      "Composers",
      "Conductors",
      "Singers",
      "Instrumentalists (Strings, Woodwinds, Brass, Percussion, Piano)",
      "Auxiliary instruments may be required for woodwind positions"
    ]
  },
  {
    title: "Expectations",
    items: [
      "Contribute up to 2 hours daily for administrative needs",
      "Participate in respective program activities",
      "Maintain professional conduct",
      "Collaborate with faculty and peers"
    ]
  }
];

const EligibilitySection = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="font-serif text-7xl mb-16">Eligibility & Requirements</h2>

          <div className="space-y-16">
            {REQUIREMENTS.map((section, index) => (
              <div key={index} className="space-y-6">
                <h3 className="font-serif text-3xl">{section.title}</h3>
                <div className="space-y-4">
                  {section.items.map((item, idx) => (
                    <div key={idx} className="flex items-start gap-4">
                      <span className="w-8 h-px bg-[#E63946] mt-3 flex-shrink-0" />
                      <span className="text-lg text-[#1D3557]/70">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-24 text-center">
            <p className="text-xl text-[#1D3557]/70 mb-8">
              Ready to begin your fellowship journey?
            </p>
            <a 
              href="/the-institute/online-application" 
              className="inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group"
            >
              <span className="relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500">
                Begin Application
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className="absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EligibilitySection;