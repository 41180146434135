import React from 'react';

const IntroSection = () => {
  return (
    <section className="py-24 md:py-32 relative overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-4xl relative">
          <p className="text-2xl leading-relaxed text-[#1D3557]/80 first-letter:text-7xl first-letter:font-serif first-letter:text-[#E63946] first-letter:float-left first-letter:mr-3 first-letter:mt-1">
            The AMF Conducting Program transforms talented conductors into visionary leaders through intensive hands-on experience and mentorship. Our unique approach emphasizes substantial podium time with professional-level ensembles, allowing conductors to develop their craft in real-world scenarios.
          </p>
        </div>
      </div>

      {/* Conducting gesture lines */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px"
            style={{
              background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.05 - i * 0.01}), transparent)`,
              top: `${20 + i * 15}%`,
              transform: `rotate(${-2 + i}deg)`,
              animation: 'gesture-flow 4s ease-in-out infinite',
              animationDelay: `${i * 0.3}s`
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default IntroSection;