import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background */}
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('199c3681-7fda-4d9c-9746-f8178d65fb22', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="Contemporary Ensemble"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/95 via-black/80 to-transparent" />
        
        {/* Abstract geometric patterns representing contemporary music notation */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute bg-white/5"
              style={{
                width: `${Math.random() * 100}px`,
                height: '1px',
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                transform: `rotate(${Math.random() * 360}deg)`,
                animation: `notation ${3 + Math.random() * 4}s ease-in-out infinite`,
                animationDelay: `${i * 0.2}s`
              }}
            />
          ))}
        </div>
      </div>
      
      {/* Content */}
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-4xl">
          {/* Title with dynamic split effect */}
          <div className="overflow-hidden mb-12">
            <div className="transform hover:scale-105 transition-transform duration-700">
              <div className="flex flex-col gap-4">
                <div className="font-serif text-[8rem] leading-none text-[#E63946]">
                  Contemporary<br />
                  Ensemble
                </div>
                <h1 className="font-serif text-[6rem] leading-none text-white">
                  Fellowship
                </h1>
              </div>
            </div>
          </div>

          {/* Description with animated line */}
          <div className="relative pl-12 max-w-2xl">
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#E63946] to-transparent" />
            <p className="text-2xl text-white/80 font-light leading-relaxed">
              Join a pioneering ensemble dedicated to advancing the music of our time. Collaborate with over 40 composers, premiere groundbreaking works, and shape the future of classical music.
            </p>
          </div>

          {/* Key Statistics */}
          <div className="mt-16 grid grid-cols-2 gap-8">
            <div className="text-white/90">
              <div className="font-serif text-5xl mb-2">40+</div>
              <div className="text-sm tracking-wide text-white/60">World Premieres</div>
            </div>
            <div className="text-white/90">
              <div className="font-serif text-5xl mb-2">6</div>
              <div className="text-sm tracking-wide text-white/60">Week Residency</div>
            </div>
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
        <div className="w-[1px] h-24 bg-gradient-to-b from-white/0 via-white/50 to-white/0 animate-pulse" />
      </div>

      <style>{`
        @keyframes notation {
          0%, 100% { 
            opacity: 0.2;
            transform: rotate(var(--rotation)) translateY(0);
          }
          50% { 
            opacity: 0.5;
            transform: rotate(var(--rotation)) translateY(-20px);
          }
        }
      `}</style>
    </div>
  );
};

export default HeroSection;