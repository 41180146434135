import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/conducting/HeroSection';
import IntroSection from '../components/conducting/IntroSection';
import OpportunitiesSection from '../components/conducting/OpportunitiesSection';
import CurriculumSection from '../components/conducting/CurriculumSection';
import RequirementsSection from '../components/conducting/RequirementsSection';
import FacultySection from '../components/programs/FacultySection';
import programData from '../data/programs/cdp.json';

const ConductingProgram = () => {
  // Process faculty data
  const faculty = programData.programs?.[0]?.faculty?.map(f => ({
    name: f.members_id.name,
    profileImage: f.members_id.profile_image?.id,
    biography: f.members_id.biography,
    affiliation: f.members_id.affiliation,
    instruments: f.members_id.instruments?.map(i => ({
      value: i.instruments_id.value,
      label: i.instruments_id.label
    })) || []
  })) || [];

  // Get audition requirements from data
  const requirements = programData.programs?.[0]?.audition_requirements
    ?.map(req => req.audition_requirements_id.requirement)
    .sort((a, b) => a.sort - b.sort) || [];

  return (
    <div>
      <Header variant="dark" />
      
      <HeroSection />
      <IntroSection />
      <OpportunitiesSection />
      
      <FacultySection
        faculty={faculty}
        title="Guest Conductors"
        description="Work with conductors who have led major orchestras worldwide and shaped the careers of emerging artists."
      />
      
      <CurriculumSection />
      <RequirementsSection requirements={requirements} />
      
      <Footer />
    </div>
  );
};

export default ConductingProgram;