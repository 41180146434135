import React from 'react';

interface ReviewSectionProps {
  title: string;
  children: React.ReactNode;
}

const CONFIG = {
  styles: {
    wrapper: "space-y-6",
    title: "font-medium text-lg mb-6",
    content: "space-y-4"
  }
};

export const ReviewSection: React.FC<ReviewSectionProps> = ({ title, children }) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <h4 className={CONFIG.styles.title}>{title}</h4>
      <div className={CONFIG.styles.content}>
        {children}
      </div>
    </div>
  );
};

export default ReviewSection;