import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createApplicationCheckout } from '../../services/stripe/stripe.service';

interface PaymentProcessorProps {
  amount: number;
  onBeforeCheckout?: () => Promise<void>;
  onError?: (error: Error) => void;
}

const CONFIG = {
  errors: {
    NO_USER: 'Authentication required',
    NO_EMAIL: 'User email required',
    PAYMENT_FAILED: 'Failed to process payment. Please try again.'
  }
};

export const PaymentProcessor: React.FC<PaymentProcessorProps> = ({
  amount,
  onBeforeCheckout,
  onError
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();

  const handlePayment = async () => {
    if (!currentUser) {
      setError(CONFIG.errors.NO_USER);
      return;
    }

    if (!currentUser.email) {
      setError(CONFIG.errors.NO_EMAIL);
      return;
    }

    try {
      setIsProcessing(true);
      setError(null);

      // Run pre-checkout hook if provided
      if (onBeforeCheckout) {
        await onBeforeCheckout();
      }

      // Initialize Stripe checkout
      await createApplicationCheckout({
        userId: currentUser.uid,
        userEmail: currentUser.email,
        amount,
        currency: 'usd',
        purpose: 'application_fee'
      });
    } catch (err) {
      console.error('Payment failed:', err);
      setError(CONFIG.errors.PAYMENT_FAILED);
      if (onError && err instanceof Error) {
        onError(err);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-4">
      <button
        onClick={handlePayment}
        disabled={isProcessing}
        aria-disabled={isProcessing}
        className={`w-full relative overflow-hidden group ${
          isProcessing ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        <span className="block px-6 py-4 border-2 border-white rounded-md text-center">
          <span className="relative z-10 text-sm tracking-wide group-hover:text-[#1D3557] transition-colors duration-500">
            {isProcessing ? 'Processing...' : 'Submit Application & Pay'}
          </span>
        </span>
        <div className="absolute inset-0 bg-white -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
      </button>

      {error && (
        <div className="mt-4 p-3 bg-red-500/20 text-red-100 text-sm rounded">
          {error}
        </div>
      )}
    </div>
  );
};