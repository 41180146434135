import React from 'react';
import { getAssetUrl, IMAGE_SIZES } from '../../../utils/directus';

interface Feature {
  title: string;
  description: string;
  imageId: string;
  color: string;
}

interface FeaturesProps {
  features: Feature[];
}

const Features: React.FC<FeaturesProps> = ({ features }) => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="group relative"
            >
              <div className="relative aspect-square overflow-hidden mb-8">
                <img
                  src={getAssetUrl(feature.imageId, IMAGE_SIZES.feature)}
                  alt={feature.title}
                  className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
                />
                <div 
                  className="absolute inset-0 mix-blend-color opacity-10 group-hover:opacity-0 transition-opacity duration-700"
                  style={{ backgroundColor: feature.color }}
                />
              </div>
              
              <div className="px-8 space-y-4">
                <h3 className="font-serif text-2xl">{feature.title}</h3>
                <p className="text-[#1D3557]/70 leading-relaxed">
                  {feature.description}
                </p>
              </div>

              <div 
                className="absolute -inset-4 bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-700 -z-10"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;