import React from 'react';
import { ArrowRight } from 'lucide-react';

interface Requirements {
  instrumentalists: string[];
  composers: string[];
  vocalists: string[];
}

interface ApplicationProcessProps {
  title: string;
  description: string;
  requirements: Requirements;
}

const ApplicationProcess: React.FC<ApplicationProcessProps> = ({
  title,
  description,
  requirements
}) => {
  return (
    <section className="py-16 md:py-24 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-3xl mb-16">
          <h2 className="font-serif text-4xl mb-6">{title}</h2>
          <p className="text-lg text-[#1D3557]/80 leading-relaxed">
            {description}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="space-y-4">
            <h3 className="font-serif text-xl">Instrumentalists</h3>
            <ul className="list-disc list-inside space-y-2 text-[#1D3557]/70">
              {requirements.instrumentalists.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className="font-serif text-xl">Composers</h3>
            <ul className="list-disc list-inside space-y-2 text-[#1D3557]/70">
              {requirements.composers.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>

          <div className="space-y-4">
            <h3 className="font-serif text-xl">Vocalists</h3>
            <ul className="list-disc list-inside space-y-2 text-[#1D3557]/70">
              {requirements.vocalists.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex justify-center">
          <a 
            href="/apply" 
            className="group relative overflow-hidden"
          >
            <span className="block px-8 py-4 border-2 border-[#1D3557]">
              <span className="relative z-10 flex items-center gap-2 text-sm tracking-wide group-hover:text-white transition-colors duration-500">
                Apply Now
                <ArrowRight 
                  size={16} 
                  className="group-hover:translate-x-2 transition-transform duration-300"
                />
              </span>
            </span>
            <div className="absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ApplicationProcess;