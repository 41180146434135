import React from 'react';
import { X } from 'lucide-react';
import FormField from '../FormField';

interface Achievement {
  year: string;
  description: string;
}

interface HonorsAchievementsProps {
  honors: Achievement[];
  onChange: (honors: Achievement[]) => void;
}

const CONFIG = {
  title: "Honors & Achievements",
  description: "List any significant musical honors, awards, or achievements.",
  fields: {
    year: {
      label: "Year",
      placeholder: "e.g., 2023"
    },
    description: {
      label: "Achievement",
      placeholder: "Describe your honor or achievement"
    }
  },
  addButton: "Add Another Achievement",
  styles: {
    section: "space-y-6",
    description: "text-sm text-[#1D3557]/60 mb-6",
    list: "space-y-6",
    item: {
      wrapper: "bg-[#1D3557]/[0.02] p-6 rounded-lg relative",
      grid: "grid grid-cols-1 md:grid-cols-4 gap-6",
      remove: "absolute top-4 right-4 text-[#1D3557]/40 hover:text-[#E63946] transition-colors"
    },
    button: {
      base: "relative overflow-hidden group mt-6",
      container: "block px-6 py-3 border-2 border-[#1D3557]",
      text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
      background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
    }
  }
};

const HonorsAchievements: React.FC<HonorsAchievementsProps> = ({ 
  honors = [{ year: '', description: '' }],
  onChange 
}) => {
  const handleAdd = () => {
    onChange([...honors, { year: '', description: '' }]);
  };

  const handleRemove = (index: number) => {
    onChange(honors.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, field: keyof Achievement, value: string) => {
    const updatedHonors = honors.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    onChange(updatedHonors);
  };

  return (
    <div className={CONFIG.styles.section}>
      <h4 className="text-lg font-medium">{CONFIG.title}</h4>
      <p className={CONFIG.styles.description}>{CONFIG.description}</p>

      <div className={CONFIG.styles.list}>
        {honors.map((honor, index) => (
          <div key={index} className={CONFIG.styles.item.wrapper}>
            {index > 0 && (
              <button
                onClick={() => handleRemove(index)}
                className={CONFIG.styles.item.remove}
              >
                <X size={20} />
              </button>
            )}
            
            <div className={CONFIG.styles.item.grid}>
              <div>
                <FormField
                  label={CONFIG.fields.year.label}
                  type="text"
                  value={honor.year}
                  onChange={(e) => handleChange(index, 'year', e.target.value)}
                  placeholder={CONFIG.fields.year.placeholder}
                  required
                />
              </div>
              <div className="md:col-span-3">
                <FormField
                  label={CONFIG.fields.description.label}
                  type="text"
                  value={honor.description}
                  onChange={(e) => handleChange(index, 'description', e.target.value)}
                  placeholder={CONFIG.fields.description.placeholder}
                  required
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <button 
        onClick={handleAdd}
        className={CONFIG.styles.button.base}
      >
        <span className={CONFIG.styles.button.container}>
          <span className={CONFIG.styles.button.text}>
            {CONFIG.addButton}
          </span>
        </span>
        <div className={CONFIG.styles.button.background} />
      </button>
    </div>
  );
};

export default HonorsAchievements;