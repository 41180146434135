export const APPLICATION_CONFIG = {
  status: {
    isOpen: false, // Set to false since it's before December 15
    message: "Applications for the 2025 season will open on December 15, 2024"
  },
  deadlines: {
    start: "2024-12-15",
    phases: [
      {
        deadline: "2025-01-01",
        label: "Submit by January 1, 2025",
        fees: {
          student: 40,
          fellowship: 55
        }
      },
      {
        deadline: "2025-02-01",
        label: "Submit by February 1, 2025",
        fees: {
          student: 60,
          fellowship: 75
        }
      },
      {
        deadline: "2025-02-01", // After this date
        label: "After February 1, 2025",
        fees: {
          student: 75,
          fellowship: 90
        }
      }
    ]
  },
  fees: {
    currency: 'usd',
    getCurrentFees: () => {
      const now = new Date();
      for (const phase of APPLICATION_CONFIG.deadlines.phases) {
        if (now <= new Date(phase.deadline)) {
          return phase.fees;
        }
      }
      // Return last phase fees if all deadlines passed
      return APPLICATION_CONFIG.deadlines.phases[APPLICATION_CONFIG.deadlines.phases.length - 1].fees;
    },
    getCurrentPhase: () => {
      const now = new Date();
      for (const phase of APPLICATION_CONFIG.deadlines.phases) {
        if (now <= new Date(phase.deadline)) {
          return phase;
        }
      }
      return APPLICATION_CONFIG.deadlines.phases[APPLICATION_CONFIG.deadlines.phases.length - 1];
    }
  },
  stripe: {
    publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    allowedParameters: [
      'lineItems',
      'mode',
      'successUrl',
      'cancelUrl',
      'customerEmail',
      'sessionId'
    ] as const
  }
} as const;