export const ROUTES = {
  home: {
    path: '/',
    component: 'HomePage'
  },
  institute: {
    path: '/the-institute',
    component: 'TheInstitute',
    children: {
      preCollege: {
        path: '/the-institute/pre-college',
        component: 'PreCollege'
      },
      composition: {
        path: '/the-institute/programs/composition-program',
        component: 'CompositionProgram'
      },
      conducting: {
        path: '/the-institute/programs/conducting-program',
        component: 'ConductingProgram'
      },
      instrumental: {
        path: '/the-institute/programs/instrumental-program',
        component: 'InstrumentalProgram'
      },
      piano: {
        path: '/the-institute/programs/piano-institute-and-seminar',
        component: 'PianoProgram'
      },
      vocal: {
        path: '/the-institute/programs/vocal-institute',
        component: 'VocalInstitute'
      },
      fellowship: {
        path: '/the-institute/programs/fellowship-program',
        component: 'FellowshipProgram',
        children: {
          contemporary: {
            path: '/the-institute/programs/fellowship-program/contemporary-ensemble',
            component: 'ContemporaryEnsemble'
          }
        }
      },
      onlineApplication: {
        path: '/the-institute/online-application',
        component: 'OnlineApplication'
      },
      financialAid: {
        path: '/the-institute/financial-aid',
        component: 'FinancialAid'
      },
      residencyGuidelines: {
        path: '/the-institute/residency-guidelines',
        component: 'ResidencyGuidelines'
      },
      feesAndDeadlines: {
        path: '/the-institute/fees-dates-and-deadlines',
        component: 'FeesAndDeadlines'
      }
    }
  },
  festival: {
    path: '/the-festival',
    children: {
      about: {
        path: '/the-festival/about-amf',
        component: 'AboutAMF'
      },
      diversity: {
        path: '/the-festival/diversity',
        component: 'Diversity'
      },
      welcome: {
        path: '/the-festival/welcome',
        component: 'Welcome'
      },
      artists: {
        path: '/the-festival/artists',
        component: 'Artists'
      }
    }
  },
  concerts: {
    path: '/concerts',
    component: 'Concerts'
  },
  getInvolved: {
    path: '/get-involved',
    component: 'GetInvolved',
    children: {
      volunteer: {
        path: '/get-involved/volunteer',
        component: 'Volunteer'
      },
      jobs: {
        path: '/get-involved/jobs',
        component: 'Jobs'
      },
      internship: {
        path: '/get-involved/jobs/internship',
        component: 'Internship'
      }
    }
  },
  legal: {
    path: '/legal',
    children: {
      terms: {
        path: '/legal/terms-use-privacy-policy',
        component: 'Legal'
      }
    }
  },
  contact: {
    path: '/contact-us',
    component: 'Contact'
  },
  visitors: {
    path: '/visitors',
    component: 'Visitors'
  },
  account: {
    path: '/account',
    component: 'Account',
    children: {
      dashboard: {
        path: '/account/dashboard',
        component: 'Dashboard',
        protected: true
      },
      application: {
        path: '/account/application',
        component: 'ApplicationForm',
        protected: true
      }
    }
  }
} as const;