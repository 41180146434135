import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const MissionSection = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
          <div className="space-y-8">
            <h2 className="font-serif text-7xl relative">
              Advancing New Music
              <div className="absolute -left-8 -bottom-8 w-24 h-24 border-l-2 border-b-2 border-[#E63946] opacity-20" />
            </h2>
            
            <div className="prose prose-lg prose-p:text-[#1D3557]/70">
              <p className="text-2xl leading-relaxed">
                The AMF Contemporary Ensemble Fellowship seeks to identify and support musicians deeply committed to promoting new and contemporary music.
              </p>
              <p className="text-xl">
                Each fellow receives a stipend of $800 and complete financial coverage of tuition, housing, and meals during the six weeks of residency, allowing them to focus on their artistic growth and development free from financial burden.
              </p>
              <p className="text-xl">
                As a resident ensemble of the festival, the members have the unique opportunity to collaborate with the festival's composer community, consisting of over 40 composers, throughout the festival season.
              </p>
            </div>
          </div>

          <div className="relative">
            <img
              src={getAssetUrl('5f430d44-005a-415a-afea-a28adfcafc84', {
                width: 800,
                height: 1000,
                quality: 85,
                fit: 'cover'
              })}
              alt="Contemporary Ensemble Performance"
              className="w-full rounded-lg shadow-xl"
            />
            
            {/* Decorative elements */}
            <div className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2 border-[#457B9D] opacity-10" />
            
            {/* Abstract notation lines */}
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute bg-[#1D3557]/10"
                style={{
                  width: '100px',
                  height: '1px',
                  right: `-${50 + i * 10}px`,
                  top: `${20 + i * 20}%`,
                  transform: `rotate(${-30 + i * 15}deg)`,
                  animation: `floatLine ${3 + i * 0.5}s ease-in-out infinite`,
                  animationDelay: `${i * 0.2}s`
                }}
              />
            ))}
          </div>
        </div>
      </div>

      <style>{`
        @keyframes floatLine {
          0%, 100% { transform: rotate(var(--rotation)) translateX(0); }
          50% { transform: rotate(var(--rotation)) translateX(-20px); }
        }
      `}</style>
    </section>
  );
};

export default MissionSection;