import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFile, FileUploadMetadata } from '../../utils/storage';
import { Upload, Loader2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface FileUploaderProps {
  accept: Record<string, string[]>;
  maxSize: number;
  path: string;
  onUploadComplete: (url: string, metadata: FileUploadMetadata) => void;
  onUploadStart?: () => void;
  onError?: (error: string) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const CONFIG = {
  styles: {
    dropzone: {
      base: "border-2 border-dashed rounded-lg p-8 text-center transition-colors",
      active: "border-[#E63946] bg-[#E63946]/5",
      inactive: "border-[#1D3557]/20 hover:border-[#1D3557]/40",
      disabled: "opacity-50 pointer-events-none"
    },
    content: {
      wrapper: "flex flex-col items-center space-y-4",
      icon: "w-8 h-8 text-[#1D3557]/40",
      text: {
        primary: "text-[#1D3557]/60",
        secondary: "text-sm text-[#1D3557]/40"
      }
    }
  }
};

const FileUploader: React.FC<FileUploaderProps> = ({
  accept,
  maxSize,
  path,
  onUploadComplete,
  onUploadStart,
  onError,
  disabled = false,
  children
}) => {
  const { currentUser } = useAuth();

  const handleFile = async (file: File) => {
    if (!currentUser?.uid) {
      onError?.('Authentication required');
      return;
    }

    if (file.size > maxSize) {
      onError?.(`File size must be less than ${Math.round(maxSize / 1024 / 1024)}MB`);
      return;
    }

    try {
      onUploadStart?.();

      const url = await uploadFile(
        file,
        currentUser.uid,
        path,
        {
          onProgress: (progress) => {
            console.log('[FileUploader] Upload progress:', progress);
          },
          onError: (error) => {
            console.error('[FileUploader] Upload error:', error);
            onError?.(error.message);
          },
          onSuccess: (url, metadata) => {
            console.log('[FileUploader] Upload success:', { url, metadata });
            onUploadComplete(url, metadata);
          }
        }
      );

      return url;
    } catch (error: any) {
      console.error('[FileUploader] Upload failed:', error);
      onError?.(error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      console.log('[FileUploader] File dropped:', acceptedFiles[0].name);
      handleFile(acceptedFiles[0]);
    }
  }, [currentUser?.uid]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    maxSize,
    disabled: disabled || !currentUser
  });

  return (
    <div
      {...getRootProps()}
      className={`${CONFIG.styles.dropzone.base} 
        ${isDragActive ? CONFIG.styles.dropzone.active : CONFIG.styles.dropzone.inactive}
        ${disabled ? CONFIG.styles.dropzone.disabled : ''}`}
    >
      <input {...getInputProps()} />
      <div className={CONFIG.styles.content.wrapper}>
        {children || (
          <>
            {disabled ? (
              <div className="flex items-center justify-center gap-2">
                <Loader2 className="w-4 h-4 animate-spin" />
                <span>Uploading...</span>
              </div>
            ) : (
              <>
                <Upload className={CONFIG.styles.content.icon} />
                <div>
                  <p className={CONFIG.styles.content.text.primary}>
                    Click to upload or drag and drop
                  </p>
                  <p className={CONFIG.styles.content.text.secondary}>
                    Maximum file size: {Math.round(maxSize / 1024 / 1024)}MB
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FileUploader;