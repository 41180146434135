import React from 'react';

interface Activity {
  time: string;
  title: string;
  description: string;
}

interface ScheduleSection {
  title: string;
  activities: Activity[];
}

interface ScheduleProps {
  title: string;
  description: string;
  sections: ScheduleSection[];
}

const Schedule: React.FC<ScheduleProps> = ({ title, description, sections }) => {
  return (
    <section className="py-16 md:py-24">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-4xl mb-6">{title}</h2>
        <p className="text-lg text-[#1D3557]/80 mb-12 max-w-3xl">{description}</p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {sections.map((section, index) => (
            <div key={index} className="space-y-6">
              <h3 className="font-serif text-xl text-[#457B9D]">{section.title}</h3>
              <div className="space-y-6">
                {section.activities.map((activity, idx) => (
                  <div key={idx} className="space-y-2">
                    <div className="flex items-baseline gap-2">
                      <span className="text-sm font-medium">{activity.time}</span>
                      <span className="text-[#E63946]">•</span>
                      <span className="font-medium">{activity.title}</span>
                    </div>
                    <p className="text-sm text-[#1D3557]/60">{activity.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Schedule;