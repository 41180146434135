import React from 'react';
import { Twitter, Facebook, Instagram } from 'lucide-react';
import { getAssetUrl } from '../utils/directus';
import { getRouteUrl } from '../utils/navigation';
import JoinNextSeason from './JoinNextSeason';

const CONFIG = {
  logo: {
    id: '897bf2b2-9605-4120-917d-8f097ba68683',
    alt: 'Atlantic Music Festival'
  },
  quote: {
    text: "This will be our reply to violence: to make music more intensely, more beautifully, more devotedly than ever before.",
    author: "Leonard Bernstein"
  },
  navigation: {
    programs: {
      title: 'Programs',
      links: [
        { label: 'Pre-College', url: getRouteUrl('/the-institute/pre-college') },
        { label: 'Composition Program', url: getRouteUrl('/the-institute/programs/composition-program') },
        { label: 'Conducting Program', url: getRouteUrl('/the-institute/programs/conducting-program') },
        { label: 'Instrumental Program', url: getRouteUrl('/the-institute/programs/instrumental-program') },
        { label: 'Piano Institute', url: getRouteUrl('/the-institute/programs/piano-institute-and-seminar') },
        { label: 'Vocal Arts Institute', url: getRouteUrl('/the-institute/programs/vocal-institute') }
      ]
    },
    visit: {
      title: 'Visit',
      links: [
        { label: 'Concert Calendar', url: getRouteUrl('/concerts') },
        { label: 'About AMF', url: getRouteUrl('/the-festival/about-amf') },
        { label: 'Artists', url: getRouteUrl('/the-festival/artists') },
        { label: 'Visitor Information', url: getRouteUrl('/visitors') }
      ]
    },
    support: {
      title: 'Support',
      links: [
        { label: 'Make a Gift', url: getRouteUrl('/get-involved') },
        { label: 'Volunteer', url: getRouteUrl('/get-involved/volunteer') },
        { label: 'Jobs', url: getRouteUrl('/get-involved/jobs') },
        { label: 'Internships', url: getRouteUrl('/get-involved/jobs/internship') }
      ]
    }
  },
  legal: {
    copyright: '© 2024 Atlantic Music Festival.',
    rights: 'All rights reserved.',
    links: [
      { label: 'Privacy Policy', url: getRouteUrl('/legal/terms-use-privacy-policy') },
      { label: 'Terms of Use', url: getRouteUrl('/legal/terms-use-privacy-policy') }
    ]
  },
  social: {
    links: [
      { icon: Twitter, url: 'https://twitter.com/experienceamf', label: 'X (Twitter)' },
      { icon: Facebook, url: 'https://facebook.com/experienceamf', label: 'Facebook' },
      { icon: Instagram, url: 'https://instagram.com/amfgram', label: 'Instagram' }
    ]
  },
  styles: {
    background: "bg-[#1D3557]/[0.02]",
    linkHover: "hover:text-[#E63946] transition-colors",
    text: {
      base: "text-[#1D3557]/70",
      light: "text-[#1D3557]/60"
    },
    quote: {
      wrapper: "space-y-2",
      text: "text-lg italic text-[#1D3557]/70",
      author: "text-sm text-[#1D3557]/60"
    }
  }
};

const Footer: React.FC = () => {
  return (
    <footer className="w-full">
      <JoinNextSeason />

      <div className={`w-full ${CONFIG.styles.background}`}>
        <div className="max-w-screen-2xl mx-auto px-16 py-24">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-16">
            {/* Logo and Quote */}
            <div className="md:col-span-4 space-y-8">
              <img 
                src={getAssetUrl(CONFIG.logo.id)}
                alt={CONFIG.logo.alt}
                className="h-16 w-auto object-contain"
              />
              <div className={CONFIG.styles.quote.wrapper}>
                <p className={CONFIG.styles.quote.text}>
                  "{CONFIG.quote.text}"
                </p>
                <p className={CONFIG.styles.quote.author}>
                  — {CONFIG.quote.author}
                </p>
              </div>
            </div>

            {/* Navigation Columns */}
            <div className="md:col-span-8 grid grid-cols-1 sm:grid-cols-3 gap-12">
              {Object.entries(CONFIG.navigation).map(([key, section]) => (
                <div key={key}>
                  <h5 className="font-serif text-lg mb-6">{section.title}</h5>
                  <ul className="space-y-4">
                    {section.links.map((link, index) => (
                      <li key={index}>
                        <a href={link.url} className={`text-sm font-light ${CONFIG.styles.text.base} ${CONFIG.styles.linkHover}`}>
                          {link.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="w-full border-t border-[#1D3557]/10 mt-20">
            <div className="py-8">
              <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                <div className={`text-sm font-light ${CONFIG.styles.text.light} flex flex-wrap items-center gap-x-4 gap-y-2`}>
                  <span>{CONFIG.legal.copyright}</span>
                  <span>{CONFIG.legal.rights}</span>
                  <div className="flex items-center gap-4">
                    {CONFIG.legal.links.map((link, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <span className="text-[#1D3557]/20">|</span>}
                        <a href={link.url} className={CONFIG.styles.linkHover}>{link.label}</a>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="flex items-center gap-6">
                  {CONFIG.social.links.map((link, index) => {
                    const Icon = link.icon;
                    return (
                      <a 
                        key={index}
                        href={link.url}
                        className={`${CONFIG.styles.text.light} ${CONFIG.styles.linkHover}`}
                        aria-label={link.label}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon size={18} />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;