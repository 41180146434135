import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Pencil, Trash, Music, FileText, Link as LinkIcon } from 'lucide-react';
import { PortfolioItem } from '../types';
import { PORTFOLIO_CONFIG } from '../config';
import AudioPreview from './previews/AudioPreview';
import PDFPreview from './previews/PDFPreview';
import LinkPreview from './previews/LinkPreview';
import FormField from '../../FormField';

interface PortfolioItemCardProps {
  item: PortfolioItem;
  dragHandleProps?: any;
  onEdit: (updates: Partial<PortfolioItem>) => void;
  onDelete: (id: string) => void;
}

const PortfolioItemCard: React.FC<PortfolioItemCardProps> = ({
  item,
  dragHandleProps,
  onEdit,
  onDelete
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(item.title);
  const [description, setDescription] = useState(item.description);

  const getIcon = () => {
    switch (item.fileType) {
      case 'mp3':
        return <Music size={16} />;
      case 'pdf':
        return <FileText size={16} />;
      default:
        return <LinkIcon size={16} />;
    }
  };

  const handleSave = () => {
    onEdit({ ...item, title, description });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTitle(item.title);
    setDescription(item.description);
    setIsEditing(false);
  };

  const renderPreview = () => {
    if (item.type === 'file') {
      if (item.fileType === 'mp3') {
        return <AudioPreview url={item.url} />;
      }
      if (item.fileType === 'pdf') {
        return <PDFPreview url={item.url} />;
      }
    }
    if (item.type === 'link') {
      return <LinkPreview url={item.url} />;
    }
    return null;
  };

  return (
    <div className="bg-white border border-[#1D3557]/10 rounded-lg overflow-hidden transition-all duration-300">
      <div className="p-6">
        <div className="flex items-start justify-between gap-4">
          <div {...dragHandleProps} className="mt-1 cursor-move text-[#1D3557]/40 hover:text-[#1D3557]/60 transition-colors">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
              <circle cx="4" cy="4" r="1.5" />
              <circle cx="12" cy="4" r="1.5" />
              <circle cx="4" cy="12" r="1.5" />
              <circle cx="12" cy="12" r="1.5" />
            </svg>
          </div>
          
          <div className="flex-1">
            {isEditing ? (
              <div className="space-y-4">
                <FormField
                  label="Title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                <FormField
                  label="Description"
                  type="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={handleCancel}
                    className="px-3 py-1.5 text-sm text-[#1D3557] hover:text-[#E63946] transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    className="px-3 py-1.5 text-sm bg-[#1D3557] text-white rounded hover:bg-[#E63946] transition-colors"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center gap-2 mb-2">
                  {getIcon()}
                  <h4 className="font-medium">{item.title}</h4>
                </div>
                <p className="text-sm text-[#1D3557]/60">{item.description}</p>
              </>
            )}
          </div>

          {!isEditing && (
            <div className="flex items-center gap-2">
              <button
                onClick={() => setIsEditing(true)}
                className="p-1.5 text-[#1D3557]/40 hover:text-[#E63946] transition-colors"
                title="Edit"
              >
                <Pencil size={18} />
              </button>
              <button
                onClick={() => onDelete(item.id)}
                className="p-1.5 text-[#1D3557]/40 hover:text-[#E63946] transition-colors"
                title="Delete"
              >
                <Trash size={18} />
              </button>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="p-1.5 text-[#1D3557]/40 hover:text-[#1D3557]/60 transition-colors"
                title={isExpanded ? "Collapse" : "Expand"}
              >
                {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
              </button>
            </div>
          )}
        </div>
      </div>

      {isExpanded && !isEditing && (
        <div className="border-t border-[#1D3557]/10 bg-[#1D3557]/[0.02]">
          <div className="p-6">
            {renderPreview()}
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioItemCard;