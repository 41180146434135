import { loadStripe } from '@stripe/stripe-js';

export async function createApplicationCheckout(params: {
  userId: string;
  userEmail: string;
  amount: number;
  currency: string;
  purpose: string;
}): Promise<void> {
  try {
    const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
    if (!stripe) {
      throw new Error('Failed to initialize Stripe');
    }

    const response = await fetch('/api/stripe/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...params,
        successUrl: `${window.location.origin}/account/dashboard?success=true`,
        cancelUrl: `${window.location.origin}/account/dashboard?canceled=true`
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to create checkout session');
    }

    const data = await response.json();
    if (!data?.sessionId) {
      throw new Error('No session ID returned');
    }

    const { error } = await stripe.redirectToCheckout({
      sessionId: data.sessionId
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('[Stripe] Payment failed:', error);
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to process payment');
  }
}