export const ARTIST_CONFIG = {
  header: {
    title: "Artists",
    description: "Meet our extraordinary artists who shape the future of classical music.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  filters: {
    categories: [
      { 
        id: 'strings', 
        label: 'Strings', 
        values: ['violin', 'viola', 'cello', 'contrabass'],
        exactMatch: true 
      },
      { 
        id: 'woodwinds', 
        label: 'Woodwinds', 
        values: ['flute', 'oboe', 'clarinet', 'bassoon'],
        exactMatch: true
      },
      { 
        id: 'brass', 
        label: 'Brass', 
        values: ['trumpet', 'french-horn', 'horn', 'tenortrombone', 'basstrombone', 'tuba'],
        exactMatch: true
      },
      { 
        id: 'percussion', 
        label: 'Percussion', 
        values: ['percussion', 'timpani'],
        exactMatch: true
      },
      { 
        id: 'piano', 
        label: 'Piano', 
        values: ['piano'],
        exactMatch: true
      },
      { 
        id: 'voice', 
        label: 'Voice', 
        values: ['voice', 'soprano', 'mezzo-soprano', 'alto', 'tenor', 'baritone', 'bass-voice'],
        exactMatch: true
      },
      { 
        id: 'composition', 
        label: 'Composition', 
        values: ['composition', 'composer'],
        exactMatch: false
      }
    ]
  },
  emptyMessage: "We are currently finalizing our artist roster for this category. Please check back soon for updates.",
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    filters: {
      wrapper: "mb-16 flex flex-wrap gap-4",
      button: {
        base: "px-6 py-2 text-sm rounded-full transition-colors",
        active: "bg-[#1D3557] text-white",
        inactive: "bg-[#1D3557]/[0.05] hover:bg-[#1D3557]/10"
      }
    },
    search: {
      wrapper: "relative mb-16",
      input: "w-full px-12 py-4 bg-[#1D3557]/[0.02] rounded-lg text-[#1D3557] placeholder:text-[#1D3557]/40 focus:outline-none focus:ring-2 focus:ring-[#E63946]",
      icon: "absolute left-4 top-1/2 -translate-y-1/2 text-[#1D3557]/40"
    },
    grid: "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8",
    artist: {
      wrapper: "group cursor-pointer",
      imageWrapper: "aspect-[3/4] mb-4 overflow-hidden rounded-lg",
      image: "w-full h-full object-cover filter grayscale group-hover:grayscale-0 scale-105 group-hover:scale-100 transition-all duration-700",
      placeholder: "w-full h-full bg-[#1D3557]/5 flex items-center justify-center",
      placeholderText: "font-serif text-4xl text-[#1D3557]/20",
      name: "font-serif text-lg mb-1",
      affiliation: "text-sm text-[#457B9D]"
    },
    modal: {
      overlay: "fixed inset-0 bg-black/80 z-50",
      wrapper: "fixed inset-0 flex items-center justify-center p-4 z-50",
      content: "bg-white max-w-3xl w-full max-h-[90vh] overflow-y-auto rounded-lg p-8 relative",
      close: "absolute top-4 right-4 text-[#1D3557]/40 hover:text-[#E63946] transition-colors",
      image: "max-w-md mx-auto aspect-[3/4] rounded-lg overflow-hidden mb-8",
      title: "font-serif text-3xl mb-2",
      affiliation: "text-[#457B9D] text-lg mb-6",
      bio: "prose prose-lg prose-headings:font-serif prose-a:text-[#E63946] prose-a:no-underline hover:prose-a:underline"
    },
    empty: "text-center py-12 text-[#1D3557]/60"
  }
} as const;