import React from 'react';
import { X } from 'lucide-react';
import { useArtistContext } from '../../contexts/ArtistContext';
import { getAssetUrl } from '../../utils/directus';
import { ARTIST_CONFIG } from '../../config/artists';

const ArtistModal = () => {
  const { selectedArtist, setSelectedArtist } = useArtistContext();

  if (!selectedArtist) return null;

  return (
    <>
      <div 
        className={ARTIST_CONFIG.styles.modal.overlay}
        onClick={() => setSelectedArtist(null)}
      />
      <div className={ARTIST_CONFIG.styles.modal.wrapper}>
        <div className={ARTIST_CONFIG.styles.modal.content}>
          <button
            onClick={() => setSelectedArtist(null)}
            className={ARTIST_CONFIG.styles.modal.close}
          >
            <X size={24} />
          </button>

          {selectedArtist.profileImage && (
            <div className={ARTIST_CONFIG.styles.modal.image}>
              <img
                src={getAssetUrl(selectedArtist.profileImage, {
                  width: 600,
                  height: 900,
                  quality: 85,
                  fit: 'cover'
                })}
                alt={selectedArtist.name}
                className="w-full h-full object-cover"
              />
            </div>
          )}

          <h3 className={ARTIST_CONFIG.styles.modal.title}>{selectedArtist.name}</h3>
          {selectedArtist.affiliation && (
            <p className={ARTIST_CONFIG.styles.modal.affiliation}>
              {selectedArtist.affiliation}
            </p>
          )}
          {selectedArtist.biography && (
            <div 
              className={ARTIST_CONFIG.styles.modal.bio}
              dangerouslySetInnerHTML={{ __html: selectedArtist.biography }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ArtistModal;