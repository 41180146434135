import React from 'react';
import FormField from '../../FormField';
import { SURVEY_CONFIG } from '../config';

const HEARD_FROM_OPTIONS = [
  'Teacher/Professor',
  'Friend/Colleague',
  'Social Media',
  'Web Search',
  'Advertisement',
  'Other'
];

interface HeardFromFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export const HeardFromField: React.FC<HeardFromFieldProps> = ({ 
  value = '',
  onChange 
}) => {
  return (
    <FormField 
      label={SURVEY_CONFIG.fields.heardFrom.label}
      type="select"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={SURVEY_CONFIG.fields.heardFrom.required}
    >
      <option value="">Select an option</option>
      {HEARD_FROM_OPTIONS.map((option) => (
        <option key={option} value={option}>{option}</option>
      ))}
    </FormField>
  );
};