import React from 'react';

interface ReviewFieldProps {
  label: string;
  value: React.ReactNode;
}

const CONFIG = {
  styles: {
    wrapper: "flex flex-col md:flex-row md:items-baseline gap-2 md:gap-4",
    label: "text-sm font-medium text-[#1D3557]/60 md:w-1/4",
    value: "text-sm"
  }
};

const ReviewField: React.FC<ReviewFieldProps> = ({ label, value }) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <span className={CONFIG.styles.label}>{label}</span>
      <span className={CONFIG.styles.value}>
        {value || 'Not provided'}
      </span>
    </div>
  );
};

export default ReviewField;