import React from 'react';
import { getAssetUrl, IMAGE_SIZES } from '../../../utils/directus';

interface HeroProps {
  imageId: string;
  title: string;
  description: string;
}

const Hero: React.FC<HeroProps> = ({ imageId, title, description }) => {
  return (
    <div className="relative min-h-screen flex items-center">
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl(imageId, IMAGE_SIZES.hero)}
          alt="Pre-College Program"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
      </div>
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <h1 
          className="font-serif text-6xl md:text-8xl text-white mb-8"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className="text-xl md:text-2xl text-white/80 max-w-2xl font-light leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Hero;