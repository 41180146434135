import React from 'react';
import { HeardFromField } from './components/HeardFromField';
import { TeacherNameField } from './components/TeacherNameField';
import { SURVEY_CONFIG } from './config';
import type { SurveyData } from './types';

interface SurveyProps {
  formData: {
    survey?: Partial<SurveyData>;
  };
  onChange: (data: { survey: SurveyData }) => void;
}

const Survey: React.FC<SurveyProps> = ({ 
  formData = { survey: {} },
  onChange 
}) => {
  // Ensure we have default values
  const data = {
    heardFrom: formData.survey?.heardFrom || '',
    teacherName: formData.survey?.teacherName || ''
  };

  const handleHeardFromChange = (heardFrom: string) => {
    onChange({
      survey: {
        ...data,
        heardFrom,
        // Clear teacher name if not heard from teacher
        teacherName: heardFrom === 'Teacher/Professor' ? data.teacherName : ''
      }
    });
  };

  const handleTeacherNameChange = (teacherName: string) => {
    onChange({
      survey: {
        ...data,
        teacherName
      }
    });
  };

  return (
    <div className={SURVEY_CONFIG.styles.section}>
      <div className={SURVEY_CONFIG.styles.card}>
        <h3 className={SURVEY_CONFIG.styles.title}>{SURVEY_CONFIG.title}</h3>
        <p className={SURVEY_CONFIG.styles.description}>{SURVEY_CONFIG.description}</p>

        <div className={SURVEY_CONFIG.styles.field.wrapper}>
          <HeardFromField 
            value={data.heardFrom}
            onChange={handleHeardFromChange}
          />

          {data.heardFrom === 'Teacher/Professor' && (
            <TeacherNameField
              value={data.teacherName}
              onChange={handleTeacherNameChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Survey;