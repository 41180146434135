import React from 'react';
import { ArrowRight } from 'lucide-react';

interface Program {
  title: string;
  url: string;
  description: string;
}

interface SpecializedPathsProps {
  title: string;
  description: string;
  programs: Program[];
}

const SpecializedPaths: React.FC<SpecializedPathsProps> = ({ 
  title, 
  description, 
  programs 
}) => {
  return (
    <section className="py-16 md:py-24 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-3xl mb-16">
          <h2 className="font-serif text-4xl mb-6">{title}</h2>
          <p className="text-lg text-[#1D3557]/80 leading-relaxed">
            {description}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {programs.map((program, index) => (
            <a 
              key={index}
              href={program.url}
              className="group bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <h3 className="font-serif text-2xl mb-4">{program.title}</h3>
              <p className="text-[#1D3557]/70 mb-6 leading-relaxed">
                {program.description}
              </p>
              <div className="flex items-center gap-2 text-[#E63946]">
                <span className="text-sm">Learn More</span>
                <ArrowRight 
                  size={16} 
                  className="group-hover:translate-x-2 transition-transform duration-300"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SpecializedPaths;