import React from 'react';
import { FESTIVAL_CONFIG } from '../../config/festival';

const CONFIG = {
  title: "Program Tuition",
  description: "Comprehensive package including instruction, housing, and meals.",
  styles: {
    card: {
      wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg",
      title: "font-serif text-xl mb-4",
      content: "space-y-4",
      row: "flex justify-between items-baseline gap-4 pb-2 border-b border-[#1D3557]/10 last:border-0",
      label: "text-[#1D3557]/70",
      value: "font-medium",
      note: "text-sm text-[#457B9D] mt-2"
    }
  }
};

const TuitionFees = () => {
  return (
    <div>
      <h2 className="font-serif text-4xl mb-6">{CONFIG.title}</h2>
      <p className="text-lg text-[#1D3557]/80 mb-8">{CONFIG.description}</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Full Session */}
        <div className={CONFIG.styles.card.wrapper}>
          <h3 className={CONFIG.styles.card.title}>
            Full Session ({FESTIVAL_CONFIG.tuition.fullSession.duration})
          </h3>
          <div className={CONFIG.styles.card.content}>
            <div className={CONFIG.styles.card.row}>
              <span className={CONFIG.styles.card.label}>Student:</span>
              <span className={CONFIG.styles.card.value}>
                ${FESTIVAL_CONFIG.tuition.fullSession.student.toLocaleString()}
              </span>
            </div>
            <div className={CONFIG.styles.card.row}>
              <span className={CONFIG.styles.card.label}>Fellowship:</span>
              <span className={CONFIG.styles.card.value}>
                {FESTIVAL_CONFIG.tuition.fullSession.fellowship}
              </span>
            </div>
            {FESTIVAL_CONFIG.tuition.fullSession.notes.map((note, index) => (
              <p key={index} className={CONFIG.styles.card.note}>{note}</p>
            ))}
          </div>
        </div>

        {/* Single Session */}
        <div className={CONFIG.styles.card.wrapper}>
          <h3 className={CONFIG.styles.card.title}>
            Single Session ({FESTIVAL_CONFIG.tuition.singleSession.duration})
          </h3>
          <div className={CONFIG.styles.card.content}>
            <div className={CONFIG.styles.card.row}>
              <span className={CONFIG.styles.card.label}>Student:</span>
              <span className={CONFIG.styles.card.value}>
                ${FESTIVAL_CONFIG.tuition.singleSession.student.toLocaleString()}
              </span>
            </div>
            <div className={CONFIG.styles.card.row}>
              <span className={CONFIG.styles.card.label}>Fellowship:</span>
              <span className={CONFIG.styles.card.value}>
                {FESTIVAL_CONFIG.tuition.singleSession.fellowship}
              </span>
            </div>
            {FESTIVAL_CONFIG.tuition.singleSession.notes.map((note, index) => (
              <p key={index} className={CONFIG.styles.card.note}>{note}</p>
            ))}
          </div>
        </div>
      </div>

      {/* Additional Fees */}
      <div className="mt-8">
        <h3 className="font-serif text-2xl mb-6">Additional Fees</h3>
        <div className="grid grid-cols-1 gap-4">
          {Object.entries(FESTIVAL_CONFIG.tuition.additionalFees).map(([key, fee]) => (
            <div key={key} className={CONFIG.styles.card.wrapper}>
              <div className={CONFIG.styles.card.content}>
                <div className="flex flex-col md:flex-row md:items-center gap-4">
                  <span className="font-medium md:w-1/4">{key.charAt(0).toUpperCase() + key.slice(1)} Fee</span>
                  <div className="flex-1 grid grid-cols-2 gap-4">
                    <div>
                      <span className="text-sm text-[#1D3557]/60">Student:</span>
                      <span className="block font-medium">${fee.student}</span>
                    </div>
                    <div>
                      <span className="text-sm text-[#1D3557]/60">Fellowship:</span>
                      <span className="block font-medium">{fee.fellowship}</span>
                    </div>
                  </div>
                </div>
                <p className={CONFIG.styles.card.note}>{fee.note}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TuitionFees;