import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAudioPlayer } from '../hooks/useAudioPlayer';
import { Track, TRACKS } from '../data/tracks';

interface AudioContextType {
  currentTrack: Track | null;
  isPlaying: boolean;
  progress: number;
  duration: number;
  volume: number;
  isMuted: boolean;
  tracks: Track[];
  isVisible: boolean;
  playTrack: (track: Track) => void;
  togglePlayPause: () => void;
  seekTo: (time: number) => void;
  setVolume: (volume: number) => void;
  toggleMute: () => void;
  playNextTrack: () => void;
  playPreviousTrack: () => void;
  showPlayer: () => void;
  hidePlayer: () => void;
}

const AudioContext = createContext<AudioContextType | null>(null);

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    currentTrack,
    isPlaying,
    progress,
    duration,
    volume,
    isMuted,
    playTrack,
    togglePlayPause,
    seekTo,
    setVolume,
    toggleMute
  } = useAudioPlayer();

  const showPlayer = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hidePlayer = useCallback(() => {
    setIsVisible(false);
  }, []);

  const playNextTrack = useCallback(() => {
    if (!currentTrack) {
      if (TRACKS.length > 0) {
        playTrack(TRACKS[0]);
      }
      return;
    }
    const currentIndex = TRACKS.findIndex(track => track.id === currentTrack.id);
    const nextIndex = (currentIndex + 1) % TRACKS.length;
    playTrack(TRACKS[nextIndex]);
  }, [currentTrack, playTrack]);

  const playPreviousTrack = useCallback(() => {
    if (!currentTrack) {
      if (TRACKS.length > 0) {
        playTrack(TRACKS[TRACKS.length - 1]);
      }
      return;
    }
    const currentIndex = TRACKS.findIndex(track => track.id === currentTrack.id);
    const previousIndex = (currentIndex - 1 + TRACKS.length) % TRACKS.length;
    playTrack(TRACKS[previousIndex]);
  }, [currentTrack, playTrack]);

  return (
    <AudioContext.Provider 
      value={{
        currentTrack,
        isPlaying,
        progress,
        duration,
        volume,
        isMuted,
        tracks: TRACKS,
        isVisible,
        playTrack,
        togglePlayPause,
        seekTo,
        setVolume,
        toggleMute,
        playNextTrack,
        playPreviousTrack,
        showPlayer,
        hidePlayer
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};