import React from 'react';

interface Option {
  value: string;
  label: string;
}

interface RadioGroupProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  name: string;
  inline?: boolean;
}

const CONFIG = {
  styles: {
    wrapper: "space-y-3",
    inlineWrapper: "flex flex-wrap gap-6",
    option: {
      wrapper: "flex items-center gap-3",
      input: "h-4 w-4 text-[#E63946] border-[#1D3557]/20 focus:ring-[#E63946]",
      label: "text-sm whitespace-nowrap"
    }
  }
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  options = [],
  value = '',
  onChange,
  name,
  inline = false
}) => {
  if (!Array.isArray(options)) {
    return null;
  }

  return (
    <div className={inline ? CONFIG.styles.inlineWrapper : CONFIG.styles.wrapper}>
      {options.map((option) => (
        <label key={option.value} className={CONFIG.styles.option.wrapper}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={(e) => onChange(e.target.value)}
            className={CONFIG.styles.option.input}
          />
          <span className={CONFIG.styles.option.label}>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;