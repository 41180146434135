export interface Track {
  id: string;
  title: string;
  description: string;
  url: string;
}

export const TRACKS: Track[] = [
  {
    id: '28f8a109-6477-4b34-b9d0-8322bf26aeb6',
    title: 'Dvorak Symphony No 8 in G Major Op 88, I Allegro',
    description: 'Performed by the Atlantic Music Festival Orchestra; Dean Whiteside, Conductor',
    url: 'https://serve.atlanticmusicfestival.org/assets/28f8a109-6477-4b34-b9d0-8322bf26aeb6'
  },
  {
    id: 'd91ecb15-96b8-4a5f-adc9-7c7dd851d164',
    title: 'Schubert Symphony No.6, D.589',
    description: 'Performed by the Atlantic Music Festival Orchestra; Kisun Sung, Conductor',
    url: 'https://serve.atlanticmusicfestival.org/assets/d91ecb15-96b8-4a5f-adc9-7c7dd851d164'
  }
];