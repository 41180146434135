import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getApplicationRef } from '../../utils/firebase';
import { getDoc, setDoc } from 'firebase/firestore';
import { initialFormData } from './initialState';

export const useFormData = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [isDirty, setIsDirty] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { currentUser } = useAuth();

  // Load initial data
  useEffect(() => {
    let mounted = true;

    async function loadData() {
      if (!currentUser) {
        if (mounted) {
          setIsLoading(false);
        }
        return;
      }

      try {
        setIsLoading(true);
        const docRef = getApplicationRef(currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (!mounted) return;

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData(prevData => ({
            ...initialFormData,
            ...data
          }));

          if (data.lastUpdated) {
            setLastSaved(new Date(data.lastUpdated));
          }
        }
      } catch (error) {
        console.error('[useFormData] Failed to load application:', error);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, [currentUser]);

  // Save handler
  const handleSave = useCallback(async () => {
    if (!currentUser || !isDirty || isSaving) return;

    try {
      setIsSaving(true);
      const docRef = getApplicationRef(currentUser.uid);
      await setDoc(docRef, {
        ...formData,
        lastUpdated: new Date().toISOString()
      }, { merge: true });
      
      setIsDirty(false);
      setLastSaved(new Date());
    } catch (error) {
      console.error('[useFormData] Failed to save application:', error);
    } finally {
      setIsSaving(false);
    }
  }, [currentUser, formData, isDirty, isSaving]);

  // Form data update handler
  const updateFormData = useCallback((
    newData: Partial<typeof initialFormData>,
    isUpload = false
  ) => {
    setFormData(prevData => {
      const updatedData = { ...prevData };

      Object.entries(newData).forEach(([key, value]) => {
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          updatedData[key as keyof typeof initialFormData] = {
            ...updatedData[key as keyof typeof initialFormData],
            ...value
          };
        } else {
          updatedData[key as keyof typeof initialFormData] = value;
        }
      });

      return updatedData;
    });

    setIsDirty(true);

    if (isUpload) {
      handleSave();
    }
  }, [handleSave]);

  return {
    formData,
    isDirty,
    lastSaved,
    currentStep,
    setCurrentStep,
    updateFormData,
    handleSave,
    isSaving,
    isLoading
  };
};