import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Main hero image */}
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('2aba656e-eac9-4b62-858f-ec5c4b687c61', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="Orchestra performance"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/70 to-transparent" />
      </div>

      {/* Animated musical staff lines */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(7)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px bg-white/10"
            style={{
              top: `${25 + i * 8}%`,
              transform: `rotate(${-3 + i * 0.8}deg) scaleX(${0.8 + i * 0.05})`,
              animation: `staffLine ${6 + i}s ease-in-out infinite`,
              animationDelay: `${i * 0.2}s`
            }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-4xl">
          <div className="overflow-hidden mb-8">
            <h1 className="font-serif text-[12rem] leading-none text-white transform hover:scale-105 transition-transform duration-700">
              Instrumental
            </h1>
          </div>
          
          <div className="overflow-hidden mb-16">
            <p className="text-8xl font-serif text-white/90 transform hover:scale-105 transition-transform duration-700 delay-100">
              Program
            </p>
          </div>

          <div className="overflow-hidden max-w-2xl">
            <p className="text-2xl text-white/80 font-light leading-relaxed transform hover:scale-105 transition-transform duration-700 delay-200">
              Join a vibrant community of exceptional musicians for an immersive summer experience where artistry meets excellence.
            </p>
          </div>

          {/* Decorative elements */}
          <div className="absolute -right-16 top-1/2 -translate-y-1/2 w-32 h-64">
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute w-full h-px bg-white/20"
                style={{
                  top: `${20 + i * 20}%`,
                  transform: `rotate(${90 + i * 5}deg)`,
                  animation: `float ${3 + i * 0.5}s ease-in-out infinite`,
                  animationDelay: `${i * 0.2}s`
                }}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Scroll indicator */}
      <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
        <div className="w-[1px] h-24 bg-gradient-to-b from-white/0 via-white/50 to-white/0 animate-pulse" />
      </div>

      <style>
        {`
          @keyframes staffLine {
            0%, 100% { transform: scaleX(0.95) rotate(var(--rotation)); opacity: 0.1; }
            50% { transform: scaleX(1.05) rotate(var(--rotation)); opacity: 0.3; }
          }
          @keyframes float {
            0%, 100% { transform: translateY(0) rotate(var(--rotation)); }
            50% { transform: translateY(-20px) rotate(var(--rotation)); }
          }
        `}
      </style>
    </div>
  );
};

export default HeroSection;