import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import PageHeader from '../components/PageHeader';

const Account = () => {
  return (
    <div>
      <Header />
      <PageHeader
        title="Account"
        description="Sign in to access your AMF account and manage your applications."
        variant="light"
      />
    </div>
  );
};

export default Account;