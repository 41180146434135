import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { NAVIGATION_ITEMS } from '../config/navigation';
import { SITE_CONFIG, getLogoUrl } from '../config/constants';
import { APPLICATION_CONFIG } from '../config/application';
import DesktopNav from './navigation/DesktopNav';
import MobileMenu from './MobileMenu';

interface HeaderProps {
  variant?: 'light' | 'dark';
}

const Header: React.FC<HeaderProps> = ({ variant = 'light' }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const logoId = variant === 'dark' ? SITE_CONFIG.logos.dark : SITE_CONFIG.logos.default;
  const textColorClass = isScrolled 
    ? 'text-[#1D3557]' 
    : variant === 'light' 
      ? 'text-[#1D3557]' 
      : 'text-white';
  const bgColorClass = isScrolled ? 'bg-white' : 'bg-transparent';

  // Button styles based on scroll state and variant
  const buttonBorderClass = isScrolled 
    ? 'border-[#1D3557]' 
    : variant === 'light' 
      ? 'border-[#1D3557]' 
      : 'border-white';
  const buttonTextClass = isScrolled 
    ? 'text-[#1D3557] group-hover:text-white' 
    : variant === 'light' 
      ? 'text-[#1D3557] group-hover:text-white' 
      : 'text-white group-hover:text-[#1D3557]';
  const buttonBgClass = isScrolled 
    ? 'bg-[#1D3557]' 
    : variant === 'light' 
      ? 'bg-[#1D3557]' 
      : 'bg-white';

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-700 ${isScrolled ? 'h-20' : 'h-28'}`}>
      <div className={`absolute inset-0 ${bgColorClass} transition-opacity duration-700 ${isScrolled ? 'opacity-95' : 'opacity-0'}`} />
      
      <div className="mx-8 md:mx-12 h-full">
        <div className="relative h-full flex items-center justify-between">
          {/* Logo */}
          <a href="/" className="group">
            <div className="flex items-center gap-4">
              <img 
                src={getLogoUrl(logoId)}
                alt="Atlantic Music Festival"
                className={`w-auto transition-all duration-300 group-hover:opacity-80 group-hover:scale-[0.98] ${isScrolled ? 'h-12' : 'h-16'}`}
              />
            </div>
          </a>

          {/* Desktop Navigation */}
          <div className={textColorClass}>
            <DesktopNav 
              items={NAVIGATION_ITEMS} 
              variant={isScrolled ? 'light' : variant} 
            />
          </div>

          {/* Right Section */}
          <div className="hidden md:flex items-center gap-8">
            <div className={`text-right ${textColorClass}`}>
              <span className="block text-[10px] tracking-[0.2em] opacity-40 mb-1">
                {SITE_CONFIG.applicationDeadline.label}
              </span>
              <span className="block text-sm font-light">
                {SITE_CONFIG.applicationDeadline.date}
              </span>
            </div>
            <a 
              href={APPLICATION_CONFIG.status.isOpen ? "/the-institute/online-application" : "/the-institute"} 
              className="relative overflow-hidden group"
            >
              <span className={`block px-6 py-3 border-2 ${buttonBorderClass}`}>
                <span className={`relative z-10 flex items-center gap-3 text-sm tracking-wide ${buttonTextClass} transition-colors duration-500`}>
                  {APPLICATION_CONFIG.status.isOpen ? 'Apply Now' : 'The Institute'}
                </span>
              </span>
              <div className={`absolute inset-0 ${buttonBgClass} -translate-y-full group-hover:translate-y-0 transition-transform duration-500`} />
            </a>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className={`md:hidden p-2 ${textColorClass}`}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={isMobileMenuOpen}
        navigationItems={NAVIGATION_ITEMS}
        applicationDeadline={SITE_CONFIG.applicationDeadline.date}
        applicationDeadlineLabel={SITE_CONFIG.applicationDeadline.label}
        applyButtonText={APPLICATION_CONFIG.status.isOpen ? 'Apply Now' : 'Application Process'}
        applyButtonUrl="/the-institute/online-application"
        variant={variant}
      />
    </header>
  );
};

export default Header;