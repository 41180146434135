import React from 'react';
import { getAssetUrl, IMAGE_SIZES } from '../../../utils/directus';

interface GalleryImage {
  id: string;
  alt: string;
  size?: 'full';
}

interface GalleryProps {
  images: GalleryImage[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {images.map((image, index) => (
        <div 
          key={image.id}
          className={image.size === 'full' ? 'md:col-span-2' : ''}
        >
          <img
            src={getAssetUrl(image.id, IMAGE_SIZES.gallery)}
            alt={image.alt}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      ))}
    </div>
  );
};

export default Gallery;