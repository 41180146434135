import React from 'react';
import PersonalInfoReview from './sections/PersonalInfoReview';
import ProgramInfoReview from './sections/ProgramInfoReview';
import EducationReview from './sections/EducationReview';
import PortfolioReview from './sections/PortfolioReview';
import SurveyReview from './sections/SurveyReview';
import { ApplicationPayment } from '../payment/ApplicationPayment';

interface ReviewProps {
  formData: any;
}

const CONFIG = {
  title: "Review Your Application",
  description: "Please review your application details before submitting.",
  styles: {
    section: "space-y-12",
    card: "bg-white p-6 rounded-lg shadow-sm",
    title: "text-lg font-medium mb-6",
    description: "text-sm text-[#1D3557]/60 mb-8"
  }
};

const Review: React.FC<ReviewProps> = ({ formData }) => {
  return (
    <div className={CONFIG.styles.section}>
      <div className={CONFIG.styles.card}>
        <h3 className={CONFIG.styles.title}>{CONFIG.title}</h3>
        <p className={CONFIG.styles.description}>{CONFIG.description}</p>

        <div className="space-y-12">
          <PersonalInfoReview data={formData.personalInfo} />
          <ProgramInfoReview data={formData.programInfo} />
          <EducationReview data={formData.education} />
          <PortfolioReview data={formData.portfolio} />
          <SurveyReview data={formData.survey} />
        </div>
      </div>

      <ApplicationPayment />
    </div>
  );
};

export default Review;