import React from 'react';
import { getAssetUrl, IMAGE_SIZES } from '../../../utils/directus';

interface Feature {
  title: string;
  description: string;
}

interface GalleryImage {
  id: string;
  alt: string;
}

interface ResidencyExperienceProps {
  title: string;
  description: string;
  features: Feature[];
  gallery: GalleryImage[];
}

const ResidencyExperience: React.FC<ResidencyExperienceProps> = ({
  title,
  description,
  features,
  gallery
}) => {
  return (
    <section className="py-16 md:py-24">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-3xl mb-16">
          <h2 className="font-serif text-4xl mb-6">{title}</h2>
          <p className="text-lg text-[#1D3557]/80 leading-relaxed">
            {description}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="space-y-4">
              <h3 className="font-serif text-xl">{feature.title}</h3>
              <p className="text-[#1D3557]/70 leading-relaxed">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {gallery.map((image) => (
            <div key={image.id} className="aspect-square overflow-hidden rounded-lg">
              <img
                src={getAssetUrl(image.id, IMAGE_SIZES.gallery)}
                alt={image.alt}
                className="w-full h-full object-cover hover:scale-105 transition-transform duration-500"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ResidencyExperience;