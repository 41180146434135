import React from 'react';
import { ArrowRight, GraduationCap, Users, Home, Award } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Financial Aid & Awards",
    description: "Making musical excellence accessible through comprehensive financial support programs.",
    imageId: "1f70f04b-01a8-401c-be2c-eaa6f74918fb",
    variant: 'light' as const
  },
  intro: {
    title: "Supporting Your Musical Journey",
    description: "The AMF Institute is committed to making our programs accessible to talented musicians through various forms of financial assistance. Our comprehensive support system includes fellowships, teaching opportunities, and scholarships."
  },
  programs: [
    {
      icon: Award,
      title: "Fellowships",
      description: "Full financial coverage for exceptional emerging artists",
      details: [
        "Complete coverage of tuition, housing, and meals",
        "Up to 40 fellowships awarded annually",
        "Six-week residency program",
        "Must be 18 or older",
        "Full season attendance required"
      ],
      note: "Fellows participate in performances and collaborate with artist-faculty throughout the season."
    },
    {
      icon: GraduationCap,
      title: "Teaching Assistantships",
      description: "Gain valuable teaching experience while pursuing your artistry",
      details: [
        "Work with accomplished artist-faculty",
        "Lead rehearsals and provide instruction",
        "Performance opportunities",
        "Must be 21 or older",
        "Teaching experience required"
      ],
      note: "TAs must submit a personal statement and teaching resume with their application."
    },
    {
      icon: Home,
      title: "Resident Assistants",
      description: "Leadership roles supporting our festival community",
      details: [
        "Promote community safety and wellness",
        "Provide student support",
        "Assist with logistics",
        "Must be 21 or older",
        "Early arrival for training"
      ],
      note: "RAs play a crucial role in creating a positive festival environment."
    },
    {
      icon: Users,
      title: "Scholarships",
      description: "Merit and need-based financial support",
      details: [
        "Partial tuition support",
        "Based on merit and financial need",
        "Program needs considered",
        "Financial documentation required",
        "Appeal process available"
      ],
      note: "Early application recommended for maximum consideration."
    }
  ],
  process: {
    title: "Application Process",
    steps: [
      "Submit your program application",
      "Receive financial aid application access",
      "Complete aid application within two weeks",
      "Provide supporting documentation",
      "Receive decision notification"
    ]
  },
  cta: {
    title: "Begin Your Application",
    description: "Take the first step toward joining our vibrant musical community.",
    button: {
      text: "Start Application",
      url: "/the-institute/online-application"
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    intro: {
      wrapper: "text-center max-w-3xl mx-auto mb-24",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 leading-relaxed"
    },
    programs: {
      grid: "grid grid-cols-1 md:grid-cols-2 gap-8",
      card: {
        wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg group hover:bg-white transition-all duration-500",
        icon: "w-12 h-12 text-[#E63946] mb-6",
        title: "font-serif text-2xl mb-4",
        description: "text-[#1D3557]/70 mb-6",
        list: "space-y-3 mb-6",
        item: "flex items-center gap-3 text-[#1D3557]/70",
        bullet: "text-[#E63946]",
        note: "text-sm text-[#457B9D] italic"
      }
    },
    process: {
      wrapper: "bg-[#1D3557] text-white rounded-lg p-12",
      title: "font-serif text-3xl mb-8 text-center",
      steps: "max-w-2xl mx-auto space-y-6",
      step: "flex items-center gap-4",
      number: "w-8 h-8 rounded-full border-2 flex items-center justify-center text-sm",
      text: "text-white/80"
    },
    cta: {
      wrapper: "text-center max-w-2xl mx-auto",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const FinancialAid = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.intro.wrapper}>
            <h2 className={CONFIG.styles.intro.title}>{CONFIG.intro.title}</h2>
            <p className={CONFIG.styles.intro.description}>{CONFIG.intro.description}</p>
          </div>

          <div className={CONFIG.styles.programs.grid}>
            {CONFIG.programs.map((program, index) => {
              const Icon = program.icon;
              return (
                <div key={index} className={CONFIG.styles.programs.card.wrapper}>
                  <Icon className={CONFIG.styles.programs.card.icon} />
                  <h3 className={CONFIG.styles.programs.card.title}>{program.title}</h3>
                  <p className={CONFIG.styles.programs.card.description}>{program.description}</p>
                  <div className={CONFIG.styles.programs.card.list}>
                    {program.details.map((detail, idx) => (
                      <div key={idx} className={CONFIG.styles.programs.card.item}>
                        <span className={CONFIG.styles.programs.card.bullet}>•</span>
                        {detail}
                      </div>
                    ))}
                  </div>
                  <p className={CONFIG.styles.programs.card.note}>{program.note}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.process.wrapper}>
            <h2 className={CONFIG.styles.process.title}>{CONFIG.process.title}</h2>
            <div className={CONFIG.styles.process.steps}>
              {CONFIG.process.steps.map((step, index) => (
                <div key={index} className={CONFIG.styles.process.step}>
                  <div className={CONFIG.styles.process.number}>{index + 1}</div>
                  <span className={CONFIG.styles.process.text}>{step}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.cta.wrapper}>
            <h2 className={CONFIG.styles.cta.title}>{CONFIG.cta.title}</h2>
            <p className={CONFIG.styles.cta.description}>{CONFIG.cta.description}</p>
            <a href={CONFIG.cta.button.url} className={CONFIG.styles.cta.button.wrapper}>
              <span className={CONFIG.styles.cta.button.text}>
                {CONFIG.cta.button.text}
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className={CONFIG.styles.cta.button.background} />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default FinancialAid;