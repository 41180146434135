import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const GALLERY_IMAGES = [
  {
    id: 'b8d5fcda-9038-4368-bf5f-e2077217c34e',
    alt: 'The singers take the stage at the Strider Theater'
  },
  {
    id: '034e9bd4-baf8-4092-b1b1-711801853371',
    alt: 'Coach Arlene Shrut during a recital'
  },
  {
    id: '4c8b475b-7eb3-4e4f-9704-e12fa7c86453',
    alt: 'Singers prepare for a final performance',
    size: 'full'
  }
];

const GallerySection = () => {
  return (
    <section className="py-24 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {GALLERY_IMAGES.map((image, index) => (
            <div 
              key={image.id}
              className={image.size === 'full' ? 'md:col-span-2' : ''}
            >
              <div className="relative aspect-[16/9] overflow-hidden rounded-lg group">
                <img
                  src={getAssetUrl(image.id, {
                    width: image.size === 'full' ? 1920 : 800,
                    height: image.size === 'full' ? 1080 : 450,
                    quality: 85,
                    fit: 'cover'
                  })}
                  alt={image.alt}
                  className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GallerySection;