import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getAssetUrl } from '../utils/directus';
import { FESTIVAL_CONFIG } from '../config/festival';

const CONFIG = {
  title: "Programs",
  description: "The Atlantic Music Festival offers comprehensive programs designed to nurture exceptional talent across all stages of musical development.",
  programs: [
    {
      title: "Pre-College Program",
      age: "Ages 14-18",
      dates: FESTIVAL_CONFIG.season.sessions[0].dates,
      description: "An immersive summer program designed for exceptional young musicians, offering intensive training in performance and musicianship.",
      imageId: "32099391-44d1-42ea-881d-937b5eed2975",
      url: "/the-institute"
    },
    {
      title: "Institute Programs",
      age: "Ages 18+",
      categories: [
        { name: "Composition Program", url: "/the-institute/programs/composition-program" },
        { name: "Conducting Program", url: "/the-institute/programs/conducting-program" },
        { name: "Instrumental Program", url: "/the-institute/programs/instrumental-program" },
        { name: "Piano Institute", url: "/the-institute/programs/piano-institute-and-seminar" },
        { name: "Vocal Arts Institute", url: "/the-institute/programs/vocal-institute" }
      ],
      dates: FESTIVAL_CONFIG.season.fullSession.dates,
      description: "Advanced training programs for emerging professional musicians, featuring masterclasses with renowned faculty and performance opportunities.",
      imageId: "f8764cd0-e456-42e0-b8c4-46ee8df79f32",
      url: "/the-institute"
    },
    {
      title: "Fellowship",
      age: "Ages 18+",
      dates: "Full Season",
      description: "A fully-funded opportunity for exceptional artists to develop their craft while working alongside distinguished faculty and guest artists.",
      imageId: "a43e3371-a8fc-43a8-9af5-bb6e4d58e44c",
      url: "/the-institute"
    }
  ],
  styles: {
    section: "py-32 bg-[#1D3557]/[0.02] -mx-8 md:-mx-12",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    grid: "grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16 mb-16 md:mb-32",
    title: {
      wrapper: "col-span-1 md:col-span-4",
      text: "font-serif text-4xl md:text-6xl relative inline-block",
      accent: "absolute -left-4 md:-left-8 -bottom-4 md:-bottom-8 w-12 md:w-16 h-12 md:h-16 bg-[#457B9D] opacity-20 -z-10"
    },
    description: "col-span-1 md:col-span-8 font-light text-lg leading-relaxed tracking-wide text-[#1D3557]/80 max-w-2xl",
    programs: {
      grid: "grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-24",
      item: {
        wrapper: "group relative",
        image: {
          wrapper: "aspect-[4/3] overflow-hidden mb-8",
          img: "w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700",
          overlay: "absolute inset-0 bg-[#1D3557] mix-blend-color opacity-10 group-hover:opacity-0 transition-opacity duration-700"
        },
        content: {
          wrapper: "px-8 space-y-4",
          age: "text-sm tracking-wide text-[#E63946]",
          title: "font-serif text-2xl",
          categories: "flex flex-wrap gap-x-4 gap-y-2",
          categoryLink: "text-sm tracking-wide text-[#457B9D] hover:text-[#E63946] transition-colors",
          dates: "text-sm tracking-wide text-[#1D3557]/60",
          description: "font-light leading-relaxed tracking-wide text-[#1D3557]/80",
          link: "flex items-center gap-2 text-[#1D3557] group-hover:gap-4 transition-all duration-300"
        }
      }
    }
  }
};

const Programs: React.FC = () => {
  return (
    <section className={CONFIG.styles.section}>
      <div className={CONFIG.styles.container}>
        <div className={CONFIG.styles.grid}>
          <div className={CONFIG.styles.title.wrapper}>
            <h3 className={CONFIG.styles.title.text}>
              {CONFIG.title}
              <div className={CONFIG.styles.title.accent} />
            </h3>
          </div>
          <div className="col-span-1 md:col-span-8">
            <p className={CONFIG.styles.description}>
              {CONFIG.description}
            </p>
          </div>
        </div>

        <div className={CONFIG.styles.programs.grid}>
          {CONFIG.programs.map((program, index) => (
            <div key={index} className={CONFIG.styles.programs.item.wrapper}>
              <div className={CONFIG.styles.programs.item.image.wrapper}>
                <img
                  src={getAssetUrl(program.imageId, {
                    width: 800,
                    height: 600,
                    quality: 85,
                    fit: 'cover'
                  })}
                  alt={program.title}
                  className={CONFIG.styles.programs.item.image.img}
                />
                <div className={CONFIG.styles.programs.item.image.overlay} />
              </div>
              
              <div className={CONFIG.styles.programs.item.content.wrapper}>
                <p className={CONFIG.styles.programs.item.content.age}>{program.age}</p>
                <h4 className={CONFIG.styles.programs.item.content.title}>{program.title}</h4>
                {program.categories && (
                  <div className={CONFIG.styles.programs.item.content.categories}>
                    {program.categories.map((category, idx) => (
                      <a
                        key={idx}
                        href={category.url}
                        className={CONFIG.styles.programs.item.content.categoryLink}
                      >
                        {category.name}
                      </a>
                    ))}
                  </div>
                )}
                <p className={CONFIG.styles.programs.item.content.dates}>{program.dates}</p>
                <p className={CONFIG.styles.programs.item.content.description}>
                  {program.description}
                </p>
                <div className="pt-4">
                  <a href={program.url} className="inline-block group/link">
                    <span className={CONFIG.styles.programs.item.content.link}>
                      <span className="text-sm tracking-wide artistic-border">Learn More</span>
                      <ArrowRight size={16} className="text-[#E63946] group-hover/link:translate-x-2 transition-transform duration-500" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Programs;