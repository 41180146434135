import React from 'react';

interface MonthFilterProps {
  months: string[];
  selectedMonth: string | null;
  onMonthSelect: (month: string | null) => void;
}

const MonthFilter: React.FC<MonthFilterProps> = ({
  months,
  selectedMonth,
  onMonthSelect
}) => {
  return (
    <div className="sticky top-0 z-10 bg-white border-b border-[#1D3557]/10">
      <div className="max-w-screen-2xl mx-auto px-8">
        <div className="flex items-center gap-6 py-6 overflow-x-auto scrollbar-hide">
          {months.map((month) => (
            <button
              key={month}
              onClick={() => onMonthSelect(month === selectedMonth ? null : month)}
              className={`relative px-4 py-2 text-sm whitespace-nowrap transition-colors ${
                month === selectedMonth
                  ? 'text-[#E63946]'
                  : 'text-[#1D3557] hover:text-[#E63946]'
              }`}
            >
              {month}
              {month === selectedMonth && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-[#E63946]" />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MonthFilter;