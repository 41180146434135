import React from 'react';
import FormField from '../FormField';
import RadioGroup from './RadioGroup';
import InstrumentSelection from './InstrumentSelection';
import SessionSelection from './SessionSelection';

interface ProgramSelectionProps {
  formData: {
    type?: string;
    specialization?: string;
    instrument?: string;
    sessions?: string[];
  };
  onChange: (data: Partial<ProgramSelectionProps['formData']>) => void;
}

const CONFIG = {
  specializations: {
    'pre-college': [
      { value: 'composition', label: 'Composition' },
      { value: 'voice', label: 'Voice' },
      { value: 'instrument', label: 'Instrument' }
    ],
    'collegiate': [
      { value: 'composition', label: 'Composition' },
      { value: 'conducting', label: 'Conducting' },
      { value: 'voice', label: 'Voice' },
      { value: 'instrument', label: 'Instrument' }
    ]
  },
  voiceTypes: {
    'pre-college': [
      { value: 'soprano', label: 'Soprano' },
      { value: 'mezzo-soprano', label: 'Mezzo-Soprano' },
      { value: 'alto', label: 'Alto' },
      { value: 'tenor', label: 'Tenor' },
      { value: 'baritone', label: 'Baritone' },
      { value: 'bass', label: 'Bass' }
    ],
    'collegiate': [
      { value: 'soprano', label: 'Soprano' },
      { value: 'mezzo-soprano', label: 'Mezzo-Soprano' },
      { value: 'alto', label: 'Alto' },
      { value: 'contralto', label: 'Contralto' },
      { value: 'countertenor', label: 'Countertenor' },
      { value: 'tenor', label: 'Tenor' },
      { value: 'baritone', label: 'Baritone' },
      { value: 'bass', label: 'Bass' }
    ]
  },
  styles: {
    section: "space-y-8",
    card: "bg-white p-6 rounded-lg shadow-sm",
    title: "text-lg font-medium mb-6",
    content: "space-y-8",
    info: "text-sm text-[#1D3557]/60 mt-2"
  }
};

const ProgramSelection: React.FC<ProgramSelectionProps> = ({ 
  formData = {
    type: '',
    specialization: '',
    instrument: '',
    sessions: []
  }, 
  onChange 
}) => {
  const handleProgramTypeChange = (type: string) => {
    onChange({
      type,
      specialization: '',
      instrument: '',
      sessions: []
    });
  };

  const handleSpecializationChange = (specialization: string) => {
    onChange({
      ...formData,
      specialization,
      instrument: ''
    });
  };

  const getSpecializationOptions = () => {
    if (!formData.type || !CONFIG.specializations[formData.type as keyof typeof CONFIG.specializations]) {
      return [];
    }
    return CONFIG.specializations[formData.type as keyof typeof CONFIG.specializations];
  };

  const getVoiceTypeOptions = () => {
    if (!formData.type || !CONFIG.voiceTypes[formData.type as keyof typeof CONFIG.voiceTypes]) {
      return [];
    }
    return CONFIG.voiceTypes[formData.type as keyof typeof CONFIG.voiceTypes];
  };

  return (
    <div className={CONFIG.styles.section}>
      <div className={CONFIG.styles.card}>
        <h3 className={CONFIG.styles.title}>Program Selection</h3>
        <div className={CONFIG.styles.content}>
          <FormField
            label="Program Type"
            type="select"
            value={formData.type}
            onChange={(e) => handleProgramTypeChange(e.target.value)}
            required
          >
            <option value="">Select program type</option>
            <option value="pre-college">Pre-College Program (Ages 14-18)</option>
            <option value="collegiate">Collegiate & Professional Program (Ages 18+)</option>
          </FormField>

          {formData.type && (
            <>
              <div>
                <h4 className="text-sm font-medium mb-4">Specialization</h4>
                <RadioGroup
                  options={getSpecializationOptions()}
                  value={formData.specialization || ''}
                  onChange={handleSpecializationChange}
                  name="specialization"
                />
              </div>

              {formData.specialization === 'voice' && (
                <div>
                  <h4 className="text-sm font-medium mb-4">Voice Type</h4>
                  <RadioGroup
                    options={getVoiceTypeOptions()}
                    value={formData.instrument || ''}
                    onChange={(value) => onChange({ ...formData, instrument: value })}
                    name="voiceType"
                  />
                </div>
              )}

              {formData.specialization === 'instrument' && (
                <InstrumentSelection
                  value={formData.instrument || ''}
                  onChange={(value) => onChange({ ...formData, instrument: value })}
                />
              )}

              <SessionSelection
                programType={formData.type}
                sessions={formData.sessions || []}
                onChange={(sessions) => onChange({ ...formData, sessions })}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramSelection;