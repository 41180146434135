import { Concert } from './data';
import concertData from '../../data/concerts/concerts.json';
import { format } from 'date-fns';

interface Performer {
  name: string;
  instrument?: string;
  uid?: string;
}

export const generateSlug = (concert: Concert): string => {
  const date = format(new Date(concert.time), 'yyyy-MM-dd');
  const titleSlug = concert.title
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-');
  
  return `${date}-${titleSlug}`;
};

export const findConcertBySlug = (slug: string): Concert | undefined => {
  return concertData.find(concert => generateSlug(concert) === slug);
};

export const arePerformersEqual = (
  performers1?: Performer[],
  performers2?: Performer[]
): boolean => {
  // If either is undefined or they have different lengths, they're not equal
  if (!performers1 || !performers2 || performers1.length !== performers2.length) {
    return false;
  }

  // Compare each performer's name and instrument
  return performers1.every((performer1, index) => {
    const performer2 = performers2[index];
    return (
      performer1.name === performer2.name &&
      performer1.instrument === performer2.instrument
    );
  });
};