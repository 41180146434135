import React from 'react';
import { ArrowRight } from 'lucide-react';

const REQUIREMENTS = [
  {
    title: "Financial Support",
    items: [
      "Complete coverage of tuition, housing, and meals",
      "$800 stipend upon successful completion",
      "Six-week residency support",
      "Performance and recording resources"
    ],
    color: "#E63946"
  },
  {
    title: "Eligibility",
    items: [
      "Open to Instrumental Program and Piano Institute applicants",
      "Must be 18 or older",
      "Strong commitment to contemporary music",
      "Full attendance required for entire residency"
    ],
    color: "#457B9D"
  },
  {
    title: "Expectations",
    items: [
      "Premiere works by resident composers",
      "Contribute up to 2 hours daily to program needs",
      "Available for all scheduled performances",
      "Maintain professional collaborative spirit"
    ],
    color: "#F6BD60"
  }
];

const EligibilitySection = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="font-serif text-7xl mb-16">Eligibility & Requirements</h2>

          <div className="space-y-16">
            {REQUIREMENTS.map((section, index) => (
              <div 
                key={index} 
                className="relative pl-12"
              >
                {/* Vertical line */}
                <div 
                  className="absolute left-0 top-0 bottom-0 w-1"
                  style={{ 
                    background: `linear-gradient(to bottom, ${section.color}, transparent)` 
                  }}
                />

                <h3 className="font-serif text-3xl mb-8">{section.title}</h3>
                <div className="space-y-6">
                  {section.items.map((item, idx) => (
                    <div 
                      key={idx} 
                      className="flex items-start gap-4 group"
                    >
                      <span 
                        className="w-8 h-px mt-3 transition-all duration-500"
                        style={{ 
                          backgroundColor: section.color,
                          opacity: 0.3,
                        }}
                      />
                      <span className="text-lg text-[#1D3557]/70 leading-relaxed">
                        {item}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Application CTA */}
          <div className="mt-24 text-center">
            <p className="text-xl text-[#1D3557]/70 mb-8">
              Ready to join our contemporary music community?
            </p>
            <a 
              href="/the-institute/online-application" 
              className="inline-flex items-center gap-2 px-12 py-6 border-2 border-[#1D3557] relative overflow-hidden group"
            >
              <span className="relative z-10 text-lg tracking-wide group-hover:text-white transition-colors duration-500">
                Begin Application
                <ArrowRight 
                  size={20} 
                  className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" 
                />
              </span>
              <div className="absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
            </a>
          </div>
        </div>
      </div>

      {/* Abstract notation background */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(12)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px"
            style={{
              background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.02 - i * 0.001}), transparent)`,
              top: `${10 + i * 8}%`,
              transform: `rotate(${-6 + i}deg)`,
              animation: `floatLine ${4 + i * 0.3}s ease-in-out infinite`,
              animationDelay: `${i * 0.1}s`
            }}
          />
        ))}
      </div>

      <style>{`
        @keyframes floatLine {
          0%, 100% { transform: rotate(var(--rotation)) translateY(0); }
          50% { transform: rotate(var(--rotation)) translateY(-5px); }
        }
      `}</style>
    </section>
  );
};

export default EligibilitySection;