export const PRE_COLLEGE_DATA = {
  hero: {
    imageId: 'deb14307-c91f-433c-a5e4-2149ea689d55',
    title: 'Pre-College<br />Program',
    description: 'A transformative summer journey for exceptional young musicians, where talent flourishes and lifelong connections are forged.'
  },

  intro: {
    content: `Discover a world where musical talent flourishes and life-long friendships are formed. The AMF Institute's Pre-College Program invites musicians under 18 on a summer journey filled with musical exploration and personal growth.

Each year, the festival committee invites young musicians specializing in instrumentalists, singers, and composers based on their talent and potential to make a difference in this world.

The program uniquely combines rigorous musical training with access to collegiate-level masterclasses, encouraging a deeper understanding of music. Students are immersed in an environment that balances intensive learning with unforgettable experiences, fostering artistic development and lasting connections.`
  },

  gallery: {
    images: [
      {
        id: 'c8b8352f-aa76-48fe-97cb-885f5a7abe40',
        alt: 'Pre-College string players during a rehearsal'
      },
      {
        id: 'a52b366d-ff04-4ec9-b3bd-df81b9b459d1',
        alt: 'Chamber music rehearsal session'
      },
      {
        id: '4c8b475b-7eb3-4e4f-9704-e12fa7c86453',
        alt: 'Singers prepare for a final performance',
        size: 'full'
      }
    ]
  },

  highlights: {
    title: 'Program Highlights',
    items: [
      {
        title: 'Personalized Instruction',
        description: 'Engage in weekly private lessons with our celebrated artist-faculty members, offering personalized guidance to refine your craft.'
      },
      {
        title: 'Dynamic Learning Environment',
        description: 'Participate in our diverse weekly seminars and masterclasses that challenge and inspire young musicians.'
      },
      {
        title: 'Performance Opportunities',
        description: 'Showcase your talents through various performance opportunities, including solo recitals, chamber music, and orchestra.'
      },
      {
        title: 'Access to Advanced Learning',
        description: 'Benefit from collegiate-level seminars and masterclasses offered by the AMF Institute.'
      },
      {
        title: 'Flexible and Diverse Curriculum',
        description: 'Our modular program structure allows you to choose from various course offerings.'
      },
      {
        title: 'Immersive Festival Experience',
        description: 'Be a part of the larger music community by attending open rehearsals and concerts throughout the season.'
      }
    ]
  },

  schedule: {
    title: 'Sample Daily Schedule',
    description: 'Our sample daily schedule offers a glimpse into the exciting and varied activities our students engage in.',
    sections: [
      {
        title: 'MORNING (7AM – 12PM)',
        activities: [
          {
            time: '7am – 9am',
            title: 'Breakfast',
            description: 'Start your day with a nutritious breakfast, featuring a variety of options for all dietary preferences.'
          },
          {
            time: '9am – 10am',
            title: 'Studio Class',
            description: 'A daily gathering for participants in our individual programs.'
          },
          {
            time: '10am – 11am',
            title: 'Ensemble or Group Practice',
            description: 'Participate in focused sessions like scale class, ensemble rehearsals, or group practice.'
          },
          {
            time: '11am – 12pm',
            title: 'Private Lessons',
            description: 'One-on-one lessons with your instructor or individual practice time.'
          }
        ]
      },
      {
        title: 'AFTERNOON (12PM – 5PM)',
        activities: [
          {
            time: '12pm – 1pm',
            title: 'Lunch',
            description: 'Enjoy a wholesome lunch with a variety of dining options.'
          },
          {
            time: '1pm – 3pm',
            title: 'Masterclasses & Ensembles',
            description: 'Learn from world-class faculty in masterclasses or participate in ensemble work.'
          },
          {
            time: '3pm – 4pm',
            title: 'Community Hour',
            description: 'A relaxed time for snacks, social activities, and building friendships.'
          },
          {
            time: '4pm – 5pm',
            title: 'Group Electives',
            description: 'Explore unique electives including topics such as career development and audition preparation.'
          }
        ]
      },
      {
        title: 'EVENING (5PM – 10PM)',
        activities: [
          {
            time: '5pm – 6:30pm',
            title: 'Dinner',
            description: 'Wind down with a delightful dinner, offering a range of tasty choices.'
          },
          {
            time: '7pm',
            title: 'Concerts',
            description: 'Experience performances by peers and faculty, showcasing the day\'s learnings and inspirations.'
          },
          {
            time: '8pm',
            title: 'Evening Activities',
            description: 'Enjoy a variety of evening activities, including community time, movie nights, and more.'
          },
          {
            time: '10pm',
            title: 'Rest',
            description: 'Rest up for another exciting day of music-making.'
          }
        ]
      }
    ]
  },

  paths: {
    title: 'Specialized Paths',
    description: `In the Pre-College Program, each student's schedule aligns with their unique talents and passions. Following their audition, students enroll in one of several dedicated pathways: Instrumental Program, Piano Institute, Composition Program, or Vocal Arts Institute.`,
    programs: [
      {
        title: 'Instrumental Program',
        url: '/the-institute/programs/instrumental-program',
        description: 'Designed for orchestral instrumentalists, this program combines individual skill development with ensemble performance.'
      },
      {
        title: 'Piano Institute and Seminar',
        url: '/the-institute/programs/piano-institute-and-seminar',
        description: 'Offers intensive training for pianists, balancing solo and collaborative playing.'
      },
      {
        title: 'Composition Program',
        url: '/the-institute/programs/composition-program',
        description: 'Nurtures young composers focusing on creativity, technique, and innovation.'
      },
      {
        title: 'Vocal Arts Institute',
        url: '/the-institute/programs/vocal-institute',
        description: 'Provides comprehensive training in various vocal styles and performance practices.'
      }
    ]
  },

  residency: {
    title: 'A Safe and Inspiring Residency Experience',
    description: 'At the Atlantic Music Festival, the well-being and security of our young musicians is our top priority.',
    features: [
      {
        title: 'Secure and Supervised Accommodations',
        description: 'Separate living quarters for young participants, with campus rules and a mandatory curfew strictly enforced for their safety.'
      },
      {
        title: 'Nourishing Dining Options',
        description: 'Enjoy diverse, healthy meals at our dining hall, catering to various dietary needs including vegetarian and gluten-free.'
      },
      {
        title: 'Recreational Facilities',
        description: 'Access to the campus\'s extensive recreational amenities, including the Museum of Art, swimming pool at the Athletic Center, and scenic trails.'
      }
    ],
    gallery: [
      {
        id: '9e6707eb-665e-45de-af70-b7663a45940c',
        alt: 'AMF Office staff during a pre-college check in'
      },
      {
        id: '38c3864b-812c-4907-8292-25fb9bc7fdbf',
        alt: 'A view of the Harole Alfond Athletics and Recreation Center at Colby College'
      },
      {
        id: '66a1df3b-1016-464a-b59a-c15f0a159e57',
        alt: 'Soccer match at AMF'
      },
      {
        id: '31f9e64c-04b5-4751-bf28-ca3f603d0083',
        alt: 'During a trip to Acadia National Park, AMF participants pose for a group photo'
      }
    ]
  },

  application: {
    title: 'Application Process',
    description: 'The Atlantic Music Festival is now accepting applications for its Pre-College program.',
    requirements: {
      instrumentalists: [
        'Two contrasting pieces or movements that best demonstrate your current level',
        'Total length should not exceed 15 minutes'
      ],
      composers: [
        'Two recent compositions (scores and recordings if available)',
        'Statement of artistic goals'
      ],
      vocalists: [
        'Two contrasting pieces in different languages',
        'One piece must be in English'
      ]
    }
  }
};