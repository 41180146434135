import { ref, uploadBytesResumable, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { storage, FIREBASE_PATHS } from '../config/firebase';

export interface FileUploadMetadata {
  originalName: string;
  uploadedBy: string;
  uploadedAt: string;
}

export interface UploadProgressCallback {
  onProgress?: (progress: number) => void;
  onError?: (error: Error) => void;
  onSuccess?: (url: string, metadata: FileUploadMetadata) => void;
}

export class StorageError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
    this.name = 'StorageError';
  }
}

const getStoragePath = (userId: string, type: string, filename: string) => {
  return `${FIREBASE_PATHS.storage.users}/${userId}/${type}/${filename}`;
};

export const uploadFile = async (
  file: File,
  userId: string,
  type: string,
  callbacks?: UploadProgressCallback
): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const fileExtension = file.name.split('.').pop() || '';
      const safeFileName = `${uuidv4()}.${fileExtension}`;
      const storagePath = getStoragePath(userId, type, safeFileName);
      
      const fileRef = ref(storage, storagePath);

      const metadata = {
        contentType: file.type,
        customMetadata: {
          originalName: file.name,
          uploadedBy: userId,
          uploadedAt: new Date().toISOString()
        }
      };

      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          callbacks?.onProgress?.(progress);
        },
        (error) => {
          callbacks?.onError?.(error);
          reject(error);
        },
        async () => {
          try {
            const downloadUrl = await getDownloadURL(fileRef);
            const uploadedMetadata = await getMetadata(fileRef);
            const fileMetadata: FileUploadMetadata = {
              originalName: file.name,
              uploadedBy: userId,
              uploadedAt: new Date().toISOString()
            };

            callbacks?.onSuccess?.(downloadUrl, fileMetadata);
            resolve(downloadUrl);
          } catch (error) {
            reject(error);
          }
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteFile = async (url: string): Promise<void> => {
  try {
    const pathRegex = /o\/(.+?)\?/;
    const match = url.match(pathRegex);
    if (!match) {
      throw new StorageError('Invalid storage URL');
    }
    
    const path = decodeURIComponent(match[1]);
    const fileRef = ref(storage, path);
    await deleteObject(fileRef);
  } catch (error) {
    throw error;
  }
};

export const verifyFile = async (url: string): Promise<FileUploadMetadata | null> => {
  try {
    const pathRegex = /o\/(.+?)\?/;
    const match = url.match(pathRegex);
    if (!match) {
      return null;
    }
    
    const path = decodeURIComponent(match[1]);
    const fileRef = ref(storage, path);
    const metadata = await getMetadata(fileRef);
    
    if (!metadata.customMetadata) {
      return null;
    }

    return metadata.customMetadata as FileUploadMetadata;
  } catch (error) {
    return null;
  }
};