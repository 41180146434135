import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const AREAS_OF_STUDY = [
  {
    title: "Individual Study",
    description: "Work one-on-one with world-renowned faculty through private lessons, masterclasses, and personalized instruction tailored to your goals.",
    imageId: "2aba656e-eac9-4b62-858f-ec5c4b687c61",
    quote: "Every note is a journey, every lesson a discovery."
  },
  {
    title: "Chamber Music",
    description: "Collaborate with fellow musicians in intimate ensemble settings, receiving expert coaching and performance opportunities.",
    imageId: "f6e4c490-5d2a-4c74-963e-6ba6cedd19c8",
    quote: "Where individual voices unite in perfect harmony."
  },
  {
    title: "Orchestra",
    description: "Gain professional orchestral experience through full orchestra rehearsals, performances, and specialized training in orchestral repertoire.",
    imageId: "212de122-1ed6-4f21-af96-2a3d74f41b05",
    quote: "Experience the power of collective excellence."
  }
];

const AreasSection = () => {
  return (
    <section className="py-32 relative">
      <div className="max-w-screen-2xl mx-auto">
        {AREAS_OF_STUDY.map((area, index) => (
          <div 
            key={index}
            className={`flex flex-col ${
              index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
            } items-center mb-32 last:mb-0`}
          >
            <div className="flex-1 p-16">
              <div className="max-w-xl">
                <h2 className="font-serif text-8xl mb-8 relative">
                  {area.title}
                  <div className="absolute -left-8 -bottom-4 w-16 h-16 border-l-2 border-b-2 border-[#E63946] opacity-20" />
                </h2>
                
                <p className="text-2xl text-[#1D3557]/70 leading-relaxed mb-8">
                  {area.description}
                </p>

                <blockquote className="font-serif text-3xl text-[#457B9D] italic">
                  "{area.quote}"
                </blockquote>
              </div>
            </div>

            <div className="flex-1 relative">
              <div className="aspect-[4/3] overflow-hidden">
                <img
                  src={getAssetUrl(area.imageId, {
                    width: 800,
                    height: 600,
                    quality: 85,
                    fit: 'cover'
                  })}
                  alt={area.title}
                  className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                />
              </div>
              
              {/* Decorative elements */}
              <div className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2 border-[#457B9D] opacity-10" />
              <div className="absolute -top-8 -left-8 w-32 h-32 border-l-2 border-t-2 border-[#E63946] opacity-10" />
            </div>
          </div>
        ))}
      </div>

      {/* Background musical notation */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(15)].map((_, i) => (
          <div
            key={i}
            className="absolute w-px bg-[#1D3557]/[0.02]"
            style={{
              height: '40%',
              left: `${5 + i * 7}%`,
              top: '30%',
              transform: `rotate(${90 + i * 2}deg)`,
              transformOrigin: 'top'
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default AreasSection;