import React from 'react';
import { getAssetUrl, IMAGE_SIZES } from '../../../utils/directus';

interface Stat {
  number: string;
  label: string;
}

interface HeroProps {
  imageId: string;
  title: string;
  slogan: string;
  description: string;
  stats: Stat[];
}

const Hero: React.FC<HeroProps> = ({ 
  imageId, 
  title, 
  slogan, 
  description,
  stats 
}) => {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background */}
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl(imageId, IMAGE_SIZES.hero)}
          alt="Composition Program"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/80 to-transparent" />
      </div>
      
      {/* Content */}
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-3xl">
          <div className="overflow-hidden mb-8">
            <h1 className="font-serif text-8xl md:text-9xl text-white transform hover:scale-105 transition-transform duration-700">
              {title}
            </h1>
          </div>
          
          <div className="overflow-hidden mb-8">
            <p className="text-3xl md:text-4xl text-white/90 font-serif transform hover:scale-105 transition-transform duration-700 delay-100">
              {slogan}
            </p>
          </div>

          <div className="overflow-hidden">
            <p className="text-xl text-white/80 font-light leading-relaxed transform hover:scale-105 transition-transform duration-700 delay-200">
              {description}
            </p>
          </div>

          {/* Stats */}
          <div className="grid grid-cols-3 gap-8 mt-16">
            {stats.map((stat, index) => (
              <div key={index} className="text-white/90">
                <div className="font-serif text-5xl mb-2">{stat.number}</div>
                <div className="text-sm tracking-wide text-white/60">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
        <div className="w-[1px] h-24 bg-gradient-to-b from-white/0 via-white/50 to-white/0 animate-pulse" />
      </div>
    </div>
  );
};

export default Hero;