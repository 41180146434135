import React from 'react';
import { Search } from 'lucide-react';
import { useArtistContext } from '../../contexts/ArtistContext';
import { ARTIST_CONFIG } from '../../config/artists';

const SearchBar = () => {
  const { searchQuery, setSearchQuery } = useArtistContext();

  return (
    <div className={ARTIST_CONFIG.styles.search.wrapper}>
      <Search className={ARTIST_CONFIG.styles.search.icon} size={20} />
      <input
        type="text"
        placeholder="Search artists..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={ARTIST_CONFIG.styles.search.input}
      />
    </div>
  );
};

export default SearchBar;