import React from 'react';
import ApplicationSteps from './ApplicationSteps';
import PersonalInfo from './personal/PersonalInfo';
import ProgramSelection from './program/ProgramSelection';
import Education from './education/Education';
import Portfolio from './portfolio/Portfolio';
import Survey from './survey/Survey';
import Review from './review/Review';
import NavigationButtons from './NavigationButtons';
import SaveIndicator from './SaveIndicator';

interface ApplicationContentProps {
  currentStep: number;
  formData: any;
  onChange: (data: any, isUpload?: boolean) => void;
  onNext: () => void;
  onPrevious: () => void;
  loading: boolean;
  saving: boolean;
  lastSaved?: Date;
}

const CONFIG = {
  styles: {
    wrapper: "max-w-3xl mx-auto pt-32 pb-12",
    content: "bg-white rounded-lg shadow-sm p-8",
    steps: "mb-24",
    loading: "flex items-center justify-center min-h-[400px]",
    loadingText: "text-lg text-gray-600"
  }
};

const ApplicationContent: React.FC<ApplicationContentProps> = ({
  currentStep,
  formData,
  onChange,
  onNext,
  onPrevious,
  loading,
  saving,
  lastSaved
}) => {
  if (loading) {
    return (
      <div className={CONFIG.styles.wrapper}>
        <div className={CONFIG.styles.loading}>
          <p className={CONFIG.styles.loadingText}>Loading application...</p>
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <PersonalInfo 
            formData={formData.personalInfo || {}} 
            onChange={(data) => onChange({ personalInfo: data })} 
          />
        );
      case 1:
        return (
          <ProgramSelection 
            formData={formData.programInfo || {}} 
            onChange={(data) => onChange({ programInfo: data })} 
          />
        );
      case 2:
        return (
          <Education 
            formData={formData.education || {}} 
            onChange={(data, isUpload) => onChange({ education: data }, isUpload)} 
          />
        );
      case 3:
        return (
          <Portfolio 
            formData={formData.portfolio || {}} 
            onChange={(data, isUpload) => onChange({ portfolio: data }, isUpload)} 
          />
        );
      case 4:
        return (
          <Survey 
            formData={{ survey: formData.survey || {} }}
            onChange={(data) => onChange({ survey: data.survey })} 
          />
        );
      case 5:
        return <Review formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className={CONFIG.styles.wrapper}>
      <div className={CONFIG.styles.steps}>
        <ApplicationSteps currentStep={currentStep} />
      </div>

      <div className={CONFIG.styles.content}>
        {renderStep()}
        <NavigationButtons
          currentStep={currentStep}
          onNext={onNext}
          onPrevious={onPrevious}
          saving={saving}
        />
      </div>

      <SaveIndicator isSaving={saving} lastSaved={lastSaved} />
    </div>
  );
};

export default ApplicationContent;