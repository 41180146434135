import React, { useState, useEffect } from 'react';
import { Play, Pause, ArrowRight } from 'lucide-react';
import { getAssetUrl } from '../utils/directus';
import { FESTIVAL_CONFIG } from '../config/festival';

const AUTOPLAY_INTERVAL = 8000;

const SLIDES = [
  {
    image: getAssetUrl("a0bbbaa1-9826-41d6-8236-77d9d3a6b68c", {
      width: 1920,
      height: 1080,
      quality: 85,
      fit: 'cover'
    }),
    title: "Institute and Fellowship Programs",
    subtitle: `${FESTIVAL_CONFIG.season.year} Season`,
    description: "Discover programs and other opportunities available for musicians",
    color: "#E63946",
    buttons: [
      {
        text: "Visit the AMF Institute",
        url: "/the-institute",
        isPrimary: true
      },
      {
        text: "View Programs",
        url: "/the-institute"
      }
    ]
  },
  {
    image: getAssetUrl("98187a19-0c92-405a-be7d-75f5882ea740", {
      width: 1920,
      height: 1080,
      quality: 85,
      fit: 'cover'
    }),
    quote: "This will be our reply to violence: to make music more intensely, more beautifully, more devotedly than ever before.",
    author: "Leonard Bernstein",
    color: "#457B9D",
    buttons: [
      {
        text: "Join Us This Summer",
        url: "/visitors",
        isPrimary: true
      }
    ]
  }
];

const HeroSlider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (!isPlaying) return;
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % SLIDES.length);
    }, AUTOPLAY_INTERVAL);
    return () => clearInterval(timer);
  }, [isPlaying]);

  return (
    <section className="mt-32 mb-16">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16">
          {/* Content Column */}
          <div className="order-2 md:order-1 md:col-span-5 pt-8 md:pt-0">
            <div className="space-y-12">
              {SLIDES.map((slide, index) => (
                <div
                  key={index}
                  className={`transition-all duration-1000 ${
                    index === currentSlide 
                      ? 'opacity-100 translate-y-0' 
                      : 'opacity-0 translate-y-8 absolute pointer-events-none'
                  }`}
                >
                  {!slide.quote ? (
                    <div className="space-y-6">
                      {slide.title && (
                        <div>
                          <h2 className="font-serif text-4xl md:text-6xl relative inline-block">
                            {slide.title}
                            <div 
                              className="absolute -left-4 -bottom-4 w-12 h-12 -z-10 transition-all duration-700"
                              style={{ backgroundColor: slide.color, opacity: 0.2 }}
                            />
                          </h2>
                        </div>
                      )}
                      <div className="space-y-3">
                        {slide.subtitle && (
                          <p className="text-base tracking-wide">{slide.subtitle}</p>
                        )}
                        {slide.description && (
                          <p className="font-light text-sm leading-relaxed tracking-wide text-[#1D3557]/80 max-w-md">
                            {slide.description}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      <p className="font-serif text-xl md:text-2xl leading-relaxed italic">
                        {slide.quote}
                      </p>
                      {slide.author && (
                        <p className="text-sm tracking-wide text-[#1D3557]/60">
                          — {slide.author}
                        </p>
                      )}
                    </div>
                  )}

                  {slide.buttons && (
                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6 pt-6">
                      {slide.buttons.map((button, buttonIndex) => (
                        <a
                          key={buttonIndex}
                          href={button.url}
                          className={`group relative overflow-hidden ${
                            button.isPrimary ? 'border-2 w-full sm:w-auto' : ''
                          } border-[#1D3557]`}
                        >
                          <span className={`block ${button.isPrimary ? 'px-8 py-4' : ''}`}>
                            <span className={`relative z-10 flex items-center justify-center sm:justify-start gap-3 
                                         text-sm tracking-wide w-full sm:w-auto
                                         ${button.isPrimary ? 'group-hover:text-white' : ''} 
                                         transition-colors duration-500`}
                            >
                              {button.text}
                              <ArrowRight 
                                size={14} 
                                className="group-hover:translate-x-2 transition-transform duration-500"
                              />
                            </span>
                          </span>
                          {button.isPrimary && (
                            <div className="absolute inset-0 bg-[#1D3557] -translate-y-full 
                                        group-hover:translate-y-0 transition-transform duration-500" />
                          )}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              <div className="flex items-center gap-8">
                <button
                  onClick={() => setIsPlaying(!isPlaying)}
                  className="w-10 h-10 rounded-full border flex items-center justify-center
                           hover:bg-[#1D3557] hover:text-white transition-all duration-500
                           border-[#1D3557]/20"
                >
                  {isPlaying ? <Pause size={16} /> : <Play size={16} />}
                </button>
                <div className="flex gap-4">
                  {SLIDES.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentSlide(index)}
                      className={`w-12 h-[1px] transition-all duration-500 ${
                        index === currentSlide ? 'bg-[#1D3557]' : 'bg-[#1D3557]/20'
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Image Column */}
          <div className="order-1 md:order-2 md:col-span-7">
            <div className="relative aspect-[16/9] md:aspect-[16/9] border border-[#1D3557]/10">
              {SLIDES.map((slide, index) => (
                <div
                  key={index}
                  className={`absolute inset-0 transition-opacity duration-[1.5s] ${
                    index === currentSlide ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/20 to-black/30" />
                  <div 
                    className="absolute inset-0 mix-blend-color opacity-10"
                    style={{ backgroundColor: slide.color }}
                  />
                  <img
                    src={slide.image}
                    alt={slide.title || slide.author || ''}
                    className="w-full h-full object-cover hero-slide"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSlider;