import React, { useState, useEffect } from 'react';
import NameFields from './NameFields';
import AddressFields from './AddressFields';
import ContactFields from './ContactFields';
import GuardianFields from './GuardianFields';
import { calculateAge } from '../../../utils/date';

interface Guardian {
  fullName: string;
  relationship: string;
  email: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

interface PersonalInfoProps {
  formData: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    hasPreferredName?: boolean;
    preferredName?: string;
    address?: {
      street?: string;
      city?: string;
      state?: string;
      zipCode?: string;
      country?: string;
    };
    phone?: string;
    gender?: string;
    otherGender?: string;
    dateOfBirth?: string;
    nationality?: string;
    guardians?: Guardian[];
  };
  onChange: (data: Partial<PersonalInfoProps['formData']>) => void;
}

const CONFIG = {
  styles: {
    section: "space-y-8",
    card: "bg-white p-6 rounded-lg shadow-sm",
    title: "text-lg font-medium mb-6",
    content: "space-y-8",
    warning: "text-sm text-[#E63946] mt-2"
  }
};

const PersonalInfo: React.FC<PersonalInfoProps> = ({ 
  formData = {
    firstName: '',
    middleName: '',
    lastName: '',
    hasPreferredName: false,
    preferredName: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US'
    },
    phone: '',
    gender: '',
    otherGender: '',
    dateOfBirth: '',
    nationality: '',
    guardians: []
  }, 
  onChange 
}) => {
  const [isMinor, setIsMinor] = useState(false);

  useEffect(() => {
    if (formData.dateOfBirth) {
      const age = calculateAge(formData.dateOfBirth);
      setIsMinor(age < 18);
    }
  }, [formData.dateOfBirth]);

  const handleNameChange = (nameData: any) => {
    onChange({ ...formData, ...nameData });
  };

  const handleAddressChange = (addressData: any) => {
    onChange({ ...formData, address: { ...formData.address, ...addressData } });
  };

  const handleContactChange = (contactData: any) => {
    onChange({ ...formData, ...contactData });
  };

  const handleGuardiansChange = (guardians: Guardian[]) => {
    onChange({ ...formData, guardians });
  };

  return (
    <div className={CONFIG.styles.section}>
      <div className={CONFIG.styles.card}>
        <h3 className={CONFIG.styles.title}>Personal Information</h3>
        <div className={CONFIG.styles.content}>
          <NameFields
            formData={{
              firstName: formData.firstName || '',
              middleName: formData.middleName || '',
              lastName: formData.lastName || '',
              hasPreferredName: formData.hasPreferredName || false,
              preferredName: formData.preferredName || ''
            }}
            onChange={handleNameChange}
          />
          <AddressFields
            formData={formData.address || {}}
            onChange={handleAddressChange}
          />
          <ContactFields
            formData={{
              phone: formData.phone || '',
              gender: formData.gender || '',
              otherGender: formData.otherGender || '',
              dateOfBirth: formData.dateOfBirth || '',
              nationality: formData.nationality || ''
            }}
            onChange={handleContactChange}
          />
        </div>
      </div>

      {isMinor && (
        <div className={CONFIG.styles.card}>
          <h3 className={CONFIG.styles.title}>Guardian Information</h3>
          <p className={CONFIG.styles.warning}>
            Since you are under 18, please provide guardian information.
          </p>
          <div className={CONFIG.styles.content}>
            <GuardianFields
              guardians={formData.guardians || []}
              onChange={handleGuardiansChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalInfo;