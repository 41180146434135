import React from 'react';
import { NavigationSection } from '../../types/navigation';
import { SITE_CONFIG } from '../../config/constants';

interface DropdownMenuProps {
  items: NavigationSection[];
  variant?: 'light' | 'dark';
}

const CONFIG = {
  styles: {
    wrapper: "absolute hidden group-hover:block pt-6 w-64 -translate-x-1/4",
    content: "bg-white p-4 shadow-sm border border-[#1D3557]/5 rounded-sm",
    section: {
      wrapper: "space-y-1 mb-4 last:mb-0",
      title: "font-serif text-sm text-[#457B9D] px-3 py-2"
    },
    item: {
      link: "block px-3 py-2 text-sm font-light text-[#1D3557] hover:text-[#E63946] hover:bg-[#1D3557]/[0.02] rounded-sm transition-colors"
    }
  }
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ items, variant = 'light' }) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <div className={CONFIG.styles.content}>
        {items.map((section, index) => (
          <div key={index} className={CONFIG.styles.section.wrapper}>
            <h3 className={CONFIG.styles.section.title}>{section.section}</h3>
            {section.items.map((item, idx) => (
              <a 
                key={idx} 
                href={item.url} 
                className={CONFIG.styles.item.link}
              >
                {item.label}
              </a>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownMenu;