import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQProps {
  items: FAQItem[];
  title?: string;
}

const CONFIG = {
  styles: {
    section: "py-32",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    title: "font-serif text-6xl mb-16",
    list: "space-y-6",
    item: {
      wrapper: "border-b border-[#1D3557]/10 last:border-0",
      button: "w-full flex items-start justify-between gap-8 py-6 text-left group",
      question: "font-serif text-2xl group-hover:text-[#E63946] transition-colors duration-300",
      icon: "flex-shrink-0 mt-2 text-[#1D3557]/40 group-hover:text-[#E63946] transition-colors duration-300",
      answer: "text-[#1D3557]/70 pb-6 leading-relaxed"
    }
  }
};

const FAQ: React.FC<FAQProps> = ({ 
  items,
  title = "Frequently Asked Questions" 
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className={CONFIG.styles.section}>
      <div className={CONFIG.styles.container}>
        <h2 className={CONFIG.styles.title}>{title}</h2>
        <div className={CONFIG.styles.list}>
          {items.map((item, index) => (
            <div key={index} className={CONFIG.styles.item.wrapper}>
              <button
                onClick={() => toggleItem(index)}
                className={CONFIG.styles.item.button}
              >
                <h3 className={CONFIG.styles.item.question}>
                  {item.question}
                </h3>
                {openIndex === index ? (
                  <ChevronUp className={CONFIG.styles.item.icon} size={24} />
                ) : (
                  <ChevronDown className={CONFIG.styles.item.icon} size={24} />
                )}
              </button>
              {openIndex === index && (
                <p className={CONFIG.styles.item.answer}>
                  {item.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;