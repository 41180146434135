import React from 'react';
import { Users, Heart, Music, Globe2 } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Diversity & Inclusion",
    description: "Creating a vibrant musical community where every voice is heard, valued, and celebrated.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  mission: {
    title: "Our Commitment",
    description: "The Atlantic Music Festival was founded on the belief that music has the power to bring people together and transcend boundaries. We are committed to fostering an environment where diversity enriches our artistic community and strengthens our creative expression.",
    values: [
      {
        icon: Users,
        title: "Inclusive Community",
        description: "We celebrate and welcome musicians of all backgrounds, creating a space where diverse perspectives and experiences enrich our artistic community."
      },
      {
        icon: Heart,
        title: "Equal Opportunity",
        description: "We are dedicated to providing equal opportunities for all talented musicians, regardless of their race, gender, sexual orientation, age, ability, religion, or socioeconomic status."
      },
      {
        icon: Music,
        title: "Artistic Excellence",
        description: "We believe that diversity in our artistic community leads to richer musical experiences and more innovative creative expressions."
      },
      {
        icon: Globe2,
        title: "Global Perspective",
        description: "We embrace musicians from around the world, fostering cultural exchange and broadening our musical horizons."
      }
    ]
  },
  initiatives: {
    title: "Our Initiatives",
    description: "We are actively working to create positive change in the classical music community through various initiatives:",
    items: [
      {
        title: "Scholarship Programs",
        description: "Providing financial support to talented musicians from underrepresented communities."
      },
      {
        title: "Mentorship Opportunities",
        description: "Connecting emerging artists with established professionals who share similar backgrounds and experiences."
      },
      {
        title: "Diverse Programming",
        description: "Featuring works by composers from various cultural backgrounds and historical periods."
      },
      {
        title: "Community Engagement",
        description: "Making classical music accessible to diverse audiences through free concerts and educational programs."
      }
    ]
  },
  action: {
    title: "Join Our Mission",
    description: "Together, we can build a stronger and more inclusive community of musicians and music lovers. Your support helps us create opportunities and break down barriers in classical music.",
    button: {
      text: "Support Our Mission",
      url: "/support"
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    values: {
      grid: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8",
      card: {
        wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg group hover:bg-white transition-all duration-500",
        icon: "w-12 h-12 text-[#E63946] mb-6",
        title: "font-serif text-xl mb-4",
        description: "text-[#1D3557]/70 leading-relaxed"
      }
    },
    initiatives: {
      grid: "grid grid-cols-1 md:grid-cols-2 gap-8",
      item: {
        wrapper: "space-y-4",
        title: "font-serif text-xl",
        description: "text-[#1D3557]/70 leading-relaxed"
      }
    },
    action: {
      wrapper: "text-center max-w-3xl mx-auto",
      title: "font-serif text-4xl mb-6",
      description: "text-[#1D3557]/70 leading-relaxed mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const Diversity = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      {/* Mission & Values */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className="max-w-3xl mb-16">
            <h2 className="font-serif text-4xl mb-6">{CONFIG.mission.title}</h2>
            <p className="text-lg text-[#1D3557]/80 leading-relaxed">
              {CONFIG.mission.description}
            </p>
          </div>

          <div className={CONFIG.styles.values.grid}>
            {CONFIG.mission.values.map((value, index) => {
              const Icon = value.icon;
              return (
                <div key={index} className={CONFIG.styles.values.card.wrapper}>
                  <Icon className={CONFIG.styles.values.card.icon} />
                  <h3 className={CONFIG.styles.values.card.title}>{value.title}</h3>
                  <p className={CONFIG.styles.values.card.description}>{value.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Initiatives */}
      <section className={`${CONFIG.styles.section} bg-[#1D3557]/[0.02]`}>
        <div className={CONFIG.styles.container}>
          <div className="max-w-3xl mb-16">
            <h2 className="font-serif text-4xl mb-6">{CONFIG.initiatives.title}</h2>
            <p className="text-lg text-[#1D3557]/80 leading-relaxed">
              {CONFIG.initiatives.description}
            </p>
          </div>

          <div className={CONFIG.styles.initiatives.grid}>
            {CONFIG.initiatives.items.map((item, index) => (
              <div key={index} className={CONFIG.styles.initiatives.item.wrapper}>
                <h3 className={CONFIG.styles.initiatives.item.title}>{item.title}</h3>
                <p className={CONFIG.styles.initiatives.item.description}>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.action.wrapper}>
            <h2 className={CONFIG.styles.action.title}>{CONFIG.action.title}</h2>
            <p className={CONFIG.styles.action.description}>{CONFIG.action.description}</p>
            <a href={CONFIG.action.button.url} className={CONFIG.styles.action.button.wrapper}>
              <span className={CONFIG.styles.action.button.text}>
                {CONFIG.action.button.text}
              </span>
              <div className={CONFIG.styles.action.button.background} />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Diversity;