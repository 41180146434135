import React from 'react';
import { useArtistContext } from '../../contexts/ArtistContext';
import { getAssetUrl } from '../../utils/directus';
import { ARTIST_CONFIG } from '../../config/artists';

const ArtistCard = ({ artist }) => {
  const { setSelectedArtist } = useArtistContext();

  return (
    <div
      className={ARTIST_CONFIG.styles.artist.wrapper}
      onClick={() => setSelectedArtist(artist)}
    >
      <div className={ARTIST_CONFIG.styles.artist.imageWrapper}>
        {artist.profileImage ? (
          <img
            src={getAssetUrl(artist.profileImage, {
              width: 400,
              height: 600,
              quality: 85,
              fit: 'cover'
            })}
            alt={artist.name}
            className={ARTIST_CONFIG.styles.artist.image}
          />
        ) : (
          <div className={ARTIST_CONFIG.styles.artist.placeholder}>
            <span className={ARTIST_CONFIG.styles.artist.placeholderText}>
              {artist.name[0]}
            </span>
          </div>
        )}
      </div>
      <h3 className={ARTIST_CONFIG.styles.artist.name}>{artist.name}</h3>
      {artist.affiliation && (
        <p className={ARTIST_CONFIG.styles.artist.affiliation}>
          {artist.affiliation}
        </p>
      )}
    </div>
  );
};

export default ArtistCard;