import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from './PreCollege/components/Hero';
import Gallery from './PreCollege/components/Gallery';
import Highlights from './PreCollege/components/Highlights';
import Schedule from './PreCollege/components/Schedule';
import SpecializedPaths from './PreCollege/components/SpecializedPaths';
import ResidencyExperience from './PreCollege/components/ResidencyExperience';
import ApplicationProcess from './PreCollege/components/ApplicationProcess';
import { PRE_COLLEGE_DATA } from './PreCollege/data';

const PreCollege = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header variant="dark" />
      
      <main>
        <Hero 
          imageId={PRE_COLLEGE_DATA.hero.imageId}
          title={PRE_COLLEGE_DATA.hero.title}
          description={PRE_COLLEGE_DATA.hero.description}
        />

        {/* Introduction */}
        <section className="py-16 md:py-24">
          <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
            <div className="max-w-3xl">
              {PRE_COLLEGE_DATA.intro.content.split('\n\n').map((paragraph, index) => (
                <p key={index} className="text-lg text-[#1D3557]/80 mb-6 font-light leading-relaxed">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </section>

        {/* Gallery */}
        <section className="py-16 md:py-24 bg-[#1D3557]/[0.02]">
          <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
            <Gallery images={PRE_COLLEGE_DATA.gallery.images} />
          </div>
        </section>

        <Highlights 
          title={PRE_COLLEGE_DATA.highlights.title}
          items={PRE_COLLEGE_DATA.highlights.items}
        />

        <Schedule 
          title={PRE_COLLEGE_DATA.schedule.title}
          description={PRE_COLLEGE_DATA.schedule.description}
          sections={PRE_COLLEGE_DATA.schedule.sections}
        />

        <SpecializedPaths 
          title={PRE_COLLEGE_DATA.paths.title}
          description={PRE_COLLEGE_DATA.paths.description}
          programs={PRE_COLLEGE_DATA.paths.programs}
        />

        <ResidencyExperience 
          title={PRE_COLLEGE_DATA.residency.title}
          description={PRE_COLLEGE_DATA.residency.description}
          features={PRE_COLLEGE_DATA.residency.features}
          gallery={PRE_COLLEGE_DATA.residency.gallery}
        />

        <ApplicationProcess 
          title={PRE_COLLEGE_DATA.application.title}
          description={PRE_COLLEGE_DATA.application.description}
          requirements={PRE_COLLEGE_DATA.application.requirements}
        />
      </main>

      <Footer />
    </div>
  );
};

export default PreCollege;