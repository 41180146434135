import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const COLLABORATIONS = [
  {
    imageId: 'b22cfabd-a00f-47f7-890d-8747834f298d',
    title: "World Premieres",
    description: "Bring new works to life through close collaboration with composers, shaping the future of classical music.",
    position: "right"
  },
  {
    imageId: 'd6861754-d6b0-415f-871f-faef69cd233e',
    title: "Innovative Performance",
    description: "Push boundaries with cutting-edge techniques and experimental approaches to music-making.",
    position: "left"
  },
  {
    imageId: '16969daf-a600-46ca-aca7-13e385ecc2ce',
    title: "Technology Integration",
    description: "Explore the intersection of acoustic and electronic music through our MUGIC™ Lab collaboration.",
    position: "center"
  }
];

const CollaborationSection = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-7xl mb-24">Collaborative Spirit</h2>

        <div className="space-y-32">
          {COLLABORATIONS.map((collab, index) => (
            <div 
              key={index}
              className={`flex flex-col ${
                collab.position === 'right' ? 'md:items-end' : 
                collab.position === 'center' ? 'md:items-center' : ''
              }`}
            >
              <div className={`relative max-w-3xl ${
                collab.position === 'right' ? 'md:text-right' :
                collab.position === 'center' ? 'text-center' : ''
              }`}>
                {/* Decorative line */}
                <div 
                  className="absolute -left-8 top-0 bottom-0 w-px"
                  style={{
                    background: 'linear-gradient(to bottom, #E63946, transparent)'
                  }}
                />

                <div className="relative">
                  <div className="aspect-[16/9] mb-8 overflow-hidden rounded-lg">
                    <img
                      src={getAssetUrl(collab.imageId, {
                        width: 1200,
                        height: 675,
                        quality: 85,
                        fit: 'cover'
                      })}
                      alt={collab.title}
                      className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                    />
                  </div>

                  <h3 className="font-serif text-4xl mb-4">{collab.title}</h3>
                  <p className="text-lg text-[#1D3557]/70 leading-relaxed max-w-xl">
                    {collab.description}
                  </p>

                  {/* Decorative elements */}
                  <div className="absolute -right-16 top-1/2 -translate-y-1/2 w-12 h-px bg-[#E63946]" />
                  <div className="absolute -left-16 bottom-0 w-12 h-px bg-[#457B9D]" />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Abstract notation background */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(10)].map((_, i) => (
            <div
              key={i}
              className="absolute w-full h-px"
              style={{
                background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.02 - i * 0.002}), transparent)`,
                top: `${10 + i * 10}%`,
                transform: `rotate(${-5 + i}deg)`,
                animation: `floatLine ${5 + i * 0.5}s ease-in-out infinite`,
                animationDelay: `${i * 0.2}s`
              }}
            />
          ))}
        </div>
      </div>

      <style>{`
        @keyframes floatLine {
          0%, 100% { transform: rotate(var(--rotation)) translateY(0); }
          50% { transform: rotate(var(--rotation)) translateY(-10px); }
        }
      `}</style>
    </section>
  );
};

export default CollaborationSection;