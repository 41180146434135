import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ConcertsListing from './ConcertsListing';
import ConcertDetail from './ConcertDetail';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Concerts = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header variant="dark" />
      <Routes>
        <Route index element={<ConcertsListing />} />
        <Route path=":slug" element={<ConcertDetail />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Concerts;