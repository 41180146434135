import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/contemporary/HeroSection';
import MissionSection from '../components/contemporary/MissionSection';
import CollaborationSection from '../components/contemporary/CollaborationSection';
import OpportunitiesSection from '../components/contemporary/OpportunitiesSection';
import EligibilitySection from '../components/contemporary/EligibilitySection';
import FAQ from '../components/FAQ';

const ContemporaryEnsemble = () => {
  return (
    <div>
      <Header variant="dark" />
      
      <HeroSection />
      <MissionSection />
      <CollaborationSection />
      <OpportunitiesSection />
      <EligibilitySection />
      
      <FAQ
        items={[
          {
            question: "What is the Contemporary Ensemble Fellowship?",
            answer: "The Contemporary Ensemble Fellowship is a specialized program for musicians deeply committed to promoting new and contemporary music. Fellows receive a stipend of $800 plus complete coverage of tuition, housing, and meals during the six-week residency."
          },
          {
            question: "What opportunities are available to fellows?",
            answer: "Fellows collaborate with over 40 composers throughout the season, premiere new works, perform as featured artists, and work closely with artist-faculty members. They also participate in the larger festival community as orchestra members."
          },
          {
            question: "What are the eligibility requirements?",
            answer: "Applicants must be 18 or older, commit to the entire residency period, and demonstrate exceptional musical talent and dedication to contemporary music. The program is open to instrumentalists applying through the Instrumental Program or Piano Institute."
          },
          {
            question: "How do I apply?",
            answer: "Apply through either the Instrumental Program or Piano Institute and Seminar. Your application will automatically be considered for both the Resident Artist Fellowship and Contemporary Ensemble Fellowship based on your portfolio."
          },
          {
            question: "What is the time commitment?",
            answer: "Fellows must be available for the entire six-week residency with no late arrivals, early departures, or absences. They contribute up to 2 hours daily for administrative needs and are available for performances throughout the residency."
          },
          {
            question: "What financial support is provided?",
            answer: "Fellows receive complete coverage of tuition, housing, and meals, plus an $800 stipend upon successful completion of the festival season."
          },
          {
            question: "Can I participate in other festival activities?",
            answer: "Yes, fellows are active members of the festival community and can participate in orchestra, chamber music, and other collaborative opportunities while focusing on contemporary music."
          }
        ]}
      />

      <Footer />
    </div>
  );
};

export default ContemporaryEnsemble;