import React from 'react';
import { ArrowRight } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Career Opportunities",
    description: "Join our team at the Atlantic Music Festival.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  content: {
    title: "Check Back Soon",
    description: "We're currently updating our job opportunities for the upcoming season. Please check back later for available positions.",
    note: "Typically, we begin our hiring process in early spring for the summer festival season.",
    cta: {
      text: "View Internship Opportunities",
      url: "/get-involved/jobs/internship"
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    content: {
      wrapper: "max-w-2xl mx-auto text-center",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 mb-4",
      note: "text-[#457B9D] mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const Jobs = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.content.wrapper}>
            <h2 className={CONFIG.styles.content.title}>{CONFIG.content.title}</h2>
            <p className={CONFIG.styles.content.description}>{CONFIG.content.description}</p>
            <p className={CONFIG.styles.content.note}>{CONFIG.content.note}</p>
            <a href={CONFIG.content.cta.url} className={CONFIG.styles.content.button.wrapper}>
              <span className={CONFIG.styles.content.button.text}>
                {CONFIG.content.cta.text}
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className={CONFIG.styles.content.button.background} />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Jobs;