import React from 'react';
import { X } from 'lucide-react';
import FormField from '../FormField';
import { APPLICATION_CONFIG } from '../../../config/application';

interface AcademicEntry {
  currentlyEnrolled: boolean;
  yearsAttended: string;
  schoolName: string;
  degree: string;
}

interface AcademicBackgroundProps {
  academic: AcademicEntry[];
  onChange: (academic: AcademicEntry[]) => void;
}

const CONFIG = {
  title: "Academic Background",
  description: "List your educational history, starting with your current or most recent institution.",
  addButton: "Add Another Institution",
  styles: {
    section: "space-y-6",
    description: "text-sm text-[#1D3557]/60 mb-6",
    list: "space-y-8",
    item: {
      wrapper: "relative bg-[#1D3557]/[0.02] p-6 rounded-lg",
      grid: "grid grid-cols-1 md:grid-cols-2 gap-6",
      remove: "absolute top-4 right-4 text-[#1D3557]/40 hover:text-[#E63946] transition-colors"
    },
    button: {
      base: "relative overflow-hidden group mt-6",
      container: "block px-6 py-3 border-2 border-[#1D3557]",
      text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
      background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
    }
  }
};

const defaultEntry: AcademicEntry = {
  currentlyEnrolled: false,
  yearsAttended: '',
  schoolName: '',
  degree: ''
};

const AcademicBackground: React.FC<AcademicBackgroundProps> = ({ 
  academic = [{ ...defaultEntry }],
  onChange 
}) => {
  const handleAdd = () => {
    onChange([...academic, { ...defaultEntry }]);
  };

  const handleRemove = (index: number) => {
    if (academic.length > 1) {
      onChange(academic.filter((_, i) => i !== index));
    }
  };

  const handleChange = (index: number, field: keyof AcademicEntry, value: any) => {
    const updatedAcademic = academic.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    onChange(updatedAcademic);
  };

  const entries = academic.length > 0 ? academic : [{ ...defaultEntry }];
  const degrees = APPLICATION_CONFIG?.degrees || [];

  return (
    <div className={CONFIG.styles.section}>
      <h4 className="text-lg font-medium">{CONFIG.title}</h4>
      <p className={CONFIG.styles.description}>{CONFIG.description}</p>

      <div className={CONFIG.styles.list}>
        {entries.map((item, index) => (
          <div key={index} className={CONFIG.styles.item.wrapper}>
            {index > 0 && (
              <button
                onClick={() => handleRemove(index)}
                className={CONFIG.styles.item.remove}
                type="button"
              >
                <X size={20} />
              </button>
            )}
            
            <div className={CONFIG.styles.item.grid}>
              <FormField
                label="Currently Enrolled"
                type="select"
                value={item.currentlyEnrolled.toString()}
                onChange={(e) => handleChange(index, 'currentlyEnrolled', e.target.value === 'true')}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </FormField>

              <FormField
                label="Years Attended"
                type="text"
                value={item.yearsAttended}
                onChange={(e) => handleChange(index, 'yearsAttended', e.target.value)}
                placeholder="e.g., 2020-2024"
                required
              />

              <FormField
                label="School Name"
                type="text"
                value={item.schoolName}
                onChange={(e) => handleChange(index, 'schoolName', e.target.value)}
                placeholder="Enter school name"
                required
              />

              <FormField
                label="Degree"
                type="select"
                value={item.degree}
                onChange={(e) => handleChange(index, 'degree', e.target.value)}
                required
              >
                <option value="">Select degree</option>
                {degrees.map((degree) => (
                  <option key={degree} value={degree}>{degree}</option>
                ))}
              </FormField>
            </div>
          </div>
        ))}
      </div>

      <button 
        onClick={handleAdd}
        className={CONFIG.styles.button.base}
        type="button"
      >
        <span className={CONFIG.styles.button.container}>
          <span className={CONFIG.styles.button.text}>
            {CONFIG.addButton}
          </span>
        </span>
        <div className={CONFIG.styles.button.background} />
      </button>
    </div>
  );
};

export default AcademicBackground;