import React, { useCallback } from 'react';
import { Play, Pause, Volume2, VolumeX, SkipBack, SkipForward } from 'lucide-react';
import { useAudio } from '../contexts/AudioContext';

const AudioPlayer = () => {
  const { 
    currentTrack,
    isPlaying,
    progress,
    duration,
    volume,
    isMuted,
    isVisible,
    togglePlayPause,
    seekTo,
    setVolume,
    toggleMute,
    playNextTrack,
    playPreviousTrack
  } = useAudio();

  const handleSeek = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const percent = (e.clientX - rect.left) / rect.width;
    seekTo(percent * duration);
  }, [duration, seekTo]);

  const handleVolumeChange = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const newVolume = Math.max(0, Math.min(1, (e.clientX - rect.left) / rect.width));
    setVolume(newVolume);
  }, [setVolume]);

  if (!isVisible || !currentTrack) {
    return null;
  }

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 z-[100] bg-[#1D3557] bg-opacity-95 shadow-lg">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8 h-20 flex items-center gap-6">
        <div className="flex items-center gap-2">
          <button 
            onClick={playPreviousTrack}
            className="text-white hover:text-[#E63946] transition-colors"
          >
            <SkipBack size={16} />
          </button>

          <button 
            onClick={togglePlayPause}
            className="w-8 h-8 rounded-full border border-white/20 flex items-center justify-center text-white hover:bg-white/10 transition-colors"
          >
            {isPlaying ? <Pause size={16} /> : <Play size={16} />}
          </button>

          <button 
            onClick={playNextTrack}
            className="text-white hover:text-[#E63946] transition-colors"
          >
            <SkipForward size={16} />
          </button>
        </div>

        <div className="flex-1 min-w-0">
          <div className="font-serif text-sm text-white truncate">
            {currentTrack.title}
          </div>
          <div className="text-xs text-white/60 truncate">
            {currentTrack.description}
          </div>
        </div>

        <div className="flex-1">
          <div 
            className="relative h-1 bg-white/20 rounded cursor-pointer group"
            onClick={handleSeek}
          >
            <div 
              className="absolute left-0 top-0 h-full bg-white/80 rounded group-hover:bg-[#E63946] transition-colors"
              style={{ width: `${(progress / duration) * 100}%` }}
            />
          </div>
          <div className="flex justify-between text-[10px] text-white/60 mt-1">
            <span>{formatTime(progress)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>

        <div className="flex items-center gap-2 w-24">
          <button 
            onClick={toggleMute}
            className="text-white hover:text-[#E63946] transition-colors"
          >
            {isMuted ? <VolumeX size={16} /> : <Volume2 size={16} />}
          </button>
          <div 
            className="flex-1 h-1 bg-white/20 rounded cursor-pointer relative group"
            onClick={handleVolumeChange}
          >
            <div 
              className="absolute left-0 top-0 h-full bg-white/80 rounded group-hover:bg-[#E63946] transition-colors"
              style={{ width: `${(isMuted ? 0 : volume) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;