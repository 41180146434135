import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Hero from './components/Hero';
import Features from './components/Features';
import Overview from './components/Overview';
import Application from './components/Application';
import FacultySection from '../../components/programs/FacultySection';
import { COMPOSITION_PROGRAM_DATA } from './data';
import programData from '../../data/programs/cpp.json';

const CompositionProgram = () => {
  // Process faculty data
  const faculty = programData.programs?.[0]?.faculty?.map(f => ({
    name: f.members_id.name,
    profileImage: f.members_id.profile_image?.id,
    biography: f.members_id.biography,
    affiliation: f.members_id.affiliation,
    instruments: f.members_id.instruments?.map(i => ({
      value: i.instruments_id.value,
      label: i.instruments_id.label
    })) || []
  })) || [];

  // Process and sort audition requirements
  const auditionRequirements = programData.programs?.[0]?.audition_requirements
    ?.map(req => ({
      requirement: req.audition_requirements_id.requirement,
      sort: req.audition_requirements_id.sort || 0
    }))
    .sort((a, b) => a.sort - b.sort)
    .map(req => req.requirement) || [];

  return (
    <div className="min-h-screen flex flex-col">
      <Header variant="dark" />
      
      <main>
        <Hero 
          imageId={COMPOSITION_PROGRAM_DATA.hero.imageId}
          title={COMPOSITION_PROGRAM_DATA.hero.title}
          slogan={COMPOSITION_PROGRAM_DATA.hero.slogan}
          description={COMPOSITION_PROGRAM_DATA.hero.description}
          stats={COMPOSITION_PROGRAM_DATA.hero.stats}
        />

        <Features features={COMPOSITION_PROGRAM_DATA.features} />
        
        <Overview 
          tracks={COMPOSITION_PROGRAM_DATA.tracks}
          opportunities={COMPOSITION_PROGRAM_DATA.opportunities}
        />
        
        <FacultySection 
          faculty={faculty}
          title="Distinguished Faculty"
          description="Learn from and work alongside world-renowned composers and innovators who are shaping the future of music."
        />

        <Application 
          title={COMPOSITION_PROGRAM_DATA.application.title}
          description={COMPOSITION_PROGRAM_DATA.application.description}
          requirements={auditionRequirements}
          deadline={COMPOSITION_PROGRAM_DATA.application.deadline}
        />
      </main>

      <Footer />
    </div>
  );
};

export default CompositionProgram;