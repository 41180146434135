import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/instrumental/HeroSection';
import AreasSection from '../components/instrumental/AreasSection';
import AwardsSection from '../components/instrumental/AwardsSection';
import VisualJourney from '../components/instrumental/VisualJourney';
import AuditionSection from '../components/instrumental/AuditionSection';
import InstrumentExplorer from '../components/instrumental/InstrumentExplorer';
import FacultySection from '../components/programs/FacultySection';
import FAQ from '../components/FAQ';
import programData from '../data/programs/isp.json';

const InstrumentalProgram = () => {
  const [selectedInstrument, setSelectedInstrument] = React.useState('');

  // Process faculty data
  const faculty = programData.programs?.[0]?.faculty?.map(f => ({
    name: f.members_id.name,
    profileImage: f.members_id.profile_image?.id,
    biography: f.members_id.biography,
    affiliation: f.members_id.affiliation,
    instruments: f.members_id.instruments?.map(i => ({
      value: i.instruments_id.value,
      label: i.instruments_id.label
    })) || []
  })) || [];

  // Process and sort audition requirements
  const requirements = programData.programs?.[0]?.audition_requirements
    ?.map(req => ({
      requirement: req.audition_requirements_id.requirement,
      sort: req.audition_requirements_id.sort || 0
    }))
    .sort((a, b) => a.sort - b.sort)
    .map(req => req.requirement) || [];

  return (
    <div className="relative">
      <Header variant="dark" />
      
      <HeroSection />
      <AreasSection />
      
      <FacultySection
        faculty={faculty}
        selectedInstrument={selectedInstrument}
        title="Distinguished Faculty"
        description="Learn from and work alongside world-renowned musicians who are shaping the future of classical music."
      />
      
      <AwardsSection />
      <VisualJourney />
      <AuditionSection requirements={requirements} />

      {/* Fixed instrument selector at bottom */}
      <InstrumentExplorer
        selectedInstrument={selectedInstrument}
        onSelect={setSelectedInstrument}
      />

      <FAQ
        items={[
          {
            question: "What is the Atlantic Music Festival Instrumental Program?",
            answer: "The Atlantic Music Festival Instrumental Program is a six-week summer music program that provides an immersive and high-level music-making experience. Through private lessons, masterclasses, and performances, participants can learn from world-renowned faculty members and collaborate with other talented musicians."
          },
          {
            question: "What areas of study are available in the Instrumental Program?",
            answer: "Participants in the Instrumental Program can take advantage of opportunities for individual study through private lessons, chamber music collaborations, and orchestral performance opportunities. Each area provides unique learning experiences and performance possibilities."
          },
          {
            question: "Who are the artist-faculty members of the Instrumental Program?",
            answer: "The artist-faculty members of the Instrumental Program include professionals in various instrument categories, such as winds, brass, percussion, harp, and strings. You can view a full list of faculty members above and filter by instrument category."
          },
          {
            question: "Are there age restrictions for the Instrumental Program?",
            answer: "The Instrumental Program is for adults 18 and older. For pre-college programs for students under 18, please visit our Pre-College Program page."
          },
          {
            question: "Are there opportunities to work with composers or contemporary music?",
            answer: "The Atlantic Music Festival provides numerous opportunities to work with composers and contemporary performances. The festival offers collaborative opportunities through the Contemporary Performance Program and hosts over 50 composers each summer. In addition, the festival offers innovative programs such as the Future Music Lab and the Motion Sensor Program."
          },
          {
            question: "Can I study more than one instrument at a time?",
            answer: "Our program is intensive, so we don't recommend studying more than one instrument at a time. However, all of our seminars are open to all participants, so you can take advantage of the many opportunities available to you by attending any of them."
          },
          {
            question: "Will I be assigned to a specific teacher's studio?",
            answer: "Students in the Instrumental Program study with many of the teachers listed on our faculty page, rather than being assigned to one specific studio. Your lesson schedule will be determined based on your session and the availability of the faculty members. This allows you to have the opportunity to learn from a variety of experienced teachers and broaden your musical education."
          },
          {
            question: "Can I request specific faculty members for private lessons?",
            answer: "After being accepted to the program, you can request specific faculty members to study with. While we may not be able to guarantee a spot, we'll do our best to accommodate your preferences."
          },
          {
            question: "How do I audition for the Instrumental Program?",
            answer: "You can audition for the Instrumental Program by submitting a portfolio through the online application. Your portfolio should represent your current level of musicianship and can include recordings, scores, and/or other materials."
          },
          {
            question: "Do I need to submit professional recordings for my audition?",
            answer: "Professional recordings are not required for your audition portfolio. However, the recording should be able to clearly convey your current level of musicality."
          },
          {
            question: "Does my portfolio submission need to include accompaniment?",
            answer: "You are encouraged to submit works that require accompaniment with accompaniment. However, this is not a requirement."
          },
          {
            question: "Are scholarships and other awards available?",
            answer: "Yes, the Atlantic Music Festival offers various forms of financial support including fellowships, teaching assistantships, resident assistant positions, and merit-based scholarships. Early application is recommended for maximum consideration."
          },
          {
            question: "Are meals and housing provided?",
            answer: "Yes, our tuition includes housing and meals at Colby College. For more information, please see our residency guidelines page."
          },
          {
            question: "Is transportation provided?",
            answer: "You are responsible for your own transportation to and from the festival. However, we do provide shuttle services from major airports near the festival at the beginning and end of the sessions for an additional fee."
          },
          {
            question: "Does the festival provide instrument rentals?",
            answer: "The festival does not provide loaner instruments. If you've been accepted but can't bring your instrument, please contact us and we'll help you find a local vendor who may have availability."
          }
        ]}
      />

      <Footer />
    </div>
  );
};

export default InstrumentalProgram;