import React from 'react';
import { PORTFOLIO_CONFIG } from '../../config';

interface PDFPreviewProps {
  url: string;
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ url }) => {
  return (
    <div className={PORTFOLIO_CONFIG.styles.preview.pdf.wrapper}>
      <iframe
        src={`${url}#view=FitH`}
        className={PORTFOLIO_CONFIG.styles.preview.pdf.iframe}
        title="PDF Preview"
      />
    </div>
  );
};

export default PDFPreview;