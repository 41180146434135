import React from 'react';

interface RequirementsSectionProps {
  requirements: string[];
}

const RequirementsSection: React.FC<RequirementsSectionProps> = ({ requirements }) => {
  return (
    <section className="py-24">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="bg-[#1D3557] text-white p-16 rounded-lg relative overflow-hidden">
          <div className="relative z-10 max-w-3xl mx-auto">
            <h2 className="font-serif text-4xl mb-8 text-center">Audition Requirements</h2>
            <div className="space-y-6">
              {requirements.map((requirement, index) => (
                <div key={index} className="flex items-start gap-4 text-white/80">
                  <span className="w-8 h-px bg-[#E63946] mt-3" />
                  {requirement}
                </div>
              ))}
            </div>
          </div>

          {/* Conducting gesture background */}
          <div className="absolute inset-0 pointer-events-none">
            {[...Array(12)].map((_, i) => (
              <div
                key={i}
                className="absolute bg-white/5"
                style={{
                  height: '1px',
                  width: '60%',
                  left: '20%',
                  top: `${8 + i * 8}%`,
                  transform: `rotate(${-30 + i * 5}deg)`,
                  animation: 'gesture-flow 3s ease-in-out infinite',
                  animationDelay: `${i * 0.1}s`
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequirementsSection;