import React from 'react';
import { useNavigate } from 'react-router-dom';

const CATEGORIES = [
  {
    label: "Strings",
    instruments: [
      { value: "violin", label: "Violin" },
      { value: "viola", label: "Viola" },
      { value: "cello", label: "Cello" },
      { value: "contrabass", label: "Double Bass" }
    ]
  },
  {
    label: "Woodwinds",
    instruments: [
      { value: "flute", label: "Flute" },
      { value: "oboe", label: "Oboe" },
      { value: "clarinet", label: "Clarinet" },
      { value: "bassoon", label: "Bassoon" }
    ]
  },
  {
    label: "Brass",
    instruments: [
      { value: "trumpet", label: "Trumpet" },
      { value: "horn", label: "French Horn" },
      { value: "trombone", label: "Trombone" },
      { value: "tuba", label: "Tuba" }
    ]
  },
  {
    label: "Piano",
    instruments: [
      { 
        value: "piano", 
        label: "Piano",
        redirectUrl: "/the-institute/programs/piano-institute-and-seminar"
      }
    ]
  },
  {
    label: "Other",
    instruments: [
      { value: "percussion", label: "Percussion" },
      { value: "harp", label: "Harp" }
    ]
  }
];

interface InstrumentExplorerProps {
  selectedInstrument: string;
  onSelect: (instrument: string) => void;
}

const InstrumentExplorer: React.FC<InstrumentExplorerProps> = ({
  selectedInstrument,
  onSelect
}) => {
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white/95 backdrop-blur-sm border-t border-[#1D3557]/10 shadow-lg z-50">
      <div className="max-w-screen-2xl mx-auto px-8 py-6">
        <div className="flex items-center gap-8 overflow-x-auto scrollbar-hide">
          <button
            onClick={() => onSelect('')}
            className={`px-6 py-3 text-sm rounded-full whitespace-nowrap transition-all duration-500 ${
              selectedInstrument === '' 
                ? 'bg-[#1D3557] text-white shadow-lg transform -translate-y-1' 
                : 'bg-[#1D3557]/[0.05] hover:bg-[#1D3557]/10'
            }`}
          >
            All Instruments
          </button>

          {CATEGORIES.map((category, index) => (
            <div key={category.label} className="flex items-center gap-4">
              {index > 0 && (
                <div className="h-8 w-px bg-[#1D3557]/10" />
              )}
              <div className="flex items-center gap-4">
                <span className="text-sm text-[#1D3557]/60 whitespace-nowrap font-serif">
                  {category.label}
                </span>
                <div className="flex items-center gap-2">
                  {category.instruments.map((instrument) => (
                    <button
                      key={instrument.value}
                      onClick={() => {
                        if (instrument.redirectUrl) {
                          navigate(instrument.redirectUrl);
                        } else {
                          onSelect(instrument.value);
                        }
                      }}
                      className={`px-6 py-3 text-sm rounded-full whitespace-nowrap transition-all duration-500 ${
                        selectedInstrument === instrument.value
                          ? 'bg-[#1D3557] text-white shadow-lg transform -translate-y-1'
                          : 'bg-[#1D3557]/[0.05] hover:bg-[#1D3557]/10'
                      }`}
                    >
                      {instrument.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstrumentExplorer;