import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getAssetUrl, IMAGE_SIZES } from '../utils/directus';

const CONFIG = {
  title: "Moments",
  subtitle: "2009—2024",
  description: "We are building an archive to commemorate fifteen remarkable seasons of artistic excellence. Stay tuned as we unveil our digital collection of performances, stories, and memories that have shaped the Atlantic Music Festival.",
  archiveButton: {
    text: "Archive Coming Soon",
    url: "#",
    disabled: true
  },
  images: [
    {
      url: getAssetUrl("d9c5dae2-55b5-4295-894e-a978042030e2", IMAGE_SIZES.galleryLarge),
      title: "Chamber Music Series",
      date: "2010",
      type: "featured"
    },
    {
      url: getAssetUrl("a1b8b231-db84-4ead-995b-05c99baaf06c", IMAGE_SIZES.gallery),
      title: "Orchestra Concert",
      date: "2010",
      type: "small"
    },
    {
      url: getAssetUrl("08de166b-8920-403c-9987-1e790fa876f9", IMAGE_SIZES.gallery),
      title: "New Music Concert",
      date: "2010",
      type: "small"
    }
  ]
};

const Gallery: React.FC = () => {
  return (
    <section className="relative py-16 md:py-32">
      <div className="absolute inset-0 bg-gradient-to-b from-[#8B0000]/95 to-[#8B0000]" />
      <div className="relative max-w-screen-2xl mx-auto px-6 md:px-16">
        <div className="mb-16 md:mb-32 text-white/90">
          <div className="mb-12 md:mb-24 relative">
            <h3 className="font-serif text-5xl md:text-7xl lg:text-8xl">
              {CONFIG.title}
            </h3>
            <span className="absolute -right-4 top-0 text-sm tracking-[0.2em] text-white/40 rotate-90 origin-top-right hidden md:block">
              {CONFIG.subtitle}
            </span>
          </div>
          <p className="font-light text-base md:text-lg max-w-2xl leading-relaxed">
            {CONFIG.description}
          </p>
          <button 
            disabled={CONFIG.archiveButton.disabled}
            className="mt-8 md:mt-12 inline-block group opacity-50 cursor-not-allowed"
          >
            <span className="flex items-center gap-4 border-b border-white/20 pb-2">
              <span className="text-sm tracking-wide">{CONFIG.archiveButton.text}</span>
              <ArrowRight size={16} className="group-hover:translate-x-2 transition-transform duration-500" />
            </span>
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 relative before:absolute before:inset-0 before:border before:border-white/10 before:-m-8 before:hidden md:before:block">
          {CONFIG.images.map((image, index) => (
            <div 
              key={index}
              className={`relative group h-[300px] md:h-[400px] ${
                image.type === 'featured' ? 'col-span-12 md:col-span-6' : 'col-span-12 md:col-span-2'
              }`}
            >
              <img
                src={image.url}
                alt={image.title}
                className="absolute inset-0 w-full h-full object-cover filter grayscale hover:grayscale-0 transition-all duration-1000"
              />
              <div className="absolute inset-0 bg-black/20 group-hover:bg-black/0 transition-colors duration-1000" />
              <div className="absolute inset-x-0 bottom-0 p-4 text-white md:transform md:translate-y-full md:group-hover:translate-y-0 md:transition-transform md:duration-500 opacity-0 group-hover:opacity-100 transition-opacity duration-500 md:opacity-100">
                <h4 className="font-serif text-lg">{image.title}</h4>
                <p className="text-sm text-white/70">{image.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;