import React from 'react';
import { ChevronDown } from 'lucide-react';
import { NavigationItem } from '../../types/navigation';
import { SITE_CONFIG } from '../../config/constants';
import MegaMenu from './MegaMenu';
import DropdownMenu from './DropdownMenu';

interface DesktopNavProps {
  items: NavigationItem[];
  variant?: 'light' | 'dark';
}

const CONFIG = {
  styles: {
    nav: "hidden md:flex items-center gap-12",
    item: {
      wrapper: "group relative",
      button: "flex items-center gap-2 py-2",
      text: "text-sm tracking-wide font-light",
      link: "text-sm tracking-wide font-light py-2",
      icon: "w-4 h-4 opacity-60 group-hover:opacity-100 transition-opacity"
    }
  }
};

const DesktopNav: React.FC<DesktopNavProps> = ({ items, variant = 'light' }) => {
  const textColorClass = SITE_CONFIG.colors.text[variant];
  const hoverColorClass = SITE_CONFIG.colors.hover[variant];

  return (
    <nav className={CONFIG.styles.nav}>
      {items.map((item, index) => (
        item.items ? (
          <div key={index} className={CONFIG.styles.item.wrapper}>
            <a 
              href={item.url} 
              className={`${CONFIG.styles.item.button} ${textColorClass} ${hoverColorClass}`}
            >
              <span className={CONFIG.styles.item.text}>{item.label}</span>
              <ChevronDown className={CONFIG.styles.item.icon} />
            </a>
            {item.label === 'Programs' ? (
              <MegaMenu item={item} variant={variant} />
            ) : (
              <DropdownMenu items={item.items} variant={variant} />
            )}
          </div>
        ) : (
          <a 
            key={index} 
            href={item.url} 
            className={`${CONFIG.styles.item.link} ${textColorClass} ${hoverColorClass}`}
          >
            {item.label}
          </a>
        )
      ))}
    </nav>
  );
};

export default DesktopNav;