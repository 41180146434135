import React from 'react';

interface HighlightItem {
  title: string;
  description: string;
}

interface HighlightsProps {
  title: string;
  items: HighlightItem[];
}

const Highlights: React.FC<HighlightsProps> = ({ title, items }) => {
  return (
    <section className="py-16 md:py-24">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-4xl mb-12">{title}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {items.map((item, index) => (
            <div key={index} className="space-y-4">
              <h3 className="font-serif text-xl">{item.title}</h3>
              <p className="text-[#1D3557]/70 leading-relaxed">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Highlights;