import React from 'react';

interface AboutSectionProps {
  title: string;
  description: string;
}

const CONFIG = {
  styles: {
    wrapper: "space-y-4",
    title: "font-serif text-3xl mb-4",
    description: "font-light text-[#1D3557]/80 leading-relaxed"
  }
};

const AboutSection: React.FC<AboutSectionProps> = ({ title, description }) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <h2 className={CONFIG.styles.title}>{title}</h2>
      <p className={CONFIG.styles.description}>{description}</p>
    </div>
  );
};

export default AboutSection;