import React from 'react';
import { ArrowRight, FileText, Users, Clock, CheckCircle } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import ApplicationSteps from '../components/application/ApplicationSteps';
import { VENUE_CONFIG } from '../config/venue';

const CONFIG = {
  header: {
    title: "Online Application",
    description: "Begin your journey with the Atlantic Music Festival. Our application process is designed to be straightforward while allowing you to showcase your artistic excellence.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  intro: {
    title: "Ready to Begin?",
    description: "Before starting your application, please review our guidelines to ensure a smooth process. You can save your progress and return at any time.",
    cta: {
      text: "Start Application",
      url: "/account/application"
    }
  },
  steps: [
    {
      icon: FileText,
      title: "Prepare Your Materials",
      description: "Gather your personal information, educational background, and prepare your portfolio materials according to your program's requirements.",
      items: [
        "Personal and contact information",
        "Educational history",
        "Performance/composition portfolio",
        "References' contact information",
        "Valid credit card for application fee"
      ]
    },
    {
      icon: Users,
      title: "References",
      description: "You'll need two professional references who can speak to your musical abilities and character. One can be your current teacher.",
      note: "References will receive an automated email to submit their recommendation after you complete your application."
    },
    {
      icon: Clock,
      title: "Timeline",
      description: "Our application process operates on a rolling basis. Early application offers several advantages:",
      items: [
        "Priority review",
        "Increased financial aid opportunities",
        "Better chance of preferred session placement",
        "Reduced application fees"
      ]
    }
  ],
  portfolio: {
    title: "Portfolio Requirements",
    description: "Requirements vary by program. Focus on presenting materials that best demonstrate your current level of musicianship.",
    note: "While adhering to requirements is important, we understand that exact matches may not always be possible. Present your strongest work."
  },
  process: {
    title: "What Happens Next",
    steps: [
      {
        title: "Reference Notifications",
        description: "Your references will receive an email with instructions to submit their recommendations."
      },
      {
        title: "Application Review",
        description: "Our faculty committee will carefully evaluate your application materials."
      },
      {
        title: "Decision Notification",
        description: "Results are typically sent within 3-6 weeks of your complete application submission."
      },
      {
        title: "Accept & Enroll",
        description: "If accepted, you'll have two weeks to confirm your attendance and complete registration requirements."
      }
    ]
  },
  support: {
    title: "Need Assistance?",
    description: "Our admissions team is here to help with any questions about the application process.",
    button: {
      text: "Contact Admissions",
      url: "/contact-us"
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    intro: {
      wrapper: "text-center max-w-3xl mx-auto mb-24",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    },
    steps: {
      grid: "grid grid-cols-1 md:grid-cols-3 gap-8",
      card: {
        wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg",
        icon: "w-12 h-12 text-[#E63946] mb-6",
        title: "font-serif text-2xl mb-4",
        description: "text-[#1D3557]/70 mb-6",
        list: "space-y-3",
        item: "flex items-center gap-3 text-[#1D3557]/70",
        bullet: "text-[#E63946]",
        note: "text-sm text-[#457B9D] mt-4 italic"
      }
    },
    process: {
      grid: "grid grid-cols-1 md:grid-cols-2 gap-8",
      step: {
        wrapper: "flex gap-4",
        icon: "w-8 h-8 text-[#E63946] flex-shrink-0",
        content: {
          title: "font-serif text-xl mb-2",
          description: "text-[#1D3557]/70"
        }
      }
    },
    support: {
      wrapper: "bg-[#1D3557] text-white rounded-lg p-12 text-center",
      title: "font-serif text-3xl mb-4",
      description: "text-white/80 mb-8",
      button: {
        wrapper: "inline-flex items-center gap-2 px-8 py-4 border-2 border-white relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-[#1D3557] transition-colors duration-500",
        background: "absolute inset-0 bg-white -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const OnlineApplication = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      {/* Introduction */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.intro.wrapper}>
            <h2 className={CONFIG.styles.intro.title}>{CONFIG.intro.title}</h2>
            <p className={CONFIG.styles.intro.description}>{CONFIG.intro.description}</p>
            <a href={CONFIG.intro.cta.url} className={CONFIG.styles.intro.button.wrapper}>
              <span className={CONFIG.styles.intro.button.text}>
                {CONFIG.intro.cta.text}
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className={CONFIG.styles.intro.button.background} />
            </a>
          </div>

          <div className={CONFIG.styles.steps.grid}>
            {CONFIG.steps.map((step, index) => {
              const Icon = step.icon;
              return (
                <div key={index} className={CONFIG.styles.steps.card.wrapper}>
                  <Icon className={CONFIG.styles.steps.card.icon} />
                  <h3 className={CONFIG.styles.steps.card.title}>{step.title}</h3>
                  <p className={CONFIG.styles.steps.card.description}>{step.description}</p>
                  {step.items && (
                    <div className={CONFIG.styles.steps.card.list}>
                      {step.items.map((item, idx) => (
                        <div key={idx} className={CONFIG.styles.steps.card.item}>
                          <span className={CONFIG.styles.steps.card.bullet}>•</span>
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                  {step.note && (
                    <p className={CONFIG.styles.steps.card.note}>{step.note}</p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Portfolio Requirements */}
      <section className={`${CONFIG.styles.section} bg-[#1D3557]/[0.02]`}>
        <div className={CONFIG.styles.container}>
          <div className="max-w-3xl mx-auto">
            <h2 className="font-serif text-4xl mb-6 text-center">{CONFIG.portfolio.title}</h2>
            <p className="text-lg text-[#1D3557]/80 mb-4 text-center">{CONFIG.portfolio.description}</p>
            <p className="text-[#457B9D] text-center italic">{CONFIG.portfolio.note}</p>
          </div>
        </div>
      </section>

      {/* Application Process */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <h2 className="font-serif text-4xl mb-12 text-center">{CONFIG.process.title}</h2>
          <div className={CONFIG.styles.process.grid}>
            {CONFIG.process.steps.map((step, index) => (
              <div key={index} className={CONFIG.styles.process.step.wrapper}>
                <CheckCircle className={CONFIG.styles.process.step.icon} />
                <div>
                  <h3 className={CONFIG.styles.process.step.content.title}>{step.title}</h3>
                  <p className={CONFIG.styles.process.step.content.description}>{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Support */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.support.wrapper}>
            <h2 className={CONFIG.styles.support.title}>{CONFIG.support.title}</h2>
            <p className={CONFIG.styles.support.description}>{CONFIG.support.description}</p>
            <a href={CONFIG.support.button.url} className={CONFIG.styles.support.button.wrapper}>
              <span className={CONFIG.styles.support.button.text}>
                {CONFIG.support.button.text}
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className={CONFIG.styles.support.button.background} />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default OnlineApplication;