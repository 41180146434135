import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Calendar, MapPin, Clock, ArrowLeft, Share2 } from 'lucide-react';
import toast from 'react-hot-toast';
import concertData from '../../data/concerts/concerts.json';
import { findConcertBySlug, arePerformersEqual } from './utils';

const ConcertDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const concert = findConcertBySlug(slug || '');

  if (!concert) {
    return (
      <div className="min-h-[calc(100vh-20rem)] flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-serif mb-4">Concert not found</h1>
          <button
            onClick={() => navigate('/concerts')}
            className="text-[#E63946] hover:underline"
          >
            Return to Concerts
          </button>
        </div>
      </div>
    );
  }

  const date = new Date(concert.time);
  const heroPath = `/images/concerts/hero/${concert.time}-hero.jpg`;

  const handleShare = async () => {
    try {
      const shareData = {
        title: concert.title,
        text: `Join us for ${concert.title} at the Atlantic Music Festival`,
        url: window.location.href
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else if (navigator.clipboard) {
        await navigator.clipboard.writeText(window.location.href);
        toast.success('Concert link copied to clipboard');
      } else {
        toast.error('Sharing is not supported on this device');
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        toast.error('Unable to share concert');
        console.error('Error sharing:', error);
      }
    }
  };

  const renderPerformers = (performers: any[], showPerformers: boolean) => {
    if (!showPerformers) return null;
    
    return (
      <div className="text-sm text-[#457B9D] text-center italic space-y-1">
        {performers.map((performer, idx) => (
          <div key={idx}>
            {performer.name}{performer.instrument ? ` (${performer.instrument})` : ''}
          </div>
        ))}
      </div>
    );
  };

  return (
    <main>
      {/* Hero Section */}
      <div className="relative min-h-[70vh] flex items-center">
        <div className="absolute inset-0">
          <img
            src={heroPath}
            alt={concert.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/70 to-transparent" />
        </div>
        
        <div className="relative max-w-screen-2xl mx-auto px-8 py-32">
          <button
            onClick={() => navigate('/concerts')}
            className="flex items-center gap-2 text-white/80 hover:text-white mb-8 transition-colors"
          >
            <ArrowLeft size={20} />
            <span>Back to Concerts</span>
          </button>

          <div className="max-w-3xl text-white backdrop-blur-sm bg-black/30 p-8 rounded-lg">
            <div className="flex items-center gap-4 mb-6">
              <div className="flex items-center gap-2 text-white/80">
                <Calendar size={20} />
                <span>{format(date, 'MMMM d, yyyy')}</span>
              </div>
              <button
                onClick={handleShare}
                className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
                title="Share"
              >
                <Share2 size={20} />
              </button>
            </div>

            <h1 className="font-serif text-5xl md:text-7xl mb-8">{concert.title}</h1>
            
            <div className="space-y-4 text-lg text-white/80">
              <div className="flex items-center gap-2">
                <Clock size={20} />
                <span>{format(date, 'h:mm a')}</span>
              </div>
              <div className="flex items-center gap-2">
                <MapPin size={20} />
                <span>{concert.venue}{concert.room ? ` • ${concert.room}` : ''}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Program Details */}
      {concert.works && concert.works.length > 0 && (
        <div className="max-w-screen-2xl mx-auto px-8 py-16">
          <div className="max-w-4xl mx-auto">
            <h2 className="font-serif text-3xl mb-16 text-center">Program</h2>
            <div className="space-y-20">
              {concert.works.map((work, index) => {
                // Check if the next work has the same performers
                const nextWork = concert.works[index + 1];
                const showPerformers = !nextWork || 
                  !arePerformersEqual(work.performers, nextWork.performers);

                return (
                  <div key={index} className="space-y-6">
                    {/* Desktop Layout */}
                    <div className="hidden md:grid md:grid-cols-[2fr,1fr] gap-8">
                      <div>
                        <h3 className="font-serif text-xl">{work.title}</h3>
                        {work.movements && (
                          <div className="mt-4 space-y-2">
                            {work.movements.map((movement, idx) => (
                              <p key={idx} className="text-[#1D3557]/80 italic pl-6">
                                {movement.title}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="text-[#1D3557]/60 font-serif">
                          {work.composer}
                          {work.composeryears && ` (${work.composeryears})`}
                        </p>
                      </div>
                    </div>

                    {/* Mobile Layout */}
                    <div className="md:hidden space-y-2">
                      <h3 className="font-serif text-xl">{work.title}</h3>
                      <p className="text-[#1D3557]/60">
                        {work.composer}
                        {work.composeryears && ` (${work.composeryears})`}
                      </p>
                      {work.movements && (
                        <div className="mt-4 space-y-2">
                          {work.movements.map((movement, idx) => (
                            <p key={idx} className="text-[#1D3557]/80 italic pl-4">
                              {movement.title}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>

                    {work.performers && renderPerformers(work.performers, showPerformers)}

                    {work.intermission && (
                      <div className="text-center py-8">
                        <div className="inline-block px-12 py-2 border-t border-b border-[#1D3557]/20 text-[#1D3557]/60 tracking-widest text-sm">
                          INTERMISSION
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default ConcertDetail;