import React from 'react';
import FormField from '../../FormField';
import { SURVEY_CONFIG } from '../config';

interface TeacherNameFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export const TeacherNameField: React.FC<TeacherNameFieldProps> = ({ 
  value = '',
  onChange 
}) => {
  return (
    <FormField 
      label={SURVEY_CONFIG.fields.teacherName.label}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={SURVEY_CONFIG.fields.teacherName.placeholder}
    >
      <p className={SURVEY_CONFIG.styles.field.description}>
        {SURVEY_CONFIG.fields.teacherName.description}
      </p>
    </FormField>
  );
};