import React from 'react';
import { ArrowRight } from 'lucide-react';

const RESOURCES = [
  {
    title: "Online Application",
    description: "Begin your journey with the Atlantic Music Festival. Our streamlined application process is designed to help you showcase your artistry.",
    url: "/the-institute/online-application"
  },
  {
    title: "Financial Aid",
    description: "Explore opportunities for financial support through fellowships, scholarships, and teaching assistantships.",
    url: "/the-institute/financial-aid"
  },
  {
    title: "Residency Guidelines",
    description: "Learn about life at Colby College during your stay, from housing and dining to practice facilities and performance venues.",
    url: "/the-institute/residency-guidelines"
  },
  {
    title: "Fees & Deadlines",
    description: "Important dates and financial information to help you plan your summer at AMF.",
    url: "/the-institute/fees-dates-and-deadlines"
  }
];

const ResourcesSection = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-8xl mb-24">Resources</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {RESOURCES.map((resource, index) => (
            <a 
              key={index}
              href={resource.url}
              className="group relative"
            >
              <div className="relative pl-12 py-12">
                {/* Vertical line */}
                <div className="absolute left-0 top-0 bottom-0 w-px bg-gradient-to-b from-[#1D3557]/20 to-transparent" />
                
                <h3 className="font-serif text-4xl mb-6">{resource.title}</h3>
                <p className="text-lg text-[#1D3557]/70 leading-relaxed mb-8">
                  {resource.description}
                </p>
                <span className="flex items-center gap-2 text-[#1D3557] group-hover:gap-4 transition-all duration-300">
                  <span className="text-sm">Learn More</span>
                  <ArrowRight size={16} />
                </span>
              </div>

              {/* Hover effect */}
              <div className="absolute -inset-8 bg-[#1D3557]/[0.02] opacity-0 group-hover:opacity-100 transition-opacity duration-700 -z-10" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ResourcesSection;