import React from 'react';
import { X } from 'lucide-react';
import FormField from '../FormField';
import { COUNTRIES } from '../../../config/countries';
import { US_STATES } from '../../../config/states';

interface Guardian {
  fullName: string;
  relationship: string;
  email: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

interface GuardianFieldsProps {
  guardians: Guardian[];
  onChange: (guardians: Guardian[]) => void;
}

const CONFIG = {
  maxGuardians: 2,
  relationships: [
    'Parent',
    'Legal Guardian',
    'Grandparent',
    'Other'
  ],
  styles: {
    section: "space-y-8",
    guardian: {
      wrapper: "relative bg-[#1D3557]/[0.02] p-6 rounded-lg",
      header: "flex justify-between items-center mb-6",
      title: "text-lg font-medium",
      remove: "text-[#1D3557]/40 hover:text-[#E63946] transition-colors",
      content: "space-y-6",
      grid: "grid grid-cols-1 md:grid-cols-2 gap-6"
    },
    button: {
      base: "relative overflow-hidden group",
      container: "block px-6 py-3 border-2 border-[#1D3557]",
      text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
      background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
    }
  }
};

const emptyGuardian: Guardian = {
  fullName: '',
  relationship: '',
  email: '',
  phone: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US'
  }
};

const GuardianFields: React.FC<GuardianFieldsProps> = ({ 
  guardians = [{ ...emptyGuardian }],
  onChange 
}) => {
  const handleAdd = () => {
    if (guardians.length < CONFIG.maxGuardians) {
      onChange([...guardians, { ...emptyGuardian }]);
    }
  };

  const handleRemove = (index: number) => {
    onChange(guardians.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, field: string, value: any) => {
    const updatedGuardians = guardians.map((guardian, i) => {
      if (i === index) {
        if (field.includes('.')) {
          const [parent, child] = field.split('.');
          return {
            ...guardian,
            [parent]: {
              ...guardian[parent as keyof Guardian],
              [child]: value
            }
          };
        }
        return { ...guardian, [field]: value };
      }
      return guardian;
    });
    onChange(updatedGuardians);
  };

  return (
    <div className={CONFIG.styles.section}>
      {guardians.map((guardian, index) => (
        <div key={index} className={CONFIG.styles.guardian.wrapper}>
          <div className={CONFIG.styles.guardian.header}>
            <h4 className={CONFIG.styles.guardian.title}>
              Guardian {index + 1}
            </h4>
            {index > 0 && (
              <button
                onClick={() => handleRemove(index)}
                className={CONFIG.styles.guardian.remove}
              >
                <X size={20} />
              </button>
            )}
          </div>

          <div className={CONFIG.styles.guardian.content}>
            <div className={CONFIG.styles.guardian.grid}>
              <FormField
                label="Full Name"
                type="text"
                value={guardian.fullName}
                onChange={(e) => handleChange(index, 'fullName', e.target.value)}
                required
              />

              <FormField
                label="Relationship"
                type="select"
                value={guardian.relationship}
                onChange={(e) => handleChange(index, 'relationship', e.target.value)}
                required
              >
                <option value="">Select relationship</option>
                {CONFIG.relationships.map((rel) => (
                  <option key={rel} value={rel}>{rel}</option>
                ))}
              </FormField>

              <FormField
                label="Email"
                type="email"
                value={guardian.email}
                onChange={(e) => handleChange(index, 'email', e.target.value)}
                required
              />

              <FormField
                label="Phone"
                type="tel"
                value={guardian.phone}
                onChange={(e) => handleChange(index, 'phone', e.target.value)}
                required
              />
            </div>

            <div className="space-y-6">
              <FormField
                label="Street Address"
                type="text"
                value={guardian.address.street}
                onChange={(e) => handleChange(index, 'address.street', e.target.value)}
                required
              />

              <div className={CONFIG.styles.guardian.grid}>
                <FormField
                  label="City"
                  type="text"
                  value={guardian.address.city}
                  onChange={(e) => handleChange(index, 'address.city', e.target.value)}
                  required
                />

                <FormField
                  label="Country"
                  type="select"
                  value={guardian.address.country}
                  onChange={(e) => handleChange(index, 'address.country', e.target.value)}
                  required
                >
                  <option value="">Select country</option>
                  {COUNTRIES.map(country => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </FormField>

                {guardian.address.country === 'US' ? (
                  <FormField
                    label="State"
                    type="select"
                    value={guardian.address.state}
                    onChange={(e) => handleChange(index, 'address.state', e.target.value)}
                    required
                  >
                    <option value="">Select state</option>
                    {US_STATES.map(state => (
                      <option key={state.code} value={state.code}>
                        {state.name}
                      </option>
                    ))}
                  </FormField>
                ) : (
                  <FormField
                    label="State/Province"
                    type="text"
                    value={guardian.address.state}
                    onChange={(e) => handleChange(index, 'address.state', e.target.value)}
                    placeholder="Enter state/province"
                  />
                )}

                <FormField
                  label="Postal Code"
                  type="text"
                  value={guardian.address.zipCode}
                  onChange={(e) => handleChange(index, 'address.zipCode', e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      {guardians.length < CONFIG.maxGuardians && (
        <button 
          onClick={handleAdd}
          className={CONFIG.styles.button.base}
        >
          <span className={CONFIG.styles.button.container}>
            <span className={CONFIG.styles.button.text}>
              Add Another Guardian
            </span>
          </span>
          <div className={CONFIG.styles.button.background} />
        </button>
      )}
    </div>
  );
};

export default GuardianFields;