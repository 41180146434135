import React from 'react';
import { ArrowRight, Home, Utensils, Dumbbell, MapPin, Car, Users } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import { VENUE_CONFIG } from '../config/venue';

const CONFIG = {
  header: {
    title: "Residency Guidelines",
    description: "Experience a vibrant artistic community in an inspiring setting.",
    imageId: "deb14307-c91f-433c-a5e4-2149ea689d55",
    variant: 'light' as const
  },
  intro: {
    title: "Your Festival Home",
    description: "Our residency program provides a supportive and enriching environment for artists to live, create, and perform. Participants enjoy world-class facilities and amenities that foster both artistic growth and personal well-being."
  },
  sections: [
    {
      icon: Home,
      title: "Housing",
      description: "Modern and comfortable residential accommodations",
      details: [
        "Air-conditioned rooms",
        "Double rooms standard, limited singles available",
        "Separate housing for minors with supervision",
        "Mandatory curfew for participants under 18",
        "Resident Assistants available for support",
        "Secure, WiFi-connected buildings"
      ],
      note: "Off-site housing arrangements must be made independently"
    },
    {
      icon: Utensils,
      title: "Dining",
      description: "All-inclusive meal plan at our dining facilities",
      details: VENUE_CONFIG.facilities.amenities.dining.features
    },
    {
      icon: Dumbbell,
      title: "Recreation",
      description: "Access to modern athletic facilities",
      details: VENUE_CONFIG.facilities.amenities.athletic.features
    },
    {
      icon: MapPin,
      title: "Performance & Practice Facilities",
      description: "Professional facilities for music-making",
      details: [
        "Performance halls with exceptional acoustics",
        "Well-maintained practice rooms",
        "Recording and performance technology",
        "Rehearsal spaces",
        "Common areas for collaboration"
      ]
    },
    {
      icon: Car,
      title: "Transportation",
      description: "Various options for getting around",
      details: [
        "Free on-site parking",
        "Local rideshare services",
        "Public transportation access",
        "Festival shuttle for select events",
        "Easy access to downtown Waterville"
      ]
    },
    {
      icon: Users,
      title: "Community Life",
      description: "Vibrant artistic community and cultural activities",
      details: [
        "Regular community events",
        "Maine International Film Festival access",
        "Social gatherings",
        "Outdoor activities",
        "Local cultural experiences"
      ]
    }
  ],
  policies: {
    title: "Important Policies",
    items: [
      {
        title: "Fellowship Attendance",
        content: "Fellows must commit to full attendance for the entire residency period. Late arrivals, early departures, or absences are not permitted."
      },
      {
        title: "Minor Housing",
        content: "Participants under 18 are housed in supervised dormitories with mandatory curfews and additional safety measures."
      },
      {
        title: "Guest Policy",
        content: "Housing is reserved for participants only. Visitors must arrange off-site accommodations independently."
      }
    ]
  },
  notice: {
    title: "Please Note",
    content: "Facility availability and scheduling are subject to change. Participants are encouraged to verify current offerings before arrival."
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    intro: {
      wrapper: "text-center max-w-3xl mx-auto mb-24",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 leading-relaxed"
    },
    grid: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8",
    card: {
      wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg group hover:bg-white transition-all duration-500",
      icon: "w-12 h-12 text-[#E63946] mb-6",
      title: "font-serif text-2xl mb-4",
      description: "text-[#1D3557]/70 mb-6",
      list: "space-y-3",
      item: "flex items-center gap-3 text-[#1D3557]/70",
      bullet: "text-[#E63946]"
    },
    policies: {
      wrapper: "bg-[#1D3557] text-white rounded-lg p-12",
      title: "font-serif text-3xl mb-8 text-center",
      grid: "grid grid-cols-1 md:grid-cols-3 gap-8",
      item: {
        title: "font-serif text-xl mb-4",
        content: "text-white/80 leading-relaxed"
      }
    },
    notice: {
      wrapper: "bg-[#E63946]/10 p-8 rounded-lg",
      title: "font-serif text-xl text-[#E63946] mb-4",
      content: "text-[#1D3557]/70 leading-relaxed"
    }
  }
};

const ResidencyGuidelines = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.intro.wrapper}>
            <h2 className={CONFIG.styles.intro.title}>{CONFIG.intro.title}</h2>
            <p className={CONFIG.styles.intro.description}>{CONFIG.intro.description}</p>
          </div>

          <div className={CONFIG.styles.grid}>
            {CONFIG.sections.map((section, index) => {
              const Icon = section.icon;
              return (
                <div key={index} className={CONFIG.styles.card.wrapper}>
                  <Icon className={CONFIG.styles.card.icon} />
                  <h3 className={CONFIG.styles.card.title}>{section.title}</h3>
                  <p className={CONFIG.styles.card.description}>{section.description}</p>
                  <div className={CONFIG.styles.card.list}>
                    {section.details.map((detail, idx) => (
                      <div key={idx} className={CONFIG.styles.card.item}>
                        <span className={CONFIG.styles.card.bullet}>•</span>
                        {detail}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.policies.wrapper}>
            <h2 className={CONFIG.styles.policies.title}>{CONFIG.policies.title}</h2>
            <div className={CONFIG.styles.policies.grid}>
              {CONFIG.policies.items.map((item, index) => (
                <div key={index}>
                  <h3 className={CONFIG.styles.policies.item.title}>{item.title}</h3>
                  <p className={CONFIG.styles.policies.item.content}>{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.notice.wrapper}>
            <h3 className={CONFIG.styles.notice.title}>{CONFIG.notice.title}</h3>
            <p className={CONFIG.styles.notice.content}>{CONFIG.notice.content}</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ResidencyGuidelines;