import { useState, useCallback } from 'react';
import { getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { getApplicationRef } from '../../utils/firebase';

export const useApplicationData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser } = useAuth();

  const getApplication = useCallback(async () => {
    if (!currentUser) return null;
    
    try {
      setLoading(true);
      setError(null);
      
      const applicationRef = getApplicationRef(currentUser.uid);
      const applicationSnap = await getDoc(applicationRef);
      
      if (applicationSnap.exists()) {
        return applicationSnap.data();
      }
      return null;
    } catch (err) {
      console.error('[useApplicationData] Error loading application:', err);
      setError(err as Error);
      return null;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    loading,
    error,
    getApplication
  };
};