import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getAssetUrl } from '../../utils/directus';

const FELLOWSHIPS = [
  {
    title: "Resident Artist Fellowship",
    description: "A transformative residency providing complete financial coverage and unparalleled artistic opportunities. Join a community of exceptional artists dedicated to advancing the future of classical music.",
    imageId: "052b19fc-d7c9-4974-919a-ec9aeac42f14",
    url: "/the-institute/programs/fellowship-program",
    stats: [
      { number: "40+", label: "Fellows per Season" },
      { number: "6", label: "Week Residency" },
      { number: "500+", label: "Alumni Worldwide" }
    ]
  },
  {
    title: "Contemporary Ensemble",
    description: "Join a pioneering ensemble dedicated to premiering new works and shaping the future of classical music. Collaborate with over 40 composers throughout the season in a dynamic and innovative environment.",
    imageId: "199c3681-7fda-4d9c-9746-f8178d65fb22",
    url: "/the-institute/programs/fellowship-program/contemporary-ensemble",
    stats: [
      { number: "40+", label: "World Premieres" },
      { number: "$800", label: "Stipend" },
      { number: "6", label: "Week Residency" }
    ]
  }
];

const FellowshipSection = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-8xl mb-24">Fellowship</h2>

        <div className="space-y-32">
          {FELLOWSHIPS.map((fellowship, index) => (
            <a 
              key={index}
              href={fellowship.url}
              className="group block"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
                <div className="relative">
                  <div className="aspect-[4/3] overflow-hidden">
                    <img
                      src={getAssetUrl(fellowship.imageId, {
                        width: 800,
                        height: 600,
                        quality: 85,
                        fit: 'cover'
                      })}
                      alt={fellowship.title}
                      className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-1000"
                    />
                  </div>
                  
                  {/* Decorative elements */}
                  <div className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2 border-[#1D3557]/10" />
                </div>

                <div>
                  <div className="relative pl-12">
                    {/* Vertical line */}
                    <div className="absolute left-0 top-0 bottom-0 w-px bg-gradient-to-b from-[#1D3557] to-transparent" />
                    
                    <h3 className="font-serif text-5xl mb-8">{fellowship.title}</h3>
                    <p className="text-xl text-[#1D3557]/70 leading-relaxed mb-12">
                      {fellowship.description}
                    </p>

                    <div className="grid grid-cols-3 gap-8 mb-12">
                      {fellowship.stats.map((stat, idx) => (
                        <div key={idx} className="text-[#1D3557]">
                          <div className="font-serif text-4xl mb-2">{stat.number}</div>
                          <div className="text-sm tracking-wide text-[#1D3557]/60">{stat.label}</div>
                        </div>
                      ))}
                    </div>

                    <span className="flex items-center gap-2 text-[#1D3557] group-hover:gap-4 transition-all duration-300">
                      <span className="text-sm">Learn More</span>
                      <ArrowRight size={16} />
                    </span>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FellowshipSection;