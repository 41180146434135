export const VENUE_CONFIG = {
  pending: true,
  location: {
    name: "Waterville",
    state: "ME",
    country: "United States"
  },
  facilities: {
    performance: [
      {
        name: "Concert Hall",
        description: "300-seat performance hall with customizable acoustics",
        type: "primary"
      },
      {
        name: "Recital Hall",
        description: "Intimate performance space with excellent acoustics",
        type: "secondary"
      }
    ],
    practice: {
      description: "Multiple practice rooms with quality pianos",
      availability: "Extended hours access"
    },
    amenities: {
      athletic: {
        description: "Modern athletic and recreation facilities",
        features: [
          "Swimming pool",
          "Fitness center",
          "Indoor track",
          "Sports courts"
        ]
      },
      dining: {
        description: "Full-service dining facilities",
        features: [
          "Three meals daily",
          "Diverse menu options",
          "Dietary accommodations",
          "Extended hours"
        ]
      },
      housing: {
        description: "Modern residence facilities",
        features: [
          "Air-conditioned rooms",
          "Common spaces",
          "Laundry facilities",
          "WiFi access"
        ]
      }
    }
  },
  transportation: {
    parking: "Free on-site parking",
    shuttle: "Festival shuttle service for select events",
    public: "Local bus service available",
    directions: {
      car: "Easily accessible from I-95",
      bus: "Regular service from Boston and Portland",
      air: "Portland International Jetport (PWM) is the nearest major airport"
    }
  }
} as const;