import { useState } from 'react';
import { setDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { getApplicationRef } from '../../utils/firebase';

export const useApplicationMutations = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser } = useAuth();

  const saveApplication = async (data: any) => {
    if (!currentUser) return false;
    
    try {
      setSaving(true);
      setError(null);
      
      const applicationRef = getApplicationRef(currentUser.uid);
      await setDoc(applicationRef, {
        ...data,
        userId: currentUser.uid,
        lastUpdated: new Date().toISOString()
      }, { merge: true }); // Use merge to preserve existing data
      
      return true;
    } catch (err) {
      console.error('[useApplicationMutations] Error saving application:', err);
      setError(err as Error);
      throw err; // Re-throw to allow handling by the caller
    } finally {
      setSaving(false);
    }
  };

  return {
    saving,
    error,
    saveApplication
  };
};