import React from 'react';
import FormField from '../FormField';
import { COUNTRIES } from '../../../config/countries';

interface ContactFieldsProps {
  formData: {
    phone?: string;
    gender?: string;
    otherGender?: string;
    dateOfBirth?: string;
    nationality?: string;
  };
  onChange: (data: Partial<ContactFieldsProps['formData']>) => void;
}

const ContactFields: React.FC<ContactFieldsProps> = ({ 
  formData = {
    phone: '',
    gender: '',
    otherGender: '',
    dateOfBirth: '',
    nationality: ''
  }, 
  onChange 
}) => {
  const handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    onChange({ [field]: e.target.value });
  };

  return (
    <div className="space-y-6">
      <FormField
        label="Phone Number"
        type="tel"
        value={formData.phone}
        onChange={handleInputChange('phone')}
        placeholder="Enter your phone number"
        required
      />

      <div className="space-y-4">
        <FormField
          label="Gender"
          type="select"
          value={formData.gender}
          onChange={handleInputChange('gender')}
          required
        >
          <option value="">Select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </FormField>

        {formData.gender === 'other' && (
          <FormField
            label="Please specify"
            type="text"
            value={formData.otherGender}
            onChange={handleInputChange('otherGender')}
            placeholder="Specify your gender"
            required
          />
        )}
      </div>

      <FormField
        label="Date of Birth"
        type="date"
        value={formData.dateOfBirth}
        onChange={handleInputChange('dateOfBirth')}
        required
      />

      <FormField
        label="Nationality"
        type="select"
        value={formData.nationality}
        onChange={handleInputChange('nationality')}
        required
      >
        <option value="">Select nationality</option>
        {COUNTRIES.map(country => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </FormField>
    </div>
  );
};

export default ContactFields;