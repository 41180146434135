import React from 'react';
import Header from '../components/Header';
import HeroSlider from '../components/HeroSlider';
import NextConcert from '../components/NextConcert';
import Programs from '../components/Programs';
import Impact from '../components/Impact';
import Gallery from '../components/Gallery';
import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <>
      <Header />
      <main className="mx-8 md:mx-12">
        <HeroSlider />
        <NextConcert />
        <Programs />
        <Impact />
        <Gallery />
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default HomePage;