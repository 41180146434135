import React from 'react';
import ReviewSection from '../ReviewSection';
import { formatAddress } from '../../../../utils/location';

const CONFIG = {
  title: "Personal Information",
  fields: [
    { key: 'fullName', label: 'Full Name' },
    { key: 'preferredName', label: 'Preferred Name' },
    { key: 'address', label: 'Address' },
    { key: 'phone', label: 'Phone' },
    { key: 'gender', label: 'Gender' },
    { key: 'dateOfBirth', label: 'Date of Birth' },
    { key: 'nationality', label: 'Nationality' }
  ]
};

interface PersonalInfoReviewProps {
  data: any;
}

const PersonalInfoReview: React.FC<PersonalInfoReviewProps> = ({ data }) => {
  if (!data) return null;

  const getFullName = () => {
    const { firstName, middleName, lastName } = data;
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  };

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'fullName':
        return getFullName();
      case 'address':
        return formatAddress(value);
      case 'dateOfBirth':
        return new Date(value).toLocaleDateString();
      default:
        return value;
    }
  };

  return (
    <ReviewSection
      title={CONFIG.title}
      fields={CONFIG.fields}
      data={data}
      formatValue={formatValue}
    />
  );
};

export default PersonalInfoReview;