import React from 'react';
import ReviewSection from '../ReviewSection';

const CONFIG = {
  title: "Program Selection",
  fields: [
    { key: 'type', label: 'Program Type' },
    { key: 'major', label: 'Major' },
    { key: 'specialization', label: 'Specialization' },
    { key: 'sessions', label: 'Selected Sessions' }
  ],
  programTypes: {
    'pre-college': 'Pre-College Program',
    'college': 'Collegiate & Professional Program'
  }
};

interface ProgramInfoReviewProps {
  data: any;
}

const ProgramInfoReview: React.FC<ProgramInfoReviewProps> = ({ data }) => {
  if (!data) return null;

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'type':
        return CONFIG.programTypes[value as keyof typeof CONFIG.programTypes] || value;
      case 'sessions':
        return Array.isArray(value) ? value.join(', ') : value;
      default:
        return value;
    }
  };

  return (
    <ReviewSection
      title={CONFIG.title}
      fields={CONFIG.fields}
      data={data}
      formatValue={formatValue}
    />
  );
};

export default ProgramInfoReview;