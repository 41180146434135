import React, { useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import PDFUpload from '../../shared/PDFUpload';
import { STORAGE_CONFIG } from '../../../config/storage';

interface ResumeUploadProps {
  resumeUrl?: string;
  onChange: (url: string, isUpload?: boolean) => void;
}

const ResumeUpload: React.FC<ResumeUploadProps> = ({ resumeUrl, onChange }) => {
  const { currentUser } = useAuth();

  const handleUploadComplete = useCallback((url: string) => {
    console.log('[ResumeUpload] Upload complete:', { url });
    // Pass true as second argument to ensure immediate state update
    onChange(url, true);
  }, [onChange]);

  if (!currentUser) {
    return null;
  }

  return (
    <PDFUpload
      fileUrl={resumeUrl}
      onChange={handleUploadComplete}
      title="Resume Upload"
      description="Upload your resume in PDF format (max. 5MB)"
      path="resume"
      maxSize={STORAGE_CONFIG.maxSizes.resume}
    />
  );
};

export default ResumeUpload;