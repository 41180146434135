import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { ChevronLeft, LogOut } from 'lucide-react';
import { getAssetUrl } from '../../utils/directus';

const CONFIG = {
  logo: {
    id: 'ef0b75cf-6898-4d70-8996-f0548af19f82',
    alt: 'Atlantic Music Festival'
  },
  styles: {
    header: "fixed top-0 left-0 right-0 z-50 bg-white border-b border-[#1D3557]/10",
    container: "max-w-4xl mx-auto px-6 h-20 flex items-center justify-between relative",
    logo: {
      wrapper: "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
      image: "h-16 w-auto"
    },
    button: {
      base: "flex items-center gap-2 text-sm text-[#1D3557]/60 hover:text-[#1D3557] transition-colors",
      icon: "w-4 h-4"
    },
    logout: {
      button: "flex items-center gap-2 text-sm text-[#1D3557]/60 hover:text-[#E63946] transition-colors",
      icon: "w-4 h-4"
    }
  }
};

const ApplicationHeader: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleBack = () => {
    navigate('/account/dashboard');
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/account');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <header className={CONFIG.styles.header}>
      <div className={CONFIG.styles.container}>
        <button onClick={handleBack} className={CONFIG.styles.button.base}>
          <ChevronLeft className={CONFIG.styles.button.icon} />
          Back to Dashboard
        </button>

        <div className={CONFIG.styles.logo.wrapper}>
          <img
            src={getAssetUrl(CONFIG.logo.id)}
            alt={CONFIG.logo.alt}
            className={CONFIG.styles.logo.image}
          />
        </div>

        <button onClick={handleLogout} className={CONFIG.styles.logout.button}>
          <LogOut className={CONFIG.styles.logout.icon} />
          Sign Out
        </button>
      </div>
    </header>
  );
};

export default ApplicationHeader;