import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PortfolioItem } from '../types';
import PortfolioItemCard from './PortfolioItemCard';

interface SortablePortfolioItemProps {
  item: PortfolioItem;
  onEdit: () => void;
  onRemove: () => void;
}

const SortablePortfolioItem: React.FC<SortablePortfolioItemProps> = ({
  item,
  onEdit,
  onRemove
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style}>
      <PortfolioItemCard
        item={item}
        onEdit={onEdit}
        onRemove={onRemove}
        dragHandleProps={{ ...attributes, ...listeners }}
      />
    </div>
  );
};

export default SortablePortfolioItem;