import React from 'react';

const CURRICULUM = [
  {
    title: "The Art of Gesture",
    content: `In conducting, every movement tells a story. Our curriculum delves deep into the language of gesture—from the subtle nuances of baton technique to the powerful expressions that can transform an ensemble's sound. Through intensive study and practice, you'll develop a conducting vocabulary that speaks directly to musicians' hearts and minds.`,
    style: {
      textSize: "text-2xl",
      alignment: "md:text-right",
      spacing: "mb-32"
    }
  },
  {
    title: "Score & Soul",
    content: `Beyond the technical mastery lies the art of interpretation. Learn to read between the lines of a score, understanding not just the notes but the composer's intentions, historical context, and emotional depth. Our approach combines rigorous analysis with artistic intuition, preparing you to bring scores to life with both authenticity and innovation.`,
    style: {
      textSize: "text-2xl",
      alignment: "md:text-left",
      spacing: "mb-32"
    }
  },
  {
    title: "Leadership Through Sound",
    content: `A conductor is more than a timekeeper—they are a leader, collaborator, and inspiration. Develop the psychological and interpersonal skills needed to guide an ensemble effectively. Master the art of non-verbal communication, rehearsal techniques, and the delicate balance of authority and collaboration that defines great conductors.`,
    style: {
      textSize: "text-2xl",
      alignment: "md:text-center",
      spacing: "mb-0"
    }
  }
];

const CurriculumSection = () => {
  return (
    <section className="py-32 relative overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        {CURRICULUM.map((section, index) => (
          <div 
            key={index}
            className={`max-w-3xl mx-auto ${section.style.spacing} ${
              index % 2 === 0 ? 'md:ml-auto' : 'md:mr-auto'
            }`}
          >
            <div className={`relative ${section.style.alignment}`}>
              <h3 className="font-serif text-7xl mb-8 leading-tight">
                {section.title}
              </h3>
              <p className={`${section.style.textSize} text-[#1D3557]/70 leading-relaxed`}>
                {section.content}
              </p>

              {/* Decorative elements */}
              <div 
                className="absolute -left-16 top-1/2 w-12 h-px"
                style={{
                  background: 'linear-gradient(to right, transparent, #E63946)'
                }}
              />
              <div 
                className="absolute -right-16 top-1/2 w-12 h-px"
                style={{
                  background: 'linear-gradient(to left, transparent, #457B9D)'
                }}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Dynamic background patterns */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(8)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px"
            style={{
              background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.02 - i * 0.002}), transparent)`,
              top: `${10 + i * 12}%`,
              transform: `rotate(${-15 + i * 4}deg) scaleX(${0.7 + i * 0.1})`,
              animation: 'gesture-flow 10s ease-in-out infinite',
              animationDelay: `${i * 0.7}s`
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default CurriculumSection;