export const SURVEY_CONFIG = {
  title: "Survey",
  description: "Help us understand how you discovered AMF",
  fields: {
    heardFrom: {
      label: "How did you hear about AMF?",
      placeholder: "Select an option",
      required: true
    },
    teacherName: {
      label: "Teacher's Name",
      placeholder: "Enter your teacher's name",
      description: "If you heard about us from a teacher"
    }
  },
  styles: {
    section: "space-y-8",
    card: "bg-white p-6 rounded-lg shadow-sm",
    title: "text-lg font-medium mb-6",
    description: "text-sm text-[#1D3557]/60 mb-8",
    field: {
      wrapper: "space-y-6",
      description: "text-sm text-[#1D3557]/60 mt-1"
    }
  }
} as const;