import React from 'react';
import { ArrowRight } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
import { getAssetUrl, IMAGE_SIZES } from '../utils/directus';
import { FESTIVAL_CONFIG } from '../config/festival';
import { VENUE_CONFIG } from '../config/venue';

const CONFIG = {
  hero: {
    imageId: "764a392b-69ec-45c4-ba32-552ba16d2bf9",
    title: "Welcome to the Festival",
    description: "Join us for a summer of extraordinary classical music in Maine.",
    dates: {
      festival: FESTIVAL_CONFIG.season.fullSession.dates,
      opening: `Saturday, ${FESTIVAL_CONFIG.season.concerts.opening.date} at ${FESTIVAL_CONFIG.season.concerts.opening.time}`,
      closing: `Saturday, ${FESTIVAL_CONFIG.season.concerts.closing.date} at ${FESTIVAL_CONFIG.season.concerts.closing.time}`
    }
  },
  experience: {
    title: "Concert Experience",
    description: "Immerse yourself in a season of extraordinary performances featuring world-class musicians.",
    moments: [
      {
        imageId: "2aba656e-eac9-4b62-858f-ec5c4b687c61",
        title: "Orchestra Concerts",
        description: "Experience the power and grandeur of full orchestral performances featuring symphonic masterworks"
      },
      {
        imageId: "4e29a401-1750-415d-b467-90047bbdf9f6",
        title: "Chamber Music",
        description: "Intimate performances showcasing the artistry of small ensembles in perfect acoustic settings"
      },
      {
        imageId: "fd93534e-db21-4442-8cec-b373e1af73f2",
        title: "Opera & Vocal Arts",
        description: "Stunning vocal performances featuring both beloved classics and contemporary works"
      }
    ]
  },
  directions: {
    title: "Getting Here",
    description: "Located in Waterville, Maine, the festival is easily accessible by car, plane, or bus.",
    options: [
      {
        title: "By Air",
        description: "Portland International Jetport (PWM) is the closest major airport, located 78 miles from the festival.",
        details: "Car rentals and festival shuttle services are available."
      },
      {
        title: "By Car",
        description: "From I-95, take Exit 127 (Waterville/Oakland).",
        details: "Free parking is available at all venues."
      },
      {
        title: "By Bus",
        description: "Concord Coach Lines provides daily service from Boston and Portland to Waterville.",
        details: "The bus stop is a short ride from the festival venue."
      }
    ]
  },
  newsletter: {
    title: "Stay Informed",
    description: "Subscribe to receive concert announcements and festival updates.",
    button: "Subscribe to Newsletter"
  },
  faq: {
    title: "Visitor Information",
    items: [
      {
        question: "Do I need tickets for the concerts?",
        answer: "All our concerts are free and open to the public. While tickets are not required, we recommend arriving early for popular performances to ensure seating."
      },
      {
        question: "What should I wear?",
        answer: "We maintain a welcoming, casual atmosphere. While some guests enjoy dressing up, casual attire is perfectly acceptable. We recommend comfortable clothing appropriate for the weather."
      },
      {
        question: "What types of concerts can I attend?",
        answer: "We offer a diverse range of classical music performances including full orchestra concerts, intimate chamber music recitals, and opera performances. Each concert features different repertoire and ensembles."
      },
      {
        question: "When should I arrive?",
        answer: "We recommend arriving 20-30 minutes before the concert start time. This allows time for parking, finding your seat, and reading the program notes."
      },
      {
        question: "Where can I stay during the festival?",
        answer: "Waterville offers various accommodation options, from boutique hotels to charming B&Bs. We maintain partnerships with several local establishments offering special rates for festival attendees."
      },
      {
        question: "Are the venues accessible?",
        answer: "Yes, all our performance venues are fully accessible. If you have specific requirements, please contact us in advance and we'll ensure your comfort."
      },
      {
        question: "Is there an intermission?",
        answer: "Most concerts include a 15-20 minute intermission. Our orchestra concerts typically run about two hours including intermission, while chamber music concerts are usually 90 minutes."
      },
      {
        question: "What else can I do in the area?",
        answer: "Waterville and the surrounding area offer numerous attractions, including museums, scenic hiking trails, and excellent dining options. Maine's coast is also just a short drive away."
      }
    ]
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    filters: {
      wrapper: "mb-16 flex flex-wrap gap-4",
      button: {
        base: "px-6 py-2 text-sm rounded-full transition-colors",
        active: "bg-[#1D3557] text-white",
        inactive: "bg-[#1D3557]/[0.05] hover:bg-[#1D3557]/10"
      }
    },
    search: {
      wrapper: "relative mb-16",
      input: "w-full px-12 py-4 bg-[#1D3557]/[0.02] rounded-lg text-[#1D3557] placeholder:text-[#1D3557]/40 focus:outline-none focus:ring-2 focus:ring-[#E63946]",
      icon: "absolute left-4 top-1/2 -translate-y-1/2 text-[#1D3557]/40"
    },
    grid: "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8",
    artist: {
      wrapper: "group cursor-pointer",
      imageWrapper: "aspect-[3/4] mb-4 overflow-hidden rounded-lg",
      image: "w-full h-full object-cover filter grayscale group-hover:grayscale-0 scale-105 group-hover:scale-100 transition-all duration-700",
      placeholder: "w-full h-full bg-[#1D3557]/5 flex items-center justify-center",
      placeholderText: "font-serif text-4xl text-[#1D3557]/20",
      name: "font-serif text-lg mb-1",
      affiliation: "text-sm text-[#457B9D]"
    }
  }
};

const Visitors = () => {
  return (
    <div>
      <Header variant="dark" />
      
      {/* Hero */}
      <div className="relative min-h-screen flex items-center">
        <div className="absolute inset-0">
          <img 
            src={getAssetUrl(CONFIG.hero.imageId, IMAGE_SIZES.hero)}
            alt="Atlantic Music Festival"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
          <div className="max-w-3xl">
            <h1 className="font-serif text-6xl md:text-8xl text-white mb-8">
              {CONFIG.hero.title}
            </h1>
            <p className="text-xl md:text-2xl text-white/80 max-w-2xl font-light leading-relaxed mb-12">
              {CONFIG.hero.description}
            </p>
            <div className="space-y-4 text-white/90">
              <p className="text-xl">Festival Dates: {CONFIG.hero.dates.festival}</p>
              <p>Opening Concert: {CONFIG.hero.dates.opening}</p>
              <p>Final Concert: {CONFIG.hero.dates.closing}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Concert Experience */}
      <section className="py-32">
        <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
          <h2 className="font-serif text-6xl mb-8">{CONFIG.experience.title}</h2>
          <p className="text-xl text-[#1D3557]/80 max-w-3xl mb-16">
            {CONFIG.experience.description}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {CONFIG.experience.moments.map((moment, index) => (
              <div key={index} className="group">
                <div className="aspect-[4/3] overflow-hidden mb-6">
                  <img
                    src={getAssetUrl(moment.imageId, IMAGE_SIZES.feature)}
                    alt={moment.title}
                    className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-[#1D3557] mix-blend-color opacity-10 group-hover:opacity-0 transition-opacity duration-700" />
                </div>
                <h3 className="font-serif text-2xl mb-4">{moment.title}</h3>
                <p className="text-[#1D3557]/70 leading-relaxed">
                  {moment.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Directions */}
      <section className="py-32 bg-[#1D3557]/[0.02]">
        <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
          <h2 className="font-serif text-6xl mb-8">{CONFIG.directions.title}</h2>
          <p className="text-xl text-[#1D3557]/80 max-w-3xl mb-16">
            {CONFIG.directions.description}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {CONFIG.directions.options.map((option, index) => (
              <div key={index} className="space-y-4">
                <h3 className="font-serif text-2xl">{option.title}</h3>
                <p className="text-[#1D3557]/70 leading-relaxed">
                  {option.description}
                </p>
                <p className="text-sm text-[#457B9D]">{option.details}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter */}
      <section className="py-32">
        <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="font-serif text-4xl mb-6">{CONFIG.newsletter.title}</h2>
            <p className="text-[#1D3557]/70 mb-8">{CONFIG.newsletter.description}</p>
            <a 
              href="#subscribe" 
              className="inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] group relative overflow-hidden"
            >
              <span className="relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500">
                {CONFIG.newsletter.button}
                <ArrowRight 
                  size={16}
                  className="inline-block ml-2 group-hover:translate-x-1 transition-transform duration-300"
                />
              </span>
              <div className="absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
            </a>
          </div>
        </div>
      </section>

      {/* FAQ */}
      <FAQ items={CONFIG.faq.items} />

      <Footer />
    </div>
  );
};

export default Visitors;