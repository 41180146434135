import React from 'react';

interface Step {
  id: string;
  label: string;
  shortLabel?: string; // Added for mobile/compressed view
}

interface StepIndicatorProps {
  steps: Step[];
  currentStep: number;
}

const CONFIG = {
  styles: {
    wrapper: "mb-12",
    steps: "relative flex justify-between",
    progress: {
      bar: "absolute top-5 left-0 h-[2px] bg-[#1D3557] transition-all duration-500",
      background: "absolute top-5 left-0 right-0 h-[2px] bg-[#1D3557]/10"
    },
    step: {
      wrapper: "relative flex flex-col items-center group",
      circle: {
        base: "w-3 h-3 rounded-full border-2 transition-colors duration-500",
        inactive: "border-[#1D3557]/20 bg-white",
        active: "border-[#1D3557] bg-[#1D3557]",
        complete: "border-[#1D3557] bg-[#1D3557]"
      },
      label: {
        base: "absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap text-xs transition-colors duration-500",
        inactive: "text-[#1D3557]/40",
        active: "text-[#1D3557] font-medium",
        complete: "text-[#1D3557]"
      }
    }
  }
};

const StepIndicator: React.FC<StepIndicatorProps> = ({ steps, currentStep }) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <div className={CONFIG.styles.steps}>
        <div className={CONFIG.styles.progress.background} />
        <div 
          className={CONFIG.styles.progress.bar}
          style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
        />

        {steps.map((step, index) => {
          const isActive = index === currentStep;
          const isComplete = index < currentStep;

          return (
            <div 
              key={step.id}
              className={CONFIG.styles.step.wrapper}
              style={{ width: index === 0 ? 0 : `${100 / (steps.length - 1)}%` }}
            >
              <div 
                className={`
                  ${CONFIG.styles.step.circle.base}
                  ${isActive ? CONFIG.styles.step.circle.active : 
                    isComplete ? CONFIG.styles.step.circle.complete :
                    CONFIG.styles.step.circle.inactive}
                `}
              />
              <span 
                className={`
                  ${CONFIG.styles.step.label.base}
                  ${isActive ? CONFIG.styles.step.label.active :
                    isComplete ? CONFIG.styles.step.label.complete :
                    CONFIG.styles.step.label.inactive}
                `}
              >
                {/* Use shortLabel for first two steps to prevent overlap */}
                {(index === 0 || index === 1) ? step.shortLabel || step.label : step.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepIndicator;