import React from 'react';
import StepIndicator from './StepIndicator';

const CONFIG = {
  steps: [
    { id: 'personal', label: 'Personal Information', shortLabel: 'Personal Info' },
    { id: 'program', label: 'Program Selection', shortLabel: 'Program' },
    { id: 'education', label: 'Education' },
    { id: 'portfolio', label: 'Portfolio' },
    { id: 'survey', label: 'Survey' },
    { id: 'review', label: 'Review' }
  ]
};

const ApplicationSteps = ({ currentStep }: { currentStep: number }) => {
  return (
    <StepIndicator 
      steps={CONFIG.steps}
      currentStep={currentStep}
    />
  );
};

export default ApplicationSteps;