import React from 'react';
import { Save, Check, AlertCircle } from 'lucide-react';

interface SaveStatusBarProps {
  isDirty: boolean;
  isSaving: boolean;
  lastSaved?: Date;
  onSave: () => void;
}

const CONFIG = {
  styles: {
    wrapper: "sticky top-20 z-40 bg-white border-b border-[#1D3557]/10 transition-all",
    container: "max-w-4xl mx-auto px-6 py-4 flex items-center justify-between",
    status: {
      wrapper: "flex items-center gap-3",
      icon: {
        base: "w-4 h-4",
        saving: "animate-spin text-[#1D3557]/40",
        saved: "text-green-500",
        unsaved: "text-[#E63946]"
      },
      text: "text-sm text-[#1D3557]/60",
      info: "text-xs text-[#1D3557]/40 ml-4"
    },
    button: {
      base: "flex items-center gap-2 px-4 py-2 border-2 border-[#1D3557] rounded transition-colors",
      active: "hover:bg-[#1D3557] hover:text-white",
      disabled: "opacity-50 cursor-not-allowed",
      icon: "w-4 h-4",
      text: "text-sm font-medium"
    }
  }
};

const SaveStatusBar: React.FC<SaveStatusBarProps> = ({
  isDirty,
  isSaving,
  lastSaved,
  onSave
}) => {
  const getStatusDisplay = () => {
    if (isSaving) {
      return {
        icon: (
          <svg className={`${CONFIG.styles.status.icon.base} ${CONFIG.styles.status.icon.saving}`} viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
        ),
        text: "Saving changes..."
      };
    }

    if (isDirty) {
      return {
        icon: <AlertCircle className={`${CONFIG.styles.status.icon.base} ${CONFIG.styles.status.icon.unsaved}`} />,
        text: "Unsaved changes"
      };
    }

    return {
      icon: <Check className={`${CONFIG.styles.status.icon.base} ${CONFIG.styles.status.icon.saved}`} />,
      text: `Last saved ${lastSaved?.toLocaleTimeString() || 'Never'}`
    };
  };

  const status = getStatusDisplay();

  return (
    <div className={CONFIG.styles.wrapper}>
      <div className={CONFIG.styles.container}>
        <div className="flex items-center">
          <div className={CONFIG.styles.status.wrapper}>
            {status.icon}
            <span className={CONFIG.styles.status.text}>{status.text}</span>
          </div>
          <span className={CONFIG.styles.status.info}>
            You can return to complete your application at any time
          </span>
        </div>

        <button
          onClick={onSave}
          disabled={isSaving || !isDirty}
          className={`${CONFIG.styles.button.base} ${
            isDirty && !isSaving ? CONFIG.styles.button.active : CONFIG.styles.button.disabled
          }`}
        >
          <Save className={CONFIG.styles.button.icon} />
          <span className={CONFIG.styles.button.text}>Save Draft</span>
        </button>
      </div>
    </div>
  );
};

export default SaveStatusBar;