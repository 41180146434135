import React from 'react';

interface NavigationButtonsProps {
  currentStep: number;
  onNext: () => void;
  onPrevious: () => void;
  saving: boolean;
}

const CONFIG = {
  styles: {
    wrapper: "flex justify-between pt-8",
    button: {
      base: "relative overflow-hidden group",
      container: "block px-6 py-3 border-2 border-[#1D3557]",
      text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
      background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500",
      disabled: "opacity-50 cursor-not-allowed"
    }
  }
};

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  currentStep,
  onNext,
  onPrevious,
  saving
}) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      {currentStep > 0 && (
        <button
          onClick={onPrevious}
          className={`${CONFIG.styles.button.base} ${saving ? CONFIG.styles.button.disabled : ''}`}
          disabled={saving}
        >
          <span className={CONFIG.styles.button.container}>
            <span className={CONFIG.styles.button.text}>Previous</span>
          </span>
          <div className={CONFIG.styles.button.background} />
        </button>
      )}
      
      <button
        onClick={onNext}
        className={`${CONFIG.styles.button.base} ${saving ? CONFIG.styles.button.disabled : ''}`}
        disabled={saving}
      >
        <span className={CONFIG.styles.button.container}>
          <span className={CONFIG.styles.button.text}>
            {currentStep === 5 ? 'Submit' : 'Next'}
          </span>
        </span>
        <div className={CONFIG.styles.button.background} />
      </button>
    </div>
  );
};

export default NavigationButtons;