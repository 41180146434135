import React from 'react';
import FormField from '../FormField';

interface NameFieldsProps {
  formData: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    hasPreferredName?: boolean;
    preferredName?: string;
  };
  onChange: (data: Partial<NameFieldsProps['formData']>) => void;
}

const NameFields: React.FC<NameFieldsProps> = ({ formData, onChange }) => {
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    hasPreferredName = false,
    preferredName = ''
  } = formData;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FormField
          label="First Name"
          required
          type="text"
          value={firstName}
          onChange={(e) => onChange({ firstName: e.target.value })}
        />
        <FormField
          label="Middle Name"
          type="text"
          value={middleName}
          onChange={(e) => onChange({ middleName: e.target.value })}
        />
        <FormField
          label="Last Name"
          required
          type="text"
          value={lastName}
          onChange={(e) => onChange({ lastName: e.target.value })}
        />
      </div>

      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="hasPreferredName"
            checked={hasPreferredName}
            onChange={(e) => onChange({ hasPreferredName: e.target.checked })}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <label htmlFor="hasPreferredName" className="text-sm text-gray-700">
            I have a preferred name
          </label>
        </div>

        {hasPreferredName && (
          <FormField
            label="Preferred Name"
            type="text"
            value={preferredName}
            onChange={(e) => onChange({ preferredName: e.target.value })}
          />
        )}
      </div>
    </div>
  );
};

export default NameFields;