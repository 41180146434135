import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const MissionSection = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
          <div className="space-y-8">
            <h2 className="font-serif text-7xl relative">
              Our Legacy of Excellence
              <div className="absolute -left-8 -bottom-8 w-24 h-24 border-l-2 border-b-2 border-[#E63946] opacity-20" />
            </h2>
            
            <div className="prose prose-lg prose-p:text-[#1D3557]/70">
              <p className="text-2xl leading-relaxed">
                From its inception, the Atlantic Music Festival has established a unique tradition of nurturing young emerging artists through our fellowship program.
              </p>
              <p className="text-xl">
                The opportunity for young musicians to study, create and perform in an environment free from financial burden unleashed results far above and beyond what we ever expected.
              </p>
              <p className="text-xl">
                In the last decade, over 500 musicians have received fellowships and now occupy key positions within major orchestras, ensembles, and academic institutions.
              </p>
            </div>
          </div>

          <div className="relative">
            <img
              src={getAssetUrl('6ecd3bef-2a70-4638-bbc1-9a737218ff89', {
                width: 800,
                height: 1000,
                quality: 85,
                fit: 'cover'
              })}
              alt="AMF Fellows"
              className="w-full rounded-lg shadow-xl"
            />
            <div className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2 border-[#457B9D] opacity-10" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;