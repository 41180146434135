import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('30b3cbc9-4f3d-48bf-bbfe-a202f118912c', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="Piano Institute"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/95 via-black/80 to-transparent" />
        
        {/* Dynamic piano key animation */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {[...Array(88)].map((_, i) => {
            const baseDelay = i * 0.02;
            const duration = 3 + Math.random() * 2;
            
            return (
              <div
                key={i}
                className="absolute w-[1px] bg-white/10"
                style={{
                  height: '200%',
                  left: `${(i / 88) * 100}%`,
                  top: '-50%',
                  transform: `
                    rotate(${45 + (i / 88) * 10}deg)
                    scaleY(${0.5 + Math.random() * 0.5})
                  `,
                  opacity: Math.random() * 0.3,
                  animation: `pianoKey ${duration}s ease-in-out ${baseDelay}s infinite`
                }}
              />
            );
          })}
        </div>
      </div>
      
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-5xl">
          <div className="mb-16">
            <div className="overflow-hidden">
              <div className="transform hover:scale-105 transition-transform duration-700">
                <h1 className="font-serif text-[10rem] md:text-[12rem] leading-none text-white mb-4">
                  Piano
                </h1>
                <div className="flex flex-wrap items-baseline gap-6">
                  <span className="font-serif text-[6rem] md:text-[7rem] leading-none text-white">Institute</span>
                  <span className="font-serif text-[4rem] md:text-[5rem] leading-none text-[#E63946]">&</span>
                  <span className="font-serif text-[6rem] md:text-[7rem] leading-none text-white">Seminar</span>
                </div>
              </div>
            </div>
          </div>

          <div className="relative max-w-2xl">
            <div className="absolute -left-8 top-0 bottom-0 w-1 bg-gradient-to-b from-[#E63946] to-transparent" />
            <p className="text-2xl md:text-3xl text-white/80 font-light leading-relaxed pl-12">
              Join a community of pianists on a journey to unlock a world of musical expression, creativity, and growth.
            </p>
          </div>
        </div>
      </div>

      {/* Scroll indicator */}
      <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
        <div className="w-[1px] h-24 bg-gradient-to-b from-white/0 via-white/50 to-white/0 animate-pulse" />
      </div>

      <style>
        {`
          @keyframes pianoKey {
            0%, 100% { 
              opacity: 0.1;
              transform: rotate(${45 + Math.random() * 10}deg) scaleY(0.5);
            }
            50% { 
              opacity: 0.3;
              transform: rotate(${50 + Math.random() * 10}deg) scaleY(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default HeroSection;