import { collection, doc } from 'firebase/firestore';
import { db, FIREBASE_PATHS } from '../config/firebase';

// Get reference to user's application document
export const getApplicationRef = (userId: string) => {
  return doc(db, FIREBASE_PATHS.applications, userId);
};

// Get collection reference for all applications
export const getApplicationsRef = () => {
  return collection(db, FIREBASE_PATHS.applications);
};