import React from 'react';
import ReviewSection from '../ReviewSection';
import ReviewField from '../ReviewField';

interface EducationReviewProps {
  data?: {
    academic?: Array<{
      currentlyEnrolled: boolean;
      yearsAttended: string;
      schoolName: string;
      degree: string;
    }>;
    honorsAchievements?: Array<{
      year: string;
      description: string;
    }>;
    resumeUrl?: string;
  };
}

const CONFIG = {
  title: "Education",
  academic: {
    title: "Academic Background",
    fields: [
      { key: 'schoolName', label: 'School' },
      { key: 'yearsAttended', label: 'Years' },
      { key: 'degree', label: 'Degree' },
      { key: 'currentlyEnrolled', label: 'Currently Enrolled' }
    ]
  },
  honors: {
    title: "Honors & Achievements",
    empty: "No honors or achievements listed"
  },
  resume: {
    title: "Resume",
    noResume: "No resume uploaded",
    viewLabel: "View Resume"
  },
  styles: {
    section: {
      wrapper: "space-y-8",
      title: "font-medium mb-4"
    },
    list: {
      wrapper: "space-y-6",
      item: "border-b border-[#1D3557]/10 last:border-0 pb-4 last:pb-0"
    },
    honors: {
      list: "list-disc list-inside space-y-2",
      item: "text-sm"
    },
    resume: {
      link: "text-sm text-[#E63946] hover:text-[#E63946]/80 transition-colors"
    },
    empty: "text-sm text-[#1D3557]/60"
  }
};

export const EducationReview: React.FC<EducationReviewProps> = ({ data = {} }) => {
  const { academic = [], honorsAchievements = [], resumeUrl } = data;

  return (
    <ReviewSection title={CONFIG.title}>
      {/* Academic Background */}
      <div className={CONFIG.styles.section.wrapper}>
        <h4 className={CONFIG.styles.section.title}>{CONFIG.academic.title}</h4>
        <div className={CONFIG.styles.list.wrapper}>
          {academic.map((school, index) => (
            <div key={index} className={CONFIG.styles.list.item}>
              {CONFIG.academic.fields.map((field) => (
                <ReviewField
                  key={field.key}
                  label={field.label}
                  value={
                    field.key === 'currentlyEnrolled'
                      ? school[field.key] ? 'Yes' : 'No'
                      : school[field.key as keyof typeof school]
                  }
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Honors & Achievements */}
      <div className={CONFIG.styles.section.wrapper}>
        <h4 className={CONFIG.styles.section.title}>{CONFIG.honors.title}</h4>
        {honorsAchievements.length > 0 ? (
          <ul className={CONFIG.styles.honors.list}>
            {honorsAchievements.map((honor, index) => (
              <li key={index} className={CONFIG.styles.honors.item}>
                {honor.year}: {honor.description}
              </li>
            ))}
          </ul>
        ) : (
          <p className={CONFIG.styles.empty}>{CONFIG.honors.empty}</p>
        )}
      </div>

      {/* Resume */}
      <div className={CONFIG.styles.section.wrapper}>
        <h4 className={CONFIG.styles.section.title}>{CONFIG.resume.title}</h4>
        {resumeUrl ? (
          <a
            href={resumeUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={CONFIG.styles.resume.link}
          >
            {CONFIG.resume.viewLabel}
          </a>
        ) : (
          <p className={CONFIG.styles.empty}>{CONFIG.resume.noResume}</p>
        )}
      </div>
    </ReviewSection>
  );
};

export default EducationReview;