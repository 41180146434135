import React, { useState, useEffect } from 'react';
import { X, Loader2 } from 'lucide-react';
import { deleteFile, verifyFile, FileUploadMetadata } from '../../utils/storage';
import FileUploader from './FileUploader';
import { useAuth } from '../../contexts/AuthContext';

interface PDFUploadProps {
  fileUrl?: string;
  onChange: (url: string, isUpload?: boolean) => void;
  title?: string;
  description?: string;
  maxSize?: number;
  path: string;
}

const CONFIG = {
  styles: {
    wrapper: "space-y-6",
    header: {
      title: "text-lg font-medium",
      description: "text-sm text-[#1D3557]/60 mb-6"
    },
    error: "p-4 bg-red-50 text-red-600 rounded-lg text-sm",
    preview: {
      wrapper: "flex items-center justify-between p-4 bg-[#1D3557]/[0.02] rounded-lg",
      info: {
        wrapper: "space-y-1",
        title: "text-sm font-medium",
        filename: "text-xs text-[#1D3557]/60"
      },
      actions: {
        wrapper: "flex items-center gap-4",
        view: "text-sm text-[#E63946] hover:text-[#E63946]/80 transition-colors",
        remove: "text-[#1D3557]/40 hover:text-[#E63946] transition-colors"
      }
    }
  }
};

const PDFUpload: React.FC<PDFUploadProps> = ({ 
  fileUrl,
  onChange,
  title = "Upload PDF",
  description = "Upload your document in PDF format",
  maxSize,
  path
}) => {
  const [error, setError] = useState<string>('');
  const [fileMetadata, setFileMetadata] = useState<FileUploadMetadata | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    const verifyExistingFile = async () => {
      if (!fileUrl) {
        setFileMetadata(null);
        return;
      }

      try {
        setIsLoading(true);
        setError('');
        const metadata = await verifyFile(fileUrl);
        if (metadata) {
          setFileMetadata(metadata);
        } else {
          setError('Failed to verify file');
          onChange('', true);
        }
      } catch (err) {
        console.error('[PDFUpload] Verification error:', err);
        setError('Failed to verify file. Please try uploading again.');
        onChange('', true);
      } finally {
        setIsLoading(false);
      }
    };

    verifyExistingFile();
  }, [fileUrl, onChange]);

  const handleUploadComplete = (url: string, metadata: FileUploadMetadata) => {
    setFileMetadata(metadata);
    onChange(url, true);
    setError('');
    setIsLoading(false);
  };

  const handleUploadError = (errorMessage: string) => {
    console.error('[PDFUpload] Upload error:', errorMessage);
    setError(errorMessage);
    setIsLoading(false);
  };

  const handleRemove = async () => {
    if (!fileUrl) return;

    try {
      setIsLoading(true);
      setError('');
      await deleteFile(fileUrl);
      onChange('', true);
      setFileMetadata(null);
    } catch (error: any) {
      console.error('[PDFUpload] Failed to remove file:', error);
      setError(`Failed to remove file: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadStart = () => {
    setIsLoading(true);
    setError('');
  };

  if (!currentUser?.uid) {
    return (
      <div className={CONFIG.styles.error}>
        Authentication required to upload files
      </div>
    );
  }

  return (
    <div className={CONFIG.styles.wrapper}>
      <div>
        <h4 className={CONFIG.styles.header.title}>{title}</h4>
        <p className={CONFIG.styles.header.description}>{description}</p>
      </div>

      {error && (
        <div className={CONFIG.styles.error}>{error}</div>
      )}

      {(!fileUrl || !fileMetadata) ? (
        <FileUploader
          accept={{ 'application/pdf': ['.pdf'] }}
          maxSize={maxSize}
          path={path}
          onUploadComplete={handleUploadComplete}
          onUploadStart={handleUploadStart}
          onError={handleUploadError}
          disabled={isLoading}
        >
          {isLoading && (
            <div className="flex items-center justify-center gap-2">
              <Loader2 className="w-4 h-4 animate-spin" />
              <span>Processing...</span>
            </div>
          )}
        </FileUploader>
      ) : (
        <div className={CONFIG.styles.preview.wrapper}>
          <div className={CONFIG.styles.preview.info.wrapper}>
            <span className={CONFIG.styles.preview.info.title}>Current File</span>
            <p className={CONFIG.styles.preview.info.filename}>
              {fileMetadata.originalName}
            </p>
          </div>
          <div className={CONFIG.styles.preview.actions.wrapper}>
            <a 
              href={fileUrl} 
              target="_blank"
              rel="noopener noreferrer"
              className={CONFIG.styles.preview.actions.view}
            >
              View PDF
            </a>
            <button
              onClick={handleRemove}
              className={CONFIG.styles.preview.actions.remove}
              title="Remove file"
              disabled={isLoading}
            >
              <X size={20} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFUpload;