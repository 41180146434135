import React from 'react';
import { ArrowRight } from 'lucide-react';

interface ApplicationProps {
  title: string;
  description: string;
  requirements: string[];
}

const Application: React.FC<ApplicationProps> = ({ 
  title, 
  description, 
  requirements
}) => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          <div>
            <h2 className="font-serif text-6xl mb-8">{title}</h2>
            <p className="text-xl text-[#1D3557]/80 mb-12 leading-relaxed">
              {description}
            </p>
            <div className="mb-12">
              <h3 className="font-serif text-2xl mb-6">Application Requirements</h3>
              <ul className="space-y-4">
                {requirements.map((req, index) => (
                  <li 
                    key={index} 
                    className="flex items-start gap-4 text-[#1D3557]/70 leading-relaxed text-lg"
                  >
                    <span className="text-[#E63946] mt-2">•</span>
                    <span>{req}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="bg-[#1D3557]/[0.02] p-16">
            <h3 className="font-serif text-2xl mb-8">Important Links</h3>
            <div className="space-y-6">
              <a 
                href="/the-institute/fees-dates-and-deadlines"
                className="block text-lg text-[#1D3557]/70 hover:text-[#E63946] transition-colors"
              >
                Fees, Dates, and Deadlines
              </a>
              <a 
                href="/the-institute/online-application"
                className="block text-lg text-[#1D3557]/70 hover:text-[#E63946] transition-colors"
              >
                Online Application
              </a>
              <a 
                href="/the-institute/residency-guidelines"
                className="block text-lg text-[#1D3557]/70 hover:text-[#E63946] transition-colors"
              >
                Residency Guidelines
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Application;