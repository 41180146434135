import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/piano/HeroSection';
import ArtisticJourney from '../components/piano/ArtisticJourney';
import SeasonOverview from '../components/piano/SeasonOverview';
import AuditionSection from '../components/instrumental/AuditionSection';
import FacultySection from '../components/programs/FacultySection';
import FAQ from '../components/FAQ';
import programData from '../data/programs/pis.json';

const PianoProgram = () => {
  // Process faculty data
  const faculty = programData.programs?.[0]?.faculty?.map(f => ({
    name: f.members_id.name,
    profileImage: f.members_id.profile_image?.id,
    biography: f.members_id.biography,
    affiliation: f.members_id.affiliation,
    instruments: f.members_id.instruments?.map(i => ({
      value: i.instruments_id.value,
      label: i.instruments_id.label
    })) || []
  })) || [];

  // Process and sort audition requirements
  const requirements = programData.programs?.[0]?.audition_requirements
    ?.map(req => ({
      requirement: req.audition_requirements_id.requirement,
      sort: req.audition_requirements_id.sort || 0
    }))
    .sort((a, b) => a.sort - b.sort)
    .map(req => req.requirement) || [];

  return (
    <div>
      <Header variant="dark" />
      
      <HeroSection />
      <SeasonOverview />
      <ArtisticJourney />
      
      <FacultySection
        faculty={faculty}
        title="Distinguished Faculty"
        description="Learn from and work alongside world-renowned pianists who are shaping the future of classical music."
      />
      
      <AuditionSection requirements={requirements} />

      <FAQ
        items={[
          {
            question: "What is the Piano Institute & Seminar?",
            answer: "The Piano Institute & Seminar is a comprehensive six-week summer program designed for conservatory-level pianists. Through masterclasses, private lessons, and performance opportunities, participants develop their artistry under the guidance of distinguished faculty."
          },
          {
            question: "Can I attend for just one session?",
            answer: "Yes, participants can choose to attend either one three-week session or the full six-week program. Each session offers a complete curriculum while the full program provides a more immersive experience."
          },
          {
            question: "What performance opportunities are available?",
            answer: "Participants have numerous opportunities for solo recitals, chamber music collaborations, and concerto performances. All performances are professionally recorded and provided to participants for their portfolios."
          },
          {
            question: "Are there collaborative opportunities with other programs?",
            answer: "Yes, pianists can participate in cross-discipline seminars with the Instrumental Program, Composition Program, Vocal Arts Institute, and MUGIC Lab, offering a well-rounded musical education."
          },
          {
            question: "What is the typical daily schedule?",
            answer: "The daily schedule includes private lessons, practice time, masterclasses, workshops, and performance opportunities. Evenings often feature faculty and student recitals."
          },
          {
            question: "Are practice facilities available?",
            answer: "Yes, participants have access to practice rooms with quality pianos throughout their stay. Practice facilities are available extended hours to accommodate various schedules."
          },
          {
            question: "What is the audition process?",
            answer: "Applicants submit recordings of required repertoire through our online application system. Our faculty committee reviews all applications holistically, considering both technical proficiency and artistic potential."
          },
          {
            question: "Are scholarships available?",
            answer: "Yes, we offer merit-based scholarships and need-based financial aid. Early application is recommended for maximum consideration."
          },
          {
            question: "What is the housing situation?",
            answer: "Participants are housed in comfortable dormitories on the Colby College campus. All rooms are air-conditioned and within walking distance to practice facilities and performance venues."
          },
          {
            question: "Is there an age requirement?",
            answer: "The Piano Institute & Seminar is designed for pianists 18 and older. For younger students, please see our Pre-College Program."
          }
        ]}
      />

      <Footer />
    </div>
  );
};

export default PianoProgram;