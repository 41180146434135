import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import PrivateRoute from './components/PrivateRoute';
import Announcements from './components/Announcements';
import HomePage from './pages/HomePage';
import TheInstitute from './pages/TheInstitute';
import AboutAMF from './pages/AboutAMF';
import Account from './pages/Account';
import Dashboard from './pages/Dashboard';
import PreCollege from './pages/PreCollege';
import CompositionProgram from './pages/CompositionProgram';
import ConductingProgram from './pages/ConductingProgram';
import InstrumentalProgram from './pages/InstrumentalProgram';
import PianoProgram from './pages/PianoProgram';
import VocalInstitute from './pages/VocalInstitute';
import Concerts from './pages/Concerts';
import ApplicationForm from './components/application/ApplicationForm';
import AudioPlayer from './components/AudioPlayer';
import Visitors from './pages/Visitors';
import Artists from './pages/Artists';
import Diversity from './pages/Diversity';
import Welcome from './pages/Welcome';
import Legal from './pages/Legal';
import Contact from './pages/Contact';
import GetInvolved from './pages/GetInvolved';
import Jobs from './pages/Jobs';
import Internship from './pages/Internship';
import Volunteer from './pages/Volunteer';
import OnlineApplication from './pages/OnlineApplication';
import FinancialAid from './pages/FinancialAid';
import ResidencyGuidelines from './pages/ResidencyGuidelines';
import FeesAndDeadlines from './pages/FeesAndDeadlines';
import FellowshipProgram from './pages/FellowshipProgram';
import ContemporaryEnsemble from './pages/ContemporaryEnsemble';

const App: React.FC = () => {
  return (
    <div className="min-h-screen bg-white">
      <Announcements />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/the-institute" element={<TheInstitute />} />
        <Route path="/the-institute/pre-college" element={<PreCollege />} />
        <Route path="/the-institute/programs/composition-program" element={<CompositionProgram />} />
        <Route path="/the-institute/programs/conducting-program" element={<ConductingProgram />} />
        <Route path="/the-institute/programs/instrumental-program" element={<InstrumentalProgram />} />
        <Route path="/the-institute/programs/piano-institute-and-seminar" element={<PianoProgram />} />
        <Route path="/the-institute/programs/vocal-institute" element={<VocalInstitute />} />
        <Route path="/concerts/*" element={<Concerts />} />
        <Route path="/the-festival/about-amf" element={<AboutAMF />} />
        <Route path="/the-festival/artists" element={<Artists />} />
        <Route path="/the-festival/diversity" element={<Diversity />} />
        <Route path="/the-festival/welcome" element={<Welcome />} />
        <Route path="/get-involved" element={<GetInvolved />} />
        <Route path="/get-involved/jobs" element={<Jobs />} />
        <Route path="/get-involved/jobs/internship" element={<Internship />} />
        <Route path="/get-involved/volunteer" element={<Volunteer />} />
        <Route path="/legal/terms-use-privacy-policy" element={<Legal />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/the-institute/online-application" element={<OnlineApplication />} />
        <Route path="/the-institute/financial-aid" element={<FinancialAid />} />
        <Route path="/the-institute/residency-guidelines" element={<ResidencyGuidelines />} />
        <Route path="/the-institute/fees-dates-and-deadlines" element={<FeesAndDeadlines />} />
        <Route path="/account" element={<Account />} />
        <Route path="/visitors" element={<Visitors />} />
        <Route path="/the-institute/programs/fellowship-program" element={<FellowshipProgram />} />
        <Route path="/the-institute/programs/fellowship-program/contemporary-ensemble" element={<ContemporaryEnsemble />} />
        <Route 
          path="/account/dashboard" 
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/account/application" 
          element={
            <PrivateRoute>
              <ApplicationForm />
            </PrivateRoute>
          } 
        />
      </Routes>
      <AudioPlayer />
      <Toaster 
        position="bottom-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#1D3557',
            color: '#fff',
            borderRadius: '8px',
            padding: '16px 24px'
          }
        }}
      />
    </div>
  );
};

export default App;