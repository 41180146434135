import React from 'react';
import { PortfolioItem } from './types';
import { PORTFOLIO_CONFIG } from './config';
import PortfolioList from './components/PortfolioList';
import PortfolioUploader from './components/PortfolioUploader';

interface PortfolioProps {
  formData: {
    items?: PortfolioItem[];
  };
  onChange: (data: Partial<PortfolioProps['formData']>, isUpload?: boolean) => void;
}

const Portfolio: React.FC<PortfolioProps> = ({ formData = { items: [] }, onChange }) => {
  const handleItemsChange = (items: PortfolioItem[], isUpload: boolean = false) => {
    onChange({ items }, isUpload);
  };

  const handleEdit = (id: string, updates: Partial<PortfolioItem>) => {
    const updatedItems = formData.items?.map(item =>
      item.id === id ? { ...item, ...updates } : item
    ) || [];
    handleItemsChange(updatedItems);
  };

  const handleRemove = (id: string) => {
    const updatedItems = formData.items?.filter(item => item.id !== id) || [];
    handleItemsChange(updatedItems);
  };

  return (
    <div className={PORTFOLIO_CONFIG.styles.wrapper}>
      <h3 className={PORTFOLIO_CONFIG.styles.title}>{PORTFOLIO_CONFIG.title}</h3>
      <p className={PORTFOLIO_CONFIG.styles.description}>{PORTFOLIO_CONFIG.description}</p>

      <div className={PORTFOLIO_CONFIG.styles.content}>
        <PortfolioUploader
          items={formData.items || []}
          onChange={handleItemsChange}
        />

        <PortfolioList
          items={formData.items || []}
          onReorder={handleItemsChange}
          onEdit={handleEdit}
          onRemove={handleRemove}
        />
      </div>
    </div>
  );
};

export default Portfolio;