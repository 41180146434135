import { ROUTES } from '../config/routes';

export const getRouteUrl = (path: string | undefined): string => {
  if (!path) return '/';

  // Always ensure path starts with /
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;

  // Helper function to recursively search through route object
  const findRoute = (routes: any, searchPath: string): string | undefined => {
    if (!routes || typeof routes !== 'object') {
      return undefined;
    }

    // Check if current route matches
    if (routes.path === searchPath) {
      return routes.path;
    }

    // Check children routes
    if (routes.children) {
      for (const child of Object.values(routes.children)) {
        const found = findRoute(child, searchPath);
        if (found) return found;
      }
    }

    // Check all properties for nested routes
    for (const value of Object.values(routes)) {
      if (typeof value === 'object' && value !== null) {
        const found = findRoute(value, searchPath);
        if (found) return found;
      }
    }

    return undefined;
  };

  try {
    const foundPath = findRoute(ROUTES, normalizedPath);
    // Always return absolute path
    return foundPath || normalizedPath;
  } catch (error) {
    console.warn(`Error finding route for path: ${normalizedPath}`, error);
    return normalizedPath;
  }
};

// Helper function to validate all navigation URLs
export const validateNavigationUrls = (navigationItems: any[]): boolean => {
  let isValid = true;

  const validateItems = (items: any[]) => {
    items.forEach(item => {
      if (item.url) {
        const url = getRouteUrl(item.url);
        if (!url) {
          console.warn(`Invalid route URL found: ${item.url}`);
          isValid = false;
        }
      }
      if (item.items) {
        item.items.forEach((section: any) => {
          if (section.items) {
            validateItems(section.items);
          }
        });
      }
    });
  };

  validateItems(navigationItems);
  return isValid;
};