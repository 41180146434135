import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import ArtistGrid from '../components/artists/ArtistGrid';
import ArtistFilters from '../components/artists/ArtistFilters';
import SearchBar from '../components/artists/SearchBar';
import { ArtistProvider } from '../contexts/ArtistContext';
import { ARTIST_CONFIG } from '../config/artists';

const Artists = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={ARTIST_CONFIG.header.title}
        description={ARTIST_CONFIG.header.description}
        imageId={ARTIST_CONFIG.header.imageId}
        variant={ARTIST_CONFIG.header.variant}
      />

      <ArtistProvider>
        <div className={ARTIST_CONFIG.styles.section}>
          <div className={ARTIST_CONFIG.styles.container}>
            <ArtistFilters />
            <SearchBar />
            <ArtistGrid />
          </div>
        </div>
      </ArtistProvider>

      <Footer />
    </div>
  );
};

export default Artists;