import React from 'react';
import { useFormData } from '../../hooks/form/useFormData';
import { useFormNavigation } from '../../hooks/form/useFormNavigation';
import ApplicationHeader from './ApplicationHeader';
import ApplicationContent from './ApplicationContent';
import SaveStatusBar from './SaveStatusBar';

const CONFIG = {
  styles: {
    wrapper: "min-h-screen bg-gray-50",
    content: "py-12 px-4"
  }
};

const ApplicationForm = () => {
  const {
    formData,
    isDirty,
    lastSaved,
    currentStep,
    setCurrentStep,
    updateFormData,
    handleSave,
    isSaving,
    isLoading
  } = useFormData();

  const { handleNext, handlePrevious } = useFormNavigation({
    currentStep,
    setCurrentStep,
    isDirty,
    onSave: handleSave
  });

  return (
    <div className={CONFIG.styles.wrapper}>
      <ApplicationHeader />
      <SaveStatusBar
        isDirty={isDirty}
        isSaving={isSaving}
        lastSaved={lastSaved}
        onSave={handleSave}
      />
      <div className={CONFIG.styles.content}>
        <ApplicationContent
          currentStep={currentStep}
          formData={formData}
          onChange={updateFormData}
          onNext={handleNext}
          onPrevious={handlePrevious}
          loading={isLoading}
          saving={isSaving}
          lastSaved={lastSaved}
        />
      </div>
    </div>
  );
};

export default ApplicationForm;