import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/fellowship/HeroSection';
import MissionSection from '../components/fellowship/MissionSection';
import OpportunitiesSection from '../components/fellowship/OpportunitiesSection';
import EligibilitySection from '../components/fellowship/EligibilitySection';
import ProgramSelector from '../components/fellowship/ProgramSelector';
import FAQ from '../components/FAQ';

const FellowshipProgram = () => {
  return (
    <div>
      <Header variant="dark" />
      
      <HeroSection />
      <MissionSection />
      <OpportunitiesSection />
      <EligibilitySection />
      
      <FAQ
        items={[
          {
            question: "What is the Fellowship Program?",
            answer: "The Fellowship Program provides complete financial coverage for tuition, housing, and meals during a six-week residency. Fellows receive exceptional training from renowned artist-faculty members and perform as featured artists while collaborating with other talented musicians."
          },
          {
            question: "How many fellowships are awarded?",
            answer: "Up to 40 fellowships are awarded annually across the festival to emerging artists who demonstrate exceptional musical talent and a strong commitment to their craft."
          },
          {
            question: "What are the eligibility requirements?",
            answer: "Applicants must be 18 or older and commit to full attendance for the entire residency period. The program is available to composers, conductors, singers, and instrumentalists including specific woodwind, brass, percussion, and string positions."
          },
          {
            question: "What are the expectations during the residency?",
            answer: "Fellows are expected to contribute up to 2 hours per day assisting with administrative needs, participate in their respective program activities, and be available for performances throughout the residency. Late arrivals, early departures, or absences are not permitted."
          },
          {
            question: "How do I apply for a fellowship?",
            answer: "The application process is consolidated for both student and fellowship applicants. Submit one application through your chosen program to be considered for both opportunities. Visit our online application page to begin the process."
          },
          {
            question: "What does the fellowship cover?",
            answer: "The fellowship provides complete financial coverage including tuition, housing, and meals for the entire six-week residency period."
          },
          {
            question: "Can I apply for multiple programs?",
            answer: "While you should focus your application on one primary program, your materials will be considered holistically for opportunities across the festival that match your skills and interests."
          },
          {
            question: "What is the success rate of fellowship alumni?",
            answer: "Our fellowship alumni have achieved significant success, now occupying prominent positions at prestigious institutions including conservatories, young artist programs, festivals, and opera houses worldwide."
          }
        ]}
      />

      <ProgramSelector />
      
      <Footer />
    </div>
  );
};

export default FellowshipProgram;