import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('8c74e94c-751d-4754-8dd5-ab9c7e2b5d28', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="Singers at the AMF Vocal Arts Institute"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
      </div>
      
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-3xl">
          <h1 className="font-serif text-8xl text-white mb-8 relative">
            Vocal Arts Institute
            <div className="absolute -right-24 top-1/2 -translate-y-1/2 w-24 h-32">
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="absolute w-full h-1 bg-white/20"
                  style={{
                    top: `${20 + i * 15}%`,
                    animation: `voiceWave ${1 + i * 0.2}s ease-in-out infinite`,
                    animationDelay: `${i * 0.1}s`
                  }}
                />
              ))}
            </div>
          </h1>
          <p className="text-2xl text-white/80 font-light leading-relaxed">
            Transform your artistry through intensive training, performance opportunities, and collaboration with world-class faculty.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;