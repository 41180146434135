import React from 'react';
import { useArtistContext } from '../../contexts/ArtistContext';
import { ARTIST_CONFIG } from '../../config/artists';

const ArtistFilters = () => {
  const { selectedCategory, setSelectedCategory } = useArtistContext();

  return (
    <div className={ARTIST_CONFIG.styles.filters.wrapper}>
      <button
        onClick={() => setSelectedCategory('')}
        className={`${ARTIST_CONFIG.styles.filters.button.base} ${
          selectedCategory === '' 
            ? ARTIST_CONFIG.styles.filters.button.active 
            : ARTIST_CONFIG.styles.filters.button.inactive
        }`}
      >
        All Artists
      </button>
      {ARTIST_CONFIG.filters.categories.map(category => (
        <button
          key={category.id}
          onClick={() => setSelectedCategory(category.id)}
          className={`${ARTIST_CONFIG.styles.filters.button.base} ${
            selectedCategory === category.id 
              ? ARTIST_CONFIG.styles.filters.button.active 
              : ARTIST_CONFIG.styles.filters.button.inactive
          }`}
        >
          {category.label}
        </button>
      ))}
    </div>
  );
};

export default ArtistFilters;