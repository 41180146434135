import React from 'react';
import { ArrowRight } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Summer Internship Program",
    description: "Gain valuable experience in arts administration and festival operations.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  content: {
    title: "Summer 2024 Internships",
    description: "Our internship opportunities for the 2024 season will be announced in spring. Please check back then for detailed information about available positions and application procedures.",
    note: "Internships typically run from June to August and offer hands-on experience in various aspects of festival operations.",
    areas: [
      "Festival Administration",
      "Concert Production",
      "Marketing & Communications",
      "Development & Fundraising",
      "Community Engagement"
    ],
    cta: {
      text: "Contact Us for More Information",
      url: "/contact-us"
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    content: {
      wrapper: "max-w-2xl mx-auto",
      title: "font-serif text-4xl mb-6 text-center",
      description: "text-lg text-[#1D3557]/80 mb-4 text-center",
      note: "text-[#457B9D] mb-8 text-center",
      areas: {
        wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg mb-12",
        title: "font-serif text-xl mb-4",
        list: "space-y-3",
        item: "flex items-center gap-3 text-[#1D3557]/70",
        bullet: "text-[#E63946]"
      },
      button: {
        wrapper: "text-center",
        link: "inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group",
        text: "relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500"
      }
    }
  }
};

const Internship = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.content.wrapper}>
            <h2 className={CONFIG.styles.content.title}>{CONFIG.content.title}</h2>
            <p className={CONFIG.styles.content.description}>{CONFIG.content.description}</p>
            <p className={CONFIG.styles.content.note}>{CONFIG.content.note}</p>

            <div className={CONFIG.styles.content.areas.wrapper}>
              <h3 className={CONFIG.styles.content.areas.title}>Internship Areas Include:</h3>
              <div className={CONFIG.styles.content.areas.list}>
                {CONFIG.content.areas.map((area, index) => (
                  <div key={index} className={CONFIG.styles.content.areas.item}>
                    <span className={CONFIG.styles.content.areas.bullet}>•</span>
                    {area}
                  </div>
                ))}
              </div>
            </div>

            <div className={CONFIG.styles.content.button.wrapper}>
              <a href={CONFIG.content.cta.url} className={CONFIG.styles.content.button.link}>
                <span className={CONFIG.styles.content.button.text}>
                  {CONFIG.content.cta.text}
                  <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
                </span>
                <div className={CONFIG.styles.content.button.background} />
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Internship;