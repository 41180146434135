import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, Loader2 } from 'lucide-react';
import { PORTFOLIO_CONFIG } from '../config';

interface PortfolioUploaderProps {
  onUpload: (file: File) => void;
  isUploading?: boolean;
  disabled?: boolean;
}

const PortfolioUploader: React.FC<PortfolioUploaderProps> = ({
  onUpload,
  isUploading = false,
  disabled = false
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      onUpload(acceptedFiles[0]);
    }
  }, [onUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: PORTFOLIO_CONFIG.acceptedFileTypes,
    maxSize: PORTFOLIO_CONFIG.maxFileSize,
    disabled: disabled || isUploading,
    multiple: false
  });

  return (
    <div 
      {...getRootProps()} 
      className={`${PORTFOLIO_CONFIG.styles.uploader.wrapper} ${
        disabled || isUploading ? PORTFOLIO_CONFIG.styles.uploader.disabled : ''
      }`}
    >
      <input {...getInputProps()} />
      <div className={PORTFOLIO_CONFIG.styles.uploader.content}>
        {isUploading ? (
          <>
            <Loader2 className={`${PORTFOLIO_CONFIG.styles.uploader.icon} animate-spin`} />
            <p className={PORTFOLIO_CONFIG.styles.uploader.text}>Uploading...</p>
          </>
        ) : (
          <>
            <Upload className={PORTFOLIO_CONFIG.styles.uploader.icon} />
            <div>
              <p className={PORTFOLIO_CONFIG.styles.uploader.text}>
                {isDragActive 
                  ? 'Drop the file here'
                  : 'Click to upload or drag and drop'
                }
              </p>
              <p className={PORTFOLIO_CONFIG.styles.uploader.subtext}>
                MP3 or PDF files (max. {PORTFOLIO_CONFIG.maxFileSize / 1024 / 1024}MB)
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PortfolioUploader;