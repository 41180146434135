import { useCallback } from 'react';

interface UseFormNavigationProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  isDirty: boolean;
  onSave: () => Promise<void>;
}

export const useFormNavigation = ({
  currentStep,
  setCurrentStep,
  isDirty,
  onSave
}: UseFormNavigationProps) => {
  const handleNext = useCallback(async () => {
    if (isDirty) {
      await onSave();
    }
    setCurrentStep(Math.min(currentStep + 1, 5));
  }, [currentStep, isDirty, onSave, setCurrentStep]);

  const handlePrevious = useCallback(() => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  }, [currentStep, setCurrentStep]);

  return {
    handleNext,
    handlePrevious
  };
};