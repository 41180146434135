import React from 'react';
import { FESTIVAL_CONFIG } from '../../config/festival';

const CONFIG = {
  title: `${FESTIVAL_CONFIG.season.year} Festival Season`,
  styles: {
    wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg",
    title: "font-serif text-2xl mb-6",
    grid: "grid grid-cols-1 md:grid-cols-2 gap-8",
    session: {
      title: "font-serif text-xl mb-2",
      dates: "text-[#1D3557]/70",
      details: "text-sm text-[#457B9D] mt-2"
    }
  }
};

const FestivalDates = () => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <h2 className={CONFIG.styles.title}>{CONFIG.title}</h2>
      <div className={CONFIG.styles.grid}>
        <div>
          <h3 className={CONFIG.styles.session.title}>Full Session</h3>
          <p className={CONFIG.styles.session.dates}>
            {FESTIVAL_CONFIG.season.fullSession.dates}
          </p>
          <div className={CONFIG.styles.session.details}>
            <p>Arrival: {FESTIVAL_CONFIG.season.fullSession.arrival.date} by {FESTIVAL_CONFIG.season.fullSession.arrival.time}</p>
            <p>Departure: {FESTIVAL_CONFIG.season.fullSession.departure.date} by {FESTIVAL_CONFIG.season.fullSession.departure.time}</p>
          </div>
        </div>
        <div>
          {FESTIVAL_CONFIG.season.sessions.map((session) => (
            <div key={session.id} className="mb-4 last:mb-0">
              <h3 className={CONFIG.styles.session.title}>
                Session {session.id}
              </h3>
              <p className={CONFIG.styles.session.dates}>
                {session.dates}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FestivalDates;