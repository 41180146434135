import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getAssetUrl } from '../../../utils/directus';

interface Track {
  title: string;
  description: string;
  image: string;
}

interface Opportunity {
  title: string;
  description: string;
  icon: string;
}

interface OverviewProps {
  tracks?: Track[];
  opportunities?: Opportunity[];
}

const Overview: React.FC<OverviewProps> = ({ tracks = [], opportunities = [] }) => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        {/* Program Tracks */}
        {tracks.length > 0 && (
          <>
            <h2 className="font-serif text-6xl mb-16">Program Tracks</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
              {tracks.map((track, index) => (
                <div key={index} className="group">
                  <div className="aspect-square overflow-hidden mb-8">
                    <img
                      src={track.image}
                      alt={track.title}
                      className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
                    />
                    <div className="absolute inset-0 bg-[#1D3557] mix-blend-color opacity-10 group-hover:opacity-0 transition-opacity duration-700" />
                  </div>
                  <h3 className="font-serif text-2xl mb-4">{track.title}</h3>
                  <p className="text-[#1D3557]/70 leading-relaxed">
                    {track.description}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Opportunities */}
        {opportunities.length > 0 && (
          <div className="mt-32">
            <h2 className="font-serif text-6xl mb-16">Opportunities</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
              {opportunities.map((opportunity, index) => (
                <div 
                  key={index} 
                  className="bg-[#1D3557]/[0.02] p-12 group hover:bg-white transition-colors duration-500"
                >
                  <h3 className="font-serif text-2xl mb-4">{opportunity.title}</h3>
                  <p className="text-[#1D3557]/70 leading-relaxed text-lg">
                    {opportunity.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Overview;