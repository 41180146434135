import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getAssetUrl } from '../../utils/directus';

const PROGRAMS = {
  preCollege: {
    title: "Pre-College Program",
    description: "An immersive summer experience for exceptional young musicians ages 14-18.",
    imageId: "deb14307-c91f-433c-a5e4-2149ea689d55",
    url: "/the-institute/pre-college"
  },
  collegiate: [
    {
      title: "Composition Program",
      description: "Advanced training in contemporary composition with world premieres.",
      imageId: "82b7760f-fe82-4ed4-ba94-d620443fedc4",
      url: "/the-institute/programs/composition-program"
    },
    {
      title: "Conducting Program",
      description: "Intensive podium time and mentorship from renowned conductors.",
      imageId: "c09d9f74-4ff1-4598-ac07-4e8c9eeffb49",
      url: "/the-institute/programs/conducting-program"
    },
    {
      title: "Instrumental Program",
      description: "Chamber music and orchestral performance opportunities.",
      imageId: "2aba656e-eac9-4b62-858f-ec5c4b687c61",
      url: "/the-institute/programs/instrumental-program"
    },
    {
      title: "Piano Institute & Seminar",
      description: "Comprehensive piano training and performance excellence.",
      imageId: "30b3cbc9-4f3d-48bf-bbfe-a202f118912c",
      url: "/the-institute/programs/piano-institute-and-seminar"
    },
    {
      title: "Vocal Arts Institute",
      description: "Transform your artistry through intensive training and performance.",
      imageId: "8c74e94c-751d-4754-8dd5-ab9c7e2b5d28",
      url: "/the-institute/programs/vocal-institute"
    }
  ]
};

const ProgramOverview = () => {
  return (
    <section className="py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        {/* Pre-College Section */}
        <div className="mb-32">
          <h2 className="font-serif text-6xl mb-16">Pre-College Program</h2>
          <a 
            href={PROGRAMS.preCollege.url}
            className="group block"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
              <div className="relative">
                <div className="aspect-[4/3] overflow-hidden">
                  <img
                    src={getAssetUrl(PROGRAMS.preCollege.imageId, {
                      width: 800,
                      height: 600,
                      quality: 85,
                      fit: 'cover'
                    })}
                    alt="Pre-College Program"
                    className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-1000"
                  />
                </div>
                <div className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2 border-[#1D3557]/10" />
              </div>

              <div>
                <p className="text-sm tracking-wide text-[#E63946] mb-6">Ages 14-18</p>
                <p className="text-xl text-[#1D3557]/70 leading-relaxed mb-8">
                  {PROGRAMS.preCollege.description}
                </p>
                <span className="flex items-center gap-2 text-[#1D3557] group-hover:gap-4 transition-all duration-300">
                  <span className="text-sm">Learn More</span>
                  <ArrowRight size={16} />
                </span>
              </div>
            </div>
          </a>
        </div>

        {/* Collegiate Programs Section */}
        <div>
          <h2 className="font-serif text-6xl mb-16">Collegiate & Professional Programs</h2>
          <p className="text-sm tracking-wide text-[#E63946] mb-16">Ages 18+</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            {PROGRAMS.collegiate.map((program, index) => (
              <a 
                key={index}
                href={program.url}
                className="group block"
              >
                <div className="relative aspect-[4/3] overflow-hidden mb-8">
                  <img
                    src={getAssetUrl(program.imageId, {
                      width: 800,
                      height: 600,
                      quality: 85,
                      fit: 'cover'
                    })}
                    alt={program.title}
                    className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-1000"
                  />
                  <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
                </div>
                
                <h3 className="font-serif text-2xl mb-4">{program.title}</h3>
                <p className="text-[#1D3557]/70 leading-relaxed mb-6">
                  {program.description}
                </p>
                <span className="flex items-center gap-2 text-[#1D3557] group-hover:gap-4 transition-all duration-300">
                  <span className="text-sm">Explore Program</span>
                  <ArrowRight size={16} />
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgramOverview;