import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Legal Information",
    description: "Terms of Use & Privacy Policy",
    variant: 'light' as const
  },
  terms: {
    title: "Terms of Use",
    lastUpdated: "Last Updated: January 1, 2024",
    sections: [
      {
        title: "1. Agreement to Terms",
        content: "By accessing and using the Atlantic Music Festival website and services, you agree to be bound by these Terms of Use, our Privacy Policy, and all applicable laws and regulations. If you do not agree with these terms, you must not use our website or services."
      },
      {
        title: "2. Intellectual Property Rights",
        content: "All content on this website, including but not limited to text, graphics, logos, images, audio clips, digital downloads, and software, is the property of Atlantic Music Festival or its content suppliers and is protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of Atlantic Music Festival."
      },
      {
        title: "3. User License",
        content: "We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our website for personal, non-commercial purposes. This license does not include:",
        list: [
          "Modifying or copying materials",
          "Using materials for commercial purposes",
          "Attempting to decompile or reverse engineer any software",
          "Removing any copyright or proprietary notations",
          "Transferring materials to another person"
        ]
      },
      {
        title: "4. Disclaimer",
        content: "The materials on the Atlantic Music Festival website are provided on an 'as is' basis. Atlantic Music Festival makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights."
      },
      {
        title: "5. Limitations",
        content: "In no event shall Atlantic Music Festival or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website."
      },
      {
        title: "6. Accuracy of Materials",
        content: "The materials appearing on our website could include technical, typographical, or photographic errors. Atlantic Music Festival does not warrant that any of the materials on its website are accurate, complete, or current."
      }
    ]
  },
  privacy: {
    title: "Privacy Policy",
    lastUpdated: "Last Updated: January 1, 2024",
    sections: [
      {
        title: "1. Information Collection",
        content: "We collect information that you provide directly to us, including:",
        list: [
          "Contact information (name, email, phone number)",
          "Application and registration data",
          "Payment information",
          "Communications with us",
          "Usage data and cookies"
        ]
      },
      {
        title: "2. Use of Information",
        content: "We use the information we collect to:",
        list: [
          "Process your applications and registrations",
          "Communicate with you about our programs and events",
          "Improve our services and website",
          "Comply with legal obligations",
          "Protect our rights and prevent fraud"
        ]
      },
      {
        title: "3. Information Sharing",
        content: "We do not sell your personal information. We may share your information with:",
        list: [
          "Service providers who assist in our operations",
          "Legal and regulatory authorities when required",
          "Professional advisors and consultants",
          "In connection with a business transaction"
        ]
      },
      {
        title: "4. Data Security",
        content: "We implement appropriate technical and organizational measures to protect your personal information. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%."
      },
      {
        title: "5. Your Rights",
        content: "You have the right to:",
        list: [
          "Access your personal information",
          "Correct inaccurate data",
          "Request deletion of your data",
          "Object to our use of your data",
          "Withdraw consent"
        ]
      },
      {
        title: "6. Contact Us",
        content: "If you have any questions about our Privacy Policy or practices, please contact us at privacy@atlanticmusicfestival.org"
      }
    ]
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    content: {
      wrapper: "max-w-4xl mx-auto",
      lastUpdated: "text-sm text-[#1D3557]/60 mb-12",
      section: {
        wrapper: "mb-12",
        title: "font-serif text-2xl mb-4",
        content: "text-[#1D3557]/80 leading-relaxed",
        list: "list-disc list-inside space-y-2 mt-4 ml-4 text-[#1D3557]/80"
      }
    }
  }
};

const Legal = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        variant={CONFIG.header.variant}
      />

      {/* Terms of Use */}
      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.content.wrapper}>
            <h2 className="font-serif text-4xl mb-4">{CONFIG.terms.title}</h2>
            <p className={CONFIG.styles.content.lastUpdated}>{CONFIG.terms.lastUpdated}</p>

            {CONFIG.terms.sections.map((section, index) => (
              <div key={index} className={CONFIG.styles.content.section.wrapper}>
                <h3 className={CONFIG.styles.content.section.title}>{section.title}</h3>
                <p className={CONFIG.styles.content.section.content}>{section.content}</p>
                {section.list && (
                  <ul className={CONFIG.styles.content.section.list}>
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Privacy Policy */}
      <section className={`${CONFIG.styles.section} bg-[#1D3557]/[0.02]`}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.content.wrapper}>
            <h2 className="font-serif text-4xl mb-4">{CONFIG.privacy.title}</h2>
            <p className={CONFIG.styles.content.lastUpdated}>{CONFIG.privacy.lastUpdated}</p>

            {CONFIG.privacy.sections.map((section, index) => (
              <div key={index} className={CONFIG.styles.content.section.wrapper}>
                <h3 className={CONFIG.styles.content.section.title}>{section.title}</h3>
                <p className={CONFIG.styles.content.section.content}>{section.content}</p>
                {section.list && (
                  <ul className={CONFIG.styles.content.section.list}>
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Legal;