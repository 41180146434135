import React, { useState } from 'react';

interface SessionSelectionProps {
  programType: string;
  sessions: string[];
  onChange: (sessions: string[]) => void;
}

const CONFIG = {
  preCollege: {
    sessions: [
      {
        id: 'session1',
        name: 'Session 1 (Weeks 1-3)',
        weeks: ['Week 1', 'Week 2', 'Week 3']
      },
      {
        id: 'session2',
        name: 'Session 2 (Weeks 4-6)',
        weeks: ['Week 4', 'Week 5', 'Week 6']
      }
    ]
  },
  collegiate: {
    sessions: ['Session 1', 'Session 2']
  },
  styles: {
    wrapper: "space-y-6",
    title: "text-sm font-medium mb-4",
    info: {
      wrapper: "space-y-3 mb-6",
      text: "text-sm text-[#1D3557]/80"
    },
    grid: "grid grid-cols-1 md:grid-cols-2 gap-8",
    session: {
      wrapper: "space-y-4",
      title: "text-sm font-medium text-[#457B9D]",
      weeks: "space-y-3 mt-4"
    },
    checkbox: {
      wrapper: "flex items-center gap-3",
      input: "h-4 w-4 text-[#E63946] border-[#1D3557]/20 rounded focus:ring-[#E63946]",
      label: "text-sm"
    },
    button: {
      base: "text-sm text-[#457B9D] hover:text-[#E63946] transition-colors mt-2",
      icon: "inline-block ml-1 w-4 h-4"
    }
  }
};

const SessionSelection: React.FC<SessionSelectionProps> = ({
  programType,
  sessions,
  onChange
}) => {
  const [showPartialWeeks, setShowPartialWeeks] = useState(false);

  const handleSessionChange = (sessionId: string) => {
    const session = CONFIG.preCollege.sessions.find(s => s.id === sessionId);
    if (!session) return;

    const sessionWeeks = new Set(sessions);
    session.weeks.forEach(week => {
      if (sessions.includes(week)) {
        sessionWeeks.delete(week);
      } else {
        sessionWeeks.add(week);
      }
    });
    onChange(Array.from(sessionWeeks));
  };

  const handleWeekChange = (week: string) => {
    const newSessions = sessions.includes(week)
      ? sessions.filter(s => s !== week)
      : [...sessions, week];
    onChange(newSessions);
  };

  const isSessionSelected = (sessionId: string) => {
    const session = CONFIG.preCollege.sessions.find(s => s.id === sessionId);
    return session?.weeks.every(week => sessions.includes(week));
  };

  return (
    <div className={CONFIG.styles.wrapper}>
      <h4 className={CONFIG.styles.title}>Session Selection</h4>
      <div className={CONFIG.styles.info.wrapper}>
        <p className={CONFIG.styles.info.text}>
          Our program is designed around comprehensive three-week sessions to provide the most enriching musical experience.
        </p>
        {programType === 'pre-college' && (
          <p className={CONFIG.styles.info.text}>
            Upon acceptance, you will have the opportunity to confirm your session preferences based on availability.
          </p>
        )}
      </div>

      <div className={CONFIG.styles.grid}>
        {programType === 'pre-college' ? (
          <>
            {CONFIG.preCollege.sessions.map((session) => (
              <div key={session.id} className={CONFIG.styles.session.wrapper}>
                <label className={CONFIG.styles.checkbox.wrapper}>
                  <input
                    type="checkbox"
                    checked={isSessionSelected(session.id)}
                    onChange={() => handleSessionChange(session.id)}
                    className={CONFIG.styles.checkbox.input}
                  />
                  <span className={CONFIG.styles.checkbox.label}>{session.name}</span>
                </label>

                {showPartialWeeks && (
                  <div className={CONFIG.styles.session.weeks}>
                    {session.weeks.map((week) => (
                      <label key={week} className={CONFIG.styles.checkbox.wrapper}>
                        <input
                          type="checkbox"
                          checked={sessions.includes(week)}
                          onChange={() => handleWeekChange(week)}
                          className={CONFIG.styles.checkbox.input}
                        />
                        <span className={CONFIG.styles.checkbox.label}>{week}</span>
                      </label>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={() => setShowPartialWeeks(!showPartialWeeks)}
              className={CONFIG.styles.button.base}
            >
              {showPartialWeeks ? 'Hide partial week options' : 'Show partial week options'}
            </button>
          </>
        ) : (
          CONFIG.collegiate.sessions.map((session) => (
            <label key={session} className={CONFIG.styles.checkbox.wrapper}>
              <input
                type="checkbox"
                checked={sessions.includes(session)}
                onChange={() => handleWeekChange(session)}
                className={CONFIG.styles.checkbox.input}
              />
              <span className={CONFIG.styles.checkbox.label}>{session}</span>
            </label>
          ))
        )}
      </div>
    </div>
  );
};

export default SessionSelection;