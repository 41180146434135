import React, { createContext, useContext, useState, useEffect } from 'react';
import facultyData from '../data/faculty/faculty.json';
import { ARTIST_CONFIG } from '../config/artists';

interface ArtistContextType {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedArtist: any;
  setSelectedArtist: (artist: any) => void;
  filteredArtists: any[];
}

const ArtistContext = createContext<ArtistContextType | undefined>(undefined);

export const useArtistContext = () => {
  const context = useContext(ArtistContext);
  if (!context) {
    throw new Error('useArtistContext must be used within an ArtistProvider');
  }
  return context;
};

export const ArtistProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedArtist, setSelectedArtist] = useState<any>(null);
  const [filteredArtists, setFilteredArtists] = useState(facultyData);

  useEffect(() => {
    let filtered = [...facultyData];

    if (selectedCategory) {
      const category = ARTIST_CONFIG.filters.categories.find(c => c.id === selectedCategory);
      if (category) {
        filtered = filtered.filter(artist => {
          const artistInstruments = artist.instruments?.map(i => i.value) || [];
          return category.values.some(value => {
            if (category.exactMatch) {
              return artistInstruments.includes(value);
            }
            return artistInstruments.some(instrument => 
              instrument.toLowerCase().includes(value.toLowerCase())
            );
          });
        });
      }
    }

    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(artist => 
        artist.name.toLowerCase().includes(query) ||
        artist.instruments?.some(i => i.label.toLowerCase().includes(query)) ||
        artist.affiliation?.toLowerCase().includes(query)
      );
    }

    setFilteredArtists(filtered);
  }, [selectedCategory, searchQuery]);

  return (
    <ArtistContext.Provider value={{
      selectedCategory,
      setSelectedCategory,
      searchQuery,
      setSearchQuery,
      selectedArtist,
      setSelectedArtist,
      filteredArtists
    }}>
      {children}
    </ArtistContext.Provider>
  );
};