import React from 'react';
import { ArrowRight, Heart, Users, Calendar, Gift } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';

const CONFIG = {
  header: {
    title: "Get Involved",
    description: "Join us in shaping the future of classical music through various meaningful ways to contribute.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  intro: {
    title: "Make a Difference",
    description: "The Atlantic Music Festival thrives through the support and dedication of our community. Whether through donations, volunteering, or joining our team, your involvement helps us nurture extraordinary talent and bring world-class music to our community."
  },
  ways: [
    {
      icon: Gift,
      title: "Make a Gift",
      description: "Support emerging musicians and help us bring their music closer to you. Your tax-deductible donation directly impacts our ability to provide fellowships and present free concerts.",
      cta: "Support AMF",
      url: "/support"
    },
    {
      icon: Users,
      title: "Volunteer",
      description: "Become part of our festival community by volunteering. From concert ushering to community engagement, your time and skills make a difference.",
      cta: "Learn More",
      url: "/get-involved/volunteer"
    },
    {
      icon: Calendar,
      title: "Join Our Team",
      description: "Explore career and internship opportunities with AMF. Help us create extraordinary musical experiences while advancing your professional journey.",
      cta: "View Opportunities",
      url: "/get-involved/jobs"
    }
  ],
  donation: {
    title: "Support Through Donation",
    description: "Your gift to the Atlantic Music Festival supports emerging musicians and helps us bring their music closer to you. As a 501(c)3 non-profit organization, your contribution is 100% tax-deductible.",
    address: {
      title: "Mail Your Donation",
      lines: [
        "Atlantic Music Festival",
        "228 Park Ave S #31975",
        "New York, NY 10003"
      ]
    }
  },
  styles: {
    section: "py-16 md:py-24",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    intro: {
      wrapper: "text-center max-w-3xl mx-auto mb-24",
      title: "font-serif text-4xl mb-6",
      description: "text-lg text-[#1D3557]/80 leading-relaxed"
    },
    grid: "grid grid-cols-1 md:grid-cols-3 gap-8",
    card: {
      wrapper: "bg-[#1D3557]/[0.02] p-8 rounded-lg group hover:bg-white transition-all duration-500",
      icon: "w-12 h-12 text-[#E63946] mb-6",
      title: "font-serif text-2xl mb-4",
      description: "text-[#1D3557]/70 leading-relaxed mb-6",
      link: "inline-flex items-center gap-2 text-[#E63946] group-hover:gap-3 transition-all"
    },
    donation: {
      wrapper: "bg-[#1D3557] text-white rounded-lg p-12 text-center max-w-3xl mx-auto",
      title: "font-serif text-3xl mb-4",
      description: "text-white/80 mb-8",
      address: {
        title: "text-xl mb-4",
        text: "text-white/60"
      }
    }
  }
};

const GetInvolved = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.intro.wrapper}>
            <h2 className={CONFIG.styles.intro.title}>{CONFIG.intro.title}</h2>
            <p className={CONFIG.styles.intro.description}>{CONFIG.intro.description}</p>
          </div>

          <div className={CONFIG.styles.grid}>
            {CONFIG.ways.map((way, index) => {
              const Icon = way.icon;
              return (
                <div key={index} className={CONFIG.styles.card.wrapper}>
                  <Icon className={CONFIG.styles.card.icon} />
                  <h3 className={CONFIG.styles.card.title}>{way.title}</h3>
                  <p className={CONFIG.styles.card.description}>{way.description}</p>
                  <a href={way.url} className={CONFIG.styles.card.link}>
                    {way.cta}
                    <ArrowRight size={16} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className={CONFIG.styles.section}>
        <div className={CONFIG.styles.container}>
          <div className={CONFIG.styles.donation.wrapper}>
            <h2 className={CONFIG.styles.donation.title}>{CONFIG.donation.title}</h2>
            <p className={CONFIG.styles.donation.description}>{CONFIG.donation.description}</p>
            <div>
              <h3 className={CONFIG.styles.donation.address.title}>{CONFIG.donation.address.title}</h3>
              {CONFIG.donation.address.lines.map((line, index) => (
                <p key={index} className={CONFIG.styles.donation.address.text}>{line}</p>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default GetInvolved;