import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background */}
      <div className="absolute inset-0">
        <img 
          src={getAssetUrl('22a6c6cc-9501-4193-ab81-e0b09817084f', {
            width: 1920,
            height: 1080,
            quality: 85,
            fit: 'cover'
          })}
          alt="The Institute"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/95 via-black/80 to-transparent" />
        
        {/* Abstract musical elements */}
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          {/* Circular notation */}
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="absolute rounded-full border border-white/10"
              style={{
                width: `${400 + i * 200}px`,
                height: `${400 + i * 200}px`,
                left: '15%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                animation: `circleRotate ${20 + i * 5}s linear infinite ${i * 2}s`
              }}
            />
          ))}

          {/* Dynamic lines */}
          {[...Array(12)].map((_, i) => (
            <div
              key={`line-${i}`}
              className="absolute w-[1px] bg-white/10"
              style={{
                height: `${100 + Math.random() * 200}px`,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                transform: `rotate(${Math.random() * 360}deg)`,
                animation: `lineFloat ${5 + Math.random() * 5}s ease-in-out infinite ${Math.random() * 2}s`
              }}
            />
          ))}
        </div>
      </div>
      
      {/* Content */}
      <div className="relative max-w-screen-2xl mx-auto px-8 md:px-16 py-32 mt-20">
        <div className="max-w-4xl">
          <div className="overflow-hidden mb-12">
            <div className="transform hover:scale-105 transition-transform duration-700">
              <h1 className="font-serif text-[8rem] leading-none text-white mb-4">
                AMF
              </h1>
              <div className="flex items-baseline gap-8">
                <span className="font-serif text-[6rem] leading-none text-[#E63946]">
                  Institute
                </span>
                <div className="w-32 h-px bg-gradient-to-r from-[#E63946] to-transparent" />
              </div>
            </div>
          </div>

          <div className="relative max-w-2xl mt-16">
            <p className="text-2xl text-white/80 font-light leading-relaxed">
              Join a community of exceptional artists dedicated to advancing the future of classical music through rigorous study, mentorship, and artistic collaboration.
            </p>
          </div>

          {/* Statistics with artistic treatment */}
          <div className="mt-24 grid grid-cols-4 gap-12">
            {[
              { number: "15", label: "Years of Excellence" },
              { number: "700+", label: "World Premieres" },
              { number: "600+", label: "Fellowship Alumni" },
              { number: "40+", label: "Faculty Artists" }
            ].map((stat, index) => (
              <div 
                key={index} 
                className="relative group"
                style={{
                  animation: `fadeUp 0.8s ease-out ${index * 0.2}s forwards`,
                  opacity: 0,
                  transform: 'translateY(20px)'
                }}
              >
                <div className="text-white/90">
                  <div className="font-serif text-5xl mb-2">{stat.number}</div>
                  <div className="text-sm tracking-wide text-white/60">{stat.label}</div>
                </div>
                <div className="absolute -inset-4 border border-white/0 group-hover:border-white/10 transition-all duration-500 rounded-lg" />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-12 left-1/2 -translate-x-1/2">
        <div className="w-[1px] h-24 bg-gradient-to-b from-white/0 via-white/50 to-white/0 animate-pulse" />
      </div>

      <style>{`
        @keyframes circleRotate {
          from { transform: translate(-50%, -50%) rotate(0deg); }
          to { transform: translate(-50%, -50%) rotate(360deg); }
        }

        @keyframes lineFloat {
          0%, 100% { transform: rotate(var(--rotation)) translateY(0); }
          50% { transform: rotate(var(--rotation)) translateY(-20px); }
        }

        @keyframes fadeUp {
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default HeroSection;