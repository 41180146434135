export const initialFormData = {
  personalInfo: {
    firstName: '',
    middleName: '',
    lastName: '',
    hasPreferredName: false,
    preferredName: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US'
    },
    phone: '',
    gender: '',
    otherGender: '',
    dateOfBirth: '',
    nationality: ''
  },
  programInfo: {
    type: '',
    major: '',
    specialization: '',
    sessions: []
  },
  education: {
    academic: [{
      currentlyEnrolled: false,
      yearsAttended: '',
      schoolName: '',
      degree: ''
    }],
    honorsAchievements: [''],
    resumeUrl: ''
  },
  portfolio: {
    items: []
  },
  survey: {
    heardFrom: '',
    teacherName: ''
  },
  status: 'draft',
  lastUpdated: null
};