import React, { forwardRef } from 'react';

interface FormFieldProps extends React.ComponentPropsWithRef<'input'> {
  label: string;
  error?: string;
  children?: React.ReactNode;
  type?: string;
}

const FormField = forwardRef<HTMLInputElement | HTMLSelectElement, FormFieldProps>(
  ({ label, error, children, type = 'text', ...props }, ref) => {
    const id = label.toLowerCase().replace(/\s+/g, '-');

    const renderInput = () => {
      if (children) {
        return (
          <div className="relative">
            {type === 'select' ? (
              <select
                ref={ref as React.Ref<HTMLSelectElement>}
                id={id}
                className={`
                  w-full px-4 py-3 border rounded-md transition-colors
                  ${error ? 'border-[#E63946]' : 'border-[#1D3557]/20 focus:border-[#E63946]'}
                `}
                {...props}
              >
                {children}
              </select>
            ) : (
              children
            )}
          </div>
        );
      }

      return (
        <input
          ref={ref as React.Ref<HTMLInputElement>}
          id={id}
          type={type}
          className={`
            w-full px-4 py-3 border rounded-md transition-colors
            ${error ? 'border-[#E63946]' : 'border-[#1D3557]/20 focus:border-[#E63946]'}
          `}
          {...props}
        />
      );
    };

    return (
      <div className="space-y-2">
        <label htmlFor={id} className="block text-sm font-medium text-[#1D3557]">
          {label}
          {props.required && <span className="text-[#E63946] ml-1">*</span>}
        </label>
        
        {renderInput()}
        
        {error && (
          <p className="text-sm text-[#E63946] mt-1">{error}</p>
        )}
      </div>
    );
  }
);

FormField.displayName = 'FormField';

export default FormField;