import React from 'react';
import { ArrowRight } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import AboutSection from '../components/AboutSection';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { VENUE_CONFIG } from '../config/venue';

const CONFIG = {
  header: {
    title: "About AMF",
    description: "A sanctuary for artistic freedom, where extraordinary talent converges with visionary ideals to shape the future of classical music.",
    imageId: "22a6c6cc-9501-4193-ab81-e0b09817084f",
    variant: 'light' as const
  },
  quote: {
    text: "This will be our reply to violence: to make music more intensely, more beautifully, more devotedly than ever before.",
    author: "Leonard Bernstein"
  },
  sections: [
    {
      title: "A Sanctuary for Artistic Freedom",
      description: "A retreat where creativity knows no bounds. Musicians from diverse global backgrounds converge here, embracing artistic freedom and sharing extraordinary ideas in a setting unlike any other."
    },
    {
      title: "The Birthplace of Tomorrow's Masterpieces",
      description: "With over 700 works premiered, we stand as a beacon of innovation in the musical world. Our commitment to new music and emerging voices makes us a hub for the creation of tomorrow's masterpieces."
    },
    {
      title: "A Provider of Authentic Musical Experiences",
      description: "Our free concerts offer more than performances; they offer authentic experiences. During our summer season, we provide a richer variety of concerts than typically found in a small city, expanding access to classical music and transcending the transactional nature of standard concert-going."
    },
    {
      title: "A Platform for Musical Growth",
      description: "We have hosted thousands of participants, including 500 fellowship recipients and countless world-renowned musicians, making the festival a pivotal leap in the musical journeys of emerging talents and established artists, shaping careers and fostering growth from student to professional stages."
    },
    {
      title: "A Cultivator of Lasting Connections",
      description: "Here, music is more than sound—it's a bond. Participants form lifelong friendships and collaborations, leading to new artistic ventures and enriching the global music community."
    },
    {
      title: "An Enricher of Cultural Life",
      description: "Our presence contributes significantly to Maine's cultural landscape, creating meaningful connections through music and fostering artistic excellence in our community."
    }
  ],
  facilities: {
    title: "Performance Venues",
    description: "Our festival venues provide exceptional settings for musical excellence, featuring professional acoustics and modern amenities.",
    details: [
      "World-class performance halls",
      "State-of-the-art practice facilities",
      "Professional recording capabilities",
      "Inspiring rehearsal spaces"
    ],
    image: "/images/amf/about-amf/parallax-1.jpg",
    link: {
      text: "View Performance Venues",
      url: "/visitors"
    }
  },
  gallery: {
    title: "Festival Life",
    description: "Experience the vibrant moments that make our festival unique.",
    images: [
      { url: "/images/amf/about-amf/gallery1/lg-01.jpg", thumbnail: "/images/amf/about-amf/gallery1/md-01.jpg" },
      { url: "/images/amf/about-amf/gallery1/lg-02.jpg", thumbnail: "/images/amf/about-amf/gallery1/md-02.jpg" },
      { url: "/images/amf/about-amf/gallery1/lg-03.jpg", thumbnail: "/images/amf/about-amf/gallery1/md-03.jpg" },
      { url: "/images/amf/about-amf/gallery1/lg-04.jpg", thumbnail: "/images/amf/about-amf/gallery1/md-04.jpg" },
      { url: "/images/amf/about-amf/gallery1/lg-05.jpg", thumbnail: "/images/amf/about-amf/gallery1/md-05.jpg" },
      { url: "/images/amf/about-amf/gallery1/lg-06.jpg", thumbnail: "/images/amf/about-amf/gallery1/md-06.jpg" }
    ]
  },
  posters: {
    title: "Festival History",
    description: "A visual journey through our past seasons.",
    images: Array.from({ length: 12 }, (_, i) => ({
      url: `/images/amf/about-amf/posters/${i + 1}.jpg`,
      thumbnail: `/images/amf/about-amf/posters/thumbnails/${i + 1}.jpg`
    }))
  },
  cta: {
    title: "An Invitation to Join a Musical Odyssey",
    description: "Join us in this unique journey. Whether as a participant, patron, volunteer, or audience member, your involvement is crucial. Be part of a festival where each note played contributes to a more connected and inspired world.",
    button: {
      text: "Support Our Mission",
      url: "/get-involved"
    }
  }
};

const AboutAMF = () => {
  return (
    <div>
      <Header variant="light" />
      <PageHeader
        title={CONFIG.header.title}
        description={CONFIG.header.description}
        imageId={CONFIG.header.imageId}
        variant={CONFIG.header.variant}
      />
      
      <div className="relative z-20">
        <div className="py-16 md:py-24">
          <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
            <div className="mb-24 md:mb-32 text-center">
              <blockquote className="font-serif text-2xl md:text-4xl italic max-w-4xl mx-auto leading-relaxed">
                "{CONFIG.quote.text}"
              </blockquote>
              <span className="block text-sm tracking-wide text-[#1D3557]/60 mt-6 text-center">
                — {CONFIG.quote.author}
              </span>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-24">
              {CONFIG.sections.map((section, index) => (
                <AboutSection
                  key={index}
                  title={section.title}
                  description={section.description}
                />
              ))}
            </div>

            {/* Performance Venues */}
            <div className="mt-32 grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
              <div>
                <img
                  src={CONFIG.facilities.image}
                  alt="Performance Venues"
                  className="w-full rounded-lg"
                />
              </div>
              <div>
                <h2 className="font-serif text-4xl mb-6">{CONFIG.facilities.title}</h2>
                <p className="text-[#1D3557]/80 mb-8">{CONFIG.facilities.description}</p>
                <ul className="space-y-4 mb-8">
                  {CONFIG.facilities.details.map((detail, index) => (
                    <li key={index} className="flex items-center gap-3 text-[#1D3557]/70">
                      <span className="text-[#E63946]">•</span>
                      {detail}
                    </li>
                  ))}
                </ul>
                <a 
                  href={CONFIG.facilities.link.url}
                  className="inline-flex items-center gap-2 text-[#E63946] hover:gap-3 transition-all"
                >
                  {CONFIG.facilities.link.text}
                  <ArrowRight size={16} />
                </a>
              </div>
            </div>

            {/* Gallery */}
            <div className="mt-32">
              <h2 className="font-serif text-4xl mb-6">{CONFIG.gallery.title}</h2>
              <p className="text-[#1D3557]/80 mb-12">{CONFIG.gallery.description}</p>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {CONFIG.gallery.images.map((image, index) => (
                  <a 
                    key={index}
                    href={image.url}
                    className="block aspect-square overflow-hidden rounded-lg group"
                  >
                    <img
                      src={image.thumbnail}
                      alt={`Festival Moment ${index + 1}`}
                      className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
                    />
                  </a>
                ))}
              </div>
            </div>

            {/* Festival History */}
            <div className="mt-32">
              <h2 className="font-serif text-4xl mb-6">{CONFIG.posters.title}</h2>
              <p className="text-[#1D3557]/80 mb-12">{CONFIG.posters.description}</p>
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {CONFIG.posters.images.map((image, index) => (
                  <a 
                    key={index}
                    href={image.url}
                    className="block aspect-[3/4] overflow-hidden rounded-lg group"
                  >
                    <img
                      src={image.thumbnail}
                      alt={`Festival Poster ${index + 1}`}
                      className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
                    />
                  </a>
                ))}
              </div>
            </div>

            {/* CTA */}
            <div className="mt-32 text-center max-w-3xl mx-auto">
              <h2 className="font-serif text-4xl mb-6">{CONFIG.cta.title}</h2>
              <p className="font-light text-[#1D3557]/80 leading-relaxed mb-8">
                {CONFIG.cta.description}
              </p>
              <a 
                href={CONFIG.cta.button.url} 
                className="inline-flex items-center gap-2 px-8 py-4 border-2 border-[#1D3557] relative overflow-hidden group"
              >
                <span className="relative z-10 text-sm tracking-wide group-hover:text-white transition-colors duration-500">
                  {CONFIG.cta.button.text}
                  <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
                </span>
                <div className="absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutAMF;