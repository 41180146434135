import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const VISUAL_JOURNEY = [
  {
    imageId: "2aba656e-eac9-4b62-858f-ec5c4b687c61",
    title: "The Orchestra",
    description: "Where individual voices unite to create something greater than the sum of their parts.",
    size: "large"
  },
  {
    imageId: "f6e4c490-5d2a-4c74-963e-6ba6cedd19c8",
    title: "Chamber Intimacy",
    description: "In the delicate interplay of chamber music, every voice carries weight.",
    size: "small"
  },
  {
    imageId: "212de122-1ed6-4f21-af96-2a3d74f41b05",
    title: "Moments of Focus",
    description: "The quiet intensity of individual practice shapes our collective sound.",
    size: "small"
  },
  {
    imageId: "57fd02c9-fd9c-4ac0-8861-d333f5f9f09e",
    title: "The Pulse",
    description: "Rhythm and precision merge in the heartbeat of our ensemble.",
    size: "full"
  }
];

const VisualJourney = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-7xl mb-16">A Visual Journey</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {VISUAL_JOURNEY.map((item, index) => (
            <div 
              key={index}
              className={`relative ${
                item.size === 'large' ? 'md:col-span-2 aspect-[21/9]' :
                item.size === 'full' ? 'md:col-span-2 aspect-[21/9]' :
                'aspect-square'
              } overflow-hidden group`}
            >
              <img
                src={getAssetUrl(item.imageId, {
                  width: item.size === 'small' ? 800 : 1920,
                  height: item.size === 'small' ? 800 : 1080,
                  quality: 85,
                  fit: 'cover'
                })}
                alt={item.title}
                className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-1000"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
              
              <div className="absolute inset-x-0 bottom-0 p-8 translate-y-full group-hover:translate-y-0 transition-transform duration-700">
                <h3 className="font-serif text-3xl text-white mb-2">{item.title}</h3>
                <p className="text-white/80 font-light">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VisualJourney;