import { useState, useRef, useEffect, useCallback } from 'react';
import { Track } from '../data/tracks';

export function useAudioPlayer() {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTrack, setCurrentTrack] = useState<Track | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);

  // Initialize audio element
  useEffect(() => {
    audioRef.current = new Audio();
    audioRef.current.preload = 'metadata';

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Set up audio event listeners
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleTimeUpdate = () => setProgress(audio.currentTime);
    const handleLoadedMetadata = () => setDuration(audio.duration);
    const handleEnded = () => setIsPlaying(false);

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  const playTrack = useCallback(async (track: Track) => {
    if (!audioRef.current) return;

    try {
      if (currentTrack?.id === track.id) {
        if (!isPlaying) {
          await audioRef.current.play();
          setIsPlaying(true);
        }
        return;
      }

      audioRef.current.src = track.url;
      audioRef.current.volume = isMuted ? 0 : volume;
      setCurrentTrack(track);
      await audioRef.current.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Failed to play track:', error);
      setIsPlaying(false);
    }
  }, [currentTrack, isPlaying, isMuted, volume]);

  const togglePlayPause = useCallback(async () => {
    if (!audioRef.current || !currentTrack) return;

    try {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        await audioRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Failed to toggle play/pause:', error);
      setIsPlaying(false);
    }
  }, [currentTrack, isPlaying]);

  const seekTo = useCallback((time: number) => {
    if (!audioRef.current) return;
    audioRef.current.currentTime = time;
    setProgress(time);
  }, []);

  const handleSetVolume = useCallback((newVolume: number) => {
    if (!audioRef.current) return;
    const volume = Math.max(0, Math.min(1, newVolume));
    audioRef.current.volume = volume;
    setVolume(volume);
    setIsMuted(false);
  }, []);

  const toggleMute = useCallback(() => {
    if (!audioRef.current) return;
    const newMutedState = !isMuted;
    audioRef.current.volume = newMutedState ? 0 : volume;
    setIsMuted(newMutedState);
  }, [isMuted, volume]);

  return {
    currentTrack,
    isPlaying,
    progress,
    duration,
    volume,
    isMuted,
    playTrack,
    togglePlayPause,
    seekTo,
    setVolume: handleSetVolume,
    toggleMute
  };
}