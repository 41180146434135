import React from 'react';
import { Info } from 'lucide-react';

const CONFIG = {
  title: "Important Notes",
  items: [
    "Transportation to/from festival not included",
    "Personal health insurance required",
    "Travel insurance recommended",
    "Tuition refundable if event cancelled by festival"
  ],
  styles: {
    wrapper: "bg-[#E63946]/10 p-8 rounded-lg",
    title: "font-serif text-xl text-[#E63946] mb-4",
    list: "space-y-3",
    item: "flex items-center gap-3 text-[#1D3557]/70"
  }
};

const ImportantNotes = () => {
  return (
    <div className={CONFIG.styles.wrapper}>
      <h3 className={CONFIG.styles.title}>{CONFIG.title}</h3>
      <div className={CONFIG.styles.list}>
        {CONFIG.items.map((item, index) => (
          <div key={index} className={CONFIG.styles.item}>
            <Info size={16} className="flex-shrink-0" />
            <span>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImportantNotes;