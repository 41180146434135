import React from 'react';
import { ArrowRight } from 'lucide-react';

interface ApplicationSectionProps {
  requirements: string[];
}

const ApplicationSection: React.FC<ApplicationSectionProps> = ({ requirements }) => {
  return (
    <section className="py-24 bg-[#1D3557]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-3xl mx-auto text-white">
          <h2 className="font-serif text-4xl mb-8 text-center">Application Process</h2>
          
          <div className="mb-12">
            <h3 className="font-serif text-2xl mb-4">Audition Requirements</h3>
            <div className="space-y-4">
              {requirements.map((requirement, index) => (
                <div key={index} className="flex items-start gap-3 text-white/80">
                  <span className="text-[#E63946] mt-1.5">•</span>
                  <span>{requirement}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="text-center">
            <p className="text-white/80 mb-8">
              Ready to begin your journey with the Atlantic Music Festival?
            </p>
            <a 
              href="/the-institute/online-application" 
              className="inline-flex items-center gap-2 px-8 py-4 border-2 border-white relative overflow-hidden group"
            >
              <span className="relative z-10 text-sm tracking-wide group-hover:text-[#1D3557] transition-colors duration-500">
                Apply Now
                <ArrowRight size={14} className="inline-block ml-2 group-hover:translate-x-2 transition-transform duration-500" />
              </span>
              <div className="absolute inset-0 bg-white -translate-y-full group-hover:translate-y-0 transition-transform duration-500" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationSection;