import { useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { getApplicationRef } from '../utils/firebase';

export const useApplication = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { currentUser } = useAuth();

  const getApplication = async () => {
    if (!currentUser) return null;
    
    try {
      setLoading(true);
      const applicationRef = getApplicationRef(currentUser.uid);
      const applicationSnap = await getDoc(applicationRef);
      
      if (applicationSnap.exists()) {
        return applicationSnap.data();
      }
      return null;
    } catch (err) {
      console.error('[useApplication] Error loading application:', err);
      setError(err as Error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const saveApplication = async (data: any) => {
    if (!currentUser) return false;
    
    try {
      setLoading(true);
      const applicationRef = getApplicationRef(currentUser.uid);
      await setDoc(applicationRef, {
        ...data,
        userId: currentUser.uid
      }, { merge: true });
      return true;
    } catch (err) {
      console.error('[useApplication] Error saving application:', err);
      setError(err as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    getApplication,
    saveApplication
  };
};