import React from 'react';
import { Music, FileText, Link as LinkIcon } from 'lucide-react';
import { PortfolioItem } from '../../portfolio/types';

const CONFIG = {
  title: "Portfolio",
  styles: {
    wrapper: "space-y-6",
    title: "font-medium mb-4",
    list: "space-y-4",
    item: {
      wrapper: "bg-[#1D3557]/[0.02] p-4 rounded-lg",
      header: {
        wrapper: "flex items-center gap-3 mb-2",
        icon: "text-[#457B9D]",
        title: "font-medium"
      },
      description: "text-sm text-[#1D3557]/60 mb-4",
      link: "text-sm text-[#E63946] hover:underline"
    }
  }
};

interface PortfolioReviewProps {
  data: {
    items?: PortfolioItem[];
  };
}

const PortfolioReview: React.FC<PortfolioReviewProps> = ({ data }) => {
  if (!data?.items?.length) return null;

  const getIcon = (item: PortfolioItem) => {
    if (item.type === 'file') {
      return item.fileType === 'mp3' ? Music : FileText;
    }
    return LinkIcon;
  };

  const getTypeLabel = (item: PortfolioItem) => {
    if (item.type === 'file') {
      return item.fileType === 'mp3' ? 'Audio Recording' : 'PDF Document';
    }
    return 'External Link';
  };

  return (
    <div className={CONFIG.styles.wrapper}>
      <h4 className={CONFIG.styles.title}>{CONFIG.title}</h4>
      <div className={CONFIG.styles.list}>
        {data.items.map((item, index) => {
          const Icon = getIcon(item);
          
          return (
            <div key={index} className={CONFIG.styles.item.wrapper}>
              <div className={CONFIG.styles.item.header.wrapper}>
                <Icon size={16} className={CONFIG.styles.item.header.icon} />
                <h5 className={CONFIG.styles.item.header.title}>
                  {item.title} ({getTypeLabel(item)})
                </h5>
              </div>
              <p className={CONFIG.styles.item.description}>{item.description}</p>
              <a 
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={CONFIG.styles.item.link}
              >
                View Item
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PortfolioReview;