import React, { createContext, useContext, useState } from 'react';
import { useApplicationData } from '../hooks/application/useApplicationData';
import { useApplicationMutations } from '../hooks/application/useApplicationMutations';

interface ApplicationContextType {
  loading: boolean;
  error: Error | null;
  getApplication: () => Promise<any>;
  saveApplication: (data: any) => Promise<boolean>;
}

const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);

export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error('useApplicationContext must be used within an ApplicationProvider');
  }
  return context;
};

export const ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loading: loadingData, error: loadError, getApplication } = useApplicationData();
  const { saving: savingData, error: saveError, saveApplication } = useApplicationMutations();

  const value = {
    loading: loadingData || savingData,
    error: loadError || saveError,
    getApplication,
    saveApplication
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};