import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const OPPORTUNITIES = [
  {
    title: "Artistic Freedom",
    description: "Fellows receive complete financial coverage for tuition, housing, and meals during the six-week residency, allowing them to focus entirely on their artistic growth.",
    imageId: "b953d9d3-0358-443e-ac66-5059424b704d",
    color: "#E63946"
  },
  {
    title: "Performance Excellence",
    description: "Regular opportunities to perform as featured artists in our concerts, collaborating with distinguished faculty and fellow musicians.",
    imageId: "635f5fab-c3cd-4911-9ebc-43d1f3dddeb6",
    color: "#457B9D"
  },
  {
    title: "Professional Development",
    description: "Gain invaluable experience through mentorship, workshops, and networking opportunities that prepare you for a successful career in music.",
    imageId: "8c74e94c-751d-4754-8dd5-ab9c7e2b5d28",
    color: "#F6BD60"
  }
];

const OpportunitiesSection = () => {
  return (
    <section className="py-32 bg-[#1D3557]/[0.02]">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-7xl mb-24">Fellowship Experience</h2>

        <div className="space-y-32">
          {OPPORTUNITIES.map((opportunity, index) => (
            <div 
              key={index}
              className={`flex flex-col ${
                index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
              } gap-16 items-center`}
            >
              <div className="flex-1 relative">
                <div className="aspect-[4/3] overflow-hidden rounded-lg">
                  <img
                    src={getAssetUrl(opportunity.imageId, {
                      width: 800,
                      height: 600,
                      quality: 85,
                      fit: 'cover'
                    })}
                    alt={opportunity.title}
                    className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                  />
                </div>
                
                {/* Decorative elements */}
                <div 
                  className="absolute -bottom-8 -right-8 w-32 h-32 border-r-2 border-b-2"
                  style={{ borderColor: `${opportunity.color}20` }}
                />
              </div>

              <div className="flex-1">
                <div className="relative pl-12">
                  {/* Vertical line */}
                  <div 
                    className="absolute left-0 top-0 bottom-0 w-1"
                    style={{ 
                      background: `linear-gradient(to bottom, ${opportunity.color}, transparent)` 
                    }}
                  />
                  
                  <h3 className="font-serif text-5xl mb-8">{opportunity.title}</h3>
                  <p className="text-xl text-[#1D3557]/70 leading-relaxed">
                    {opportunity.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OpportunitiesSection;