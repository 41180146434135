import React from 'react';

const AWARDS = [
  {
    title: "Fellowship",
    description: "Our fellowship program provides complete financial coverage for tuition, housing, and meals during a six-week residency. Up to 40 fellowships are awarded annually to emerging artists showing exceptional promise.",
    highlight: "Full Coverage",
    color: "#E63946"
  },
  {
    title: "Teaching Assistant",
    description: "TAs gain valuable teaching experience while working with accomplished faculty. This role includes leading rehearsals, providing instruction, and performing in concerts. Teaching experience required.",
    highlight: "Gain Experience",
    color: "#457B9D"
  },
  {
    title: "Resident Assistant",
    description: "RAs create a positive community environment while pursuing their musical studies. This role includes promoting safety, providing support, and assisting with logistics.",
    highlight: "Build Community",
    color: "#F6BD60"
  },
  {
    title: "Scholarship",
    description: "Partial scholarships are awarded based on merit and financial need to instrumentalists who demonstrate exceptional musical talent and a strong commitment to their craft.",
    highlight: "Merit Based",
    color: "#1D3557"
  }
];

const AwardsSection = () => {
  return (
    <section className="py-32 relative overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <h2 className="font-serif text-[10rem] leading-none mb-24 relative">
          Awards
          <div className="absolute -left-8 -bottom-8 w-24 h-24 border-l-2 border-b-2 border-[#E63946] opacity-20" />
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {AWARDS.map((award, index) => (
            <div 
              key={index}
              className="relative group"
            >
              {/* Card content */}
              <div className="bg-white p-16 rounded-lg shadow-sm transition-all duration-700 group-hover:-translate-y-2">
                <h3 className="font-serif text-4xl mb-6">{award.title}</h3>
                <p className="text-xl text-[#1D3557]/70 leading-relaxed mb-8">
                  {award.description}
                </p>
                
                <span 
                  className="text-lg font-medium"
                  style={{ color: award.color }}
                >
                  {award.highlight}
                </span>
              </div>

              {/* Decorative elements */}
              <div 
                className="absolute -inset-1 blur opacity-0 group-hover:opacity-100 transition-opacity duration-700 -z-10"
                style={{ 
                  background: `linear-gradient(45deg, ${award.color}20, transparent)` 
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Background patterns */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute w-24 h-24 border border-[#1D3557]/[0.02] rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              transform: `scale(${0.5 + Math.random()})`,
              opacity: 0.1 + Math.random() * 0.2
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default AwardsSection;