import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Calendar, MapPin, Clock } from 'lucide-react';
import { Concert } from '../data';
import { generateSlug } from '../utils';

interface ConcertCardProps {
  concert: Concert;
  isEven: boolean;
}

const ConcertCard: React.FC<ConcertCardProps> = ({ concert, isEven }) => {
  const date = new Date(concert.time);
  const slug = generateSlug(concert);
  
  // Use absolute path from public directory
  const thumbnailPath = `/images/concerts/thumbnail/${concert.time}-thumbnail.jpg`;

  return (
    <div className="relative">
      {/* Date Marker */}
      <div className={`absolute top-8 ${isEven ? 'md:left-1/2' : 'left-0 md:left-1/2'} -translate-x-1/2`}>
        <div className="w-4 h-4 rounded-full border-2 border-[#E63946] bg-white" />
      </div>

      {/* Concert Card */}
      <div className={`md:w-1/2 ${isEven ? 'md:pr-16' : 'md:pl-16 md:ml-auto'}`}>
        <Link
          to={`/concerts/${slug}`}
          className="block group"
        >
          <div className="relative aspect-[16/9] overflow-hidden rounded-lg mb-6">
            <img
              src={thumbnailPath}
              alt={concert.title}
              className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-700"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-4 text-sm text-[#457B9D]">
              <div className="flex items-center gap-2">
                <Calendar size={16} className="opacity-60" />
                <span>{format(date, 'MMMM d, yyyy')}</span>
              </div>
              <div className="flex items-center gap-2">
                <Clock size={16} className="opacity-60" />
                <span>{format(date, 'h:mm a')}</span>
              </div>
            </div>

            <h2 className="font-serif text-3xl group-hover:text-[#E63946] transition-colors">
              {concert.title}
            </h2>

            <div className="flex items-center gap-2 text-sm text-[#1D3557]/60">
              <MapPin size={16} />
              <span>{concert.venue}{concert.room ? ` • ${concert.room}` : ''}</span>
            </div>

            {concert.works && concert.works[0] && (
              <div className="text-sm text-[#1D3557]/60 italic">
                Featuring works by {concert.works.map(work => work.composer).join(', ')}
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ConcertCard;