import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/vocal/HeroSection';
import IntroSection from '../components/vocal/IntroSection';
import GallerySection from '../components/vocal/GallerySection';
import ProgramHighlights from '../components/vocal/ProgramHighlights';
import ApplicationSection from '../components/vocal/ApplicationSection';
import FacultySection from '../components/programs/FacultySection';
import programData from '../data/programs/vci.json';

const VocalInstitute = () => {
  // Process faculty data
  const faculty = programData.programs?.[0]?.faculty?.map(f => ({
    name: f.members_id.name,
    profileImage: f.members_id.profile_image?.id,
    biography: f.members_id.biography,
    affiliation: f.members_id.affiliation,
    instruments: f.members_id.instruments?.map(i => ({
      value: i.instruments_id.value,
      label: i.instruments_id.label
    })) || []
  })) || [];

  // Process and sort audition requirements
  const requirements = programData.programs?.[0]?.audition_requirements
    ?.map(req => ({
      requirement: req.audition_requirements_id.requirement,
      sort: req.audition_requirements_id.sort || 0
    }))
    .sort((a, b) => a.sort - b.sort)
    .map(req => req.requirement) || [];

  return (
    <div>
      <Header variant="dark" />
      
      <HeroSection />
      <IntroSection />
      <GallerySection />
      
      <FacultySection
        faculty={faculty}
        title="Distinguished Faculty"
        description="Learn from and work alongside world-renowned vocalists and coaches who are shaping the future of vocal arts."
      />
      
      <ProgramHighlights />
      <ApplicationSection requirements={requirements} />
      
      <Footer />

      <style>{`
        @keyframes voiceWave {
          0% {
            transform: scaleX(0.3) translateY(0);
            opacity: 0.2;
          }
          50% {
            transform: scaleX(1) translateY(-2px);
            opacity: 0.5;
          }
          100% {
            transform: scaleX(0.3) translateY(0);
            opacity: 0.2;
          }
        }
      `}</style>
    </div>
  );
};

export default VocalInstitute;