import React from 'react';
import { ChevronDown } from 'lucide-react';

interface ConcertHeroProps {
  seasonYears: string;
}

const ConcertHero: React.FC<ConcertHeroProps> = ({ seasonYears }) => {
  return (
    <div className="relative min-h-[80vh] flex items-center">
      <div className="absolute inset-0 bg-[#1D3557]">
        <div className="absolute inset-0 opacity-20">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute h-px bg-white/20"
              style={{
                left: 0,
                right: 0,
                top: `${(i + 1) * 5}%`,
                transform: `rotate(${Math.random() * 2 - 1}deg)`
              }}
            />
          ))}
        </div>
      </div>
      
      <div className="relative max-w-screen-2xl mx-auto px-8 w-full">
        <div className="max-w-4xl">
          <h1 className="font-serif text-8xl md:text-9xl text-white mb-8">
            Concert Archives
          </h1>
          <p className="text-2xl text-white/80 font-light leading-relaxed mb-12">
            Explore our rich history of performances from {seasonYears}, featuring world premieres, 
            masterworks, and extraordinary artists.
          </p>
          <div className="flex items-center gap-2 text-white/60">
            <ChevronDown size={20} className="animate-bounce" />
            <span className="text-sm tracking-wide">Scroll to explore</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcertHero;