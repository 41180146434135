import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { subscribeToNewsletter } from '../../services/directus/newsletter';
import toast from 'react-hot-toast';

interface NewsletterFormProps {
  type: 'patron' | 'institute';
}

const CONFIG = {
  patron: {
    title: "Stay Connected",
    description: "Join our community to receive curated updates about performances, special events, and artistic opportunities.",
    buttonText: "Subscribe to Newsletter"
  },
  institute: {
    title: "Stay Informed",
    description: "Receive updates about programs, deadlines, and opportunities for musicians.",
    buttonText: "Subscribe for Updates"
  },
  styles: {
    form: {
      wrapper: "space-y-6",
      input: "w-full px-4 py-3 border border-[#1D3557]/20 rounded-md text-[#1D3557] placeholder:text-[#1D3557]/40 focus:outline-none focus:border-[#E63946] transition-colors",
      button: {
        base: "w-full relative overflow-hidden group",
        container: "block px-6 py-4 border-2 border-[#1D3557]",
        text: "relative z-10 flex items-center justify-center gap-2 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
        background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500",
        disabled: "opacity-50 cursor-not-allowed"
      }
    }
  }
};

const NewsletterForm: React.FC<NewsletterFormProps> = ({ type }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || isSubmitting) return;

    try {
      setIsSubmitting(true);
      const result = await subscribeToNewsletter(email, type);

      if (result.success) {
        toast.success(result.message);
        setEmail('');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Failed to subscribe. Please try again later.');
      console.error('Newsletter subscription failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={CONFIG.styles.form.wrapper}>
      <h3 className="font-serif text-4xl mb-4">{CONFIG[type].title}</h3>
      <p className="text-[#1D3557]/70 mb-8">{CONFIG[type].description}</p>

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className={CONFIG.styles.form.input}
          disabled={isSubmitting}
          required
        />

        <button
          type="submit"
          disabled={isSubmitting}
          className={`${CONFIG.styles.form.button.base} ${
            isSubmitting ? CONFIG.styles.form.button.disabled : ''
          }`}
        >
          <span className={CONFIG.styles.form.button.container}>
            <span className={CONFIG.styles.form.button.text}>
              {isSubmitting ? 'Subscribing...' : CONFIG[type].buttonText}
              <ArrowRight size={16} className="group-hover:translate-x-2 transition-transform duration-500" />
            </span>
          </span>
          <div className={CONFIG.styles.form.button.background} />
        </button>
      </form>
    </div>
  );
};

export default NewsletterForm;