import React from 'react';
import { getAssetUrl } from '../../utils/directus';

const OPPORTUNITIES = [
  {
    title: "The Orchestra",
    description: "Stand before the AMF Orchestra, where each gesture shapes symphonic masterworks into living, breathing experiences. This is where theory meets practice, where your movements translate into music.",
    imageId: "e6fa3a25-c9dd-434c-a3f8-9c1cb2a52af1"
  },
  {
    title: "New Music",
    description: "Pioneer the future of classical music through our Contemporary Ensemble. Work directly with living composers, bringing their visions to life in world premieres that push the boundaries of musical expression.",
    imageId: "6bc2ea85-d7c0-4b4f-8c35-4bdcfc134da6"
  },
  {
    title: "Chamber Music",
    description: "Discover the intimate art of chamber conducting, where precision meets poetry. Lead diverse ensembles in works that demand both technical mastery and profound musical understanding.",
    imageId: "7602470d-9c02-44d8-9d35-79e280088ef6"
  }
];

const OpportunitiesSection = () => {
  return (
    <section className="py-32 relative overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="space-y-40">
          {OPPORTUNITIES.map((opp, index) => (
            <div 
              key={index}
              className={`flex flex-col ${
                index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
              } gap-16 items-center`}
            >
              <div className="flex-1 relative">
                <div className="aspect-[4/3] overflow-hidden">
                  <img
                    src={getAssetUrl(opp.imageId, {
                      width: 800,
                      height: 600,
                      quality: 85,
                      fit: 'cover'
                    })}
                    alt={opp.title}
                    className="w-full h-full object-cover scale-105 hover:scale-100 transition-transform duration-700"
                  />
                </div>
                {/* Decorative elements */}
                <div className="absolute -bottom-4 -right-4 w-24 h-24 border-r-2 border-b-2 border-[#E63946]" />
                <div className="absolute -top-4 -left-4 w-24 h-24 border-l-2 border-t-2 border-[#457B9D]" />
              </div>

              <div className="flex-1 relative">
                {/* Conducting gesture line */}
                <div 
                  className="absolute -left-8 top-0 bottom-0 w-px"
                  style={{
                    background: 'linear-gradient(to bottom, #E63946, transparent)'
                  }}
                />
                
                <div className="pl-8">
                  <h3 className="font-serif text-6xl mb-8">{opp.title}</h3>
                  <p className="text-xl text-[#1D3557]/70 leading-relaxed">
                    {opp.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Background conducting gestures */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px"
            style={{
              background: `linear-gradient(90deg, transparent, rgba(29, 53, 87, ${0.03 - i * 0.005}), transparent)`,
              top: `${15 + i * 15}%`,
              transform: `rotate(${-20 + i * 8}deg) scaleX(${0.8 + i * 0.1})`,
              animation: 'gesture-flow 8s ease-in-out infinite',
              animationDelay: `${i * 0.5}s`
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default OpportunitiesSection;