import { DIRECTUS_CONFIG } from '../../config/directus';

interface NewsletterResponse {
  success: boolean;
  message: string;
}

export async function subscribeToNewsletter(email: string, type: 'patron' | 'institute'): Promise<NewsletterResponse> {
  try {
    const response = await fetch('/api/newsletter/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, type })
    });

    // Always try to parse the response as JSON first
    const data = await response.json().catch(() => ({
      success: false,
      message: 'Invalid server response'
    }));

    if (!response.ok) {
      return {
        success: false,
        message: data.message || 'Failed to subscribe to newsletter'
      };
    }

    return {
      success: true,
      message: data.message || 'Successfully subscribed to newsletter'
    };
  } catch (error) {
    console.error('Newsletter subscription failed:', error);
    return {
      success: false,
      message: 'Failed to connect to server. Please try again later.'
    };
  }
}