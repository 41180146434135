import React from 'react';
import { useAnnouncements } from '../contexts/AnnouncementContext';

const CONFIG = {
  styles: {
    wrapper: "fixed bottom-0 left-0 right-0 z-50",
    announcement: {
      base: "px-4 py-3 text-center backdrop-blur-sm border-t transition-colors duration-300",
      light: "bg-white/95 text-[#1D3557] text-sm font-medium border-[#1D3557]/10",
      dark: "bg-black/95 text-white text-sm font-medium border-white/10"
    }
  }
};

const Announcements = () => {
  const { announcements } = useAnnouncements();

  if (!announcements || announcements.length === 0) return null;

  return (
    <div className={CONFIG.styles.wrapper}>
      {announcements.map((announcement) => (
        <div 
          key={announcement.id}
          className={`
            ${CONFIG.styles.announcement.base}
            ${announcement.variant === 'light' 
              ? CONFIG.styles.announcement.light 
              : CONFIG.styles.announcement.dark}
          `}
        >
          {announcement.message}
        </div>
      ))}
    </div>
  );
};

export default Announcements;