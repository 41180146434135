import React from 'react';
import { ArrowRight, Calendar } from 'lucide-react';
import { getAssetUrl, IMAGE_SIZES } from '../utils/directus';

const CONFIG = {
  title: "Next Performance",
  event: {
    date: "July 4, 2025",
    time: "19:00",
    venue: "TBD",
    title: "AMF Orchestra Concert",
    description: "Join us for our opening concert of the 2025 season",
    image: getAssetUrl("08de166b-8920-403c-9987-1e790fa876f9", IMAGE_SIZES.feature)
  },
  button: {
    text: "Seat Reservations Coming Spring 2025",
    url: "/concerts"
  },
  styles: {
    section: "py-8 md:py-10 mb-8 md:mb-16 bg-[#1D3557]/[0.04]",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    grid: "grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16 items-center",
    title: {
      wrapper: "col-span-1 md:col-span-3",
      text: "font-serif text-3xl md:text-4xl relative inline-block",
      accent: "absolute -left-4 -bottom-4 w-12 h-12 bg-[#F6BD60] opacity-20 -z-10"
    },
    content: {
      wrapper: "col-span-1 md:col-span-6 space-y-4",
      datetime: {
        wrapper: "flex items-center gap-3 text-[#457B9D]",
        icon: "opacity-60",
        text: "text-sm tracking-widest"
      },
      title: "font-serif text-xl md:text-2xl",
      description: "font-light text-sm leading-relaxed tracking-wide text-[#1D3557]/80"
    },
    button: {
      wrapper: "col-span-1 md:col-span-3 flex justify-start md:justify-end",
      base: "relative overflow-hidden group inline-block",
      container: "block px-6 py-3 border-2 border-[#1D3557]",
      text: "relative z-10 flex items-center gap-3 text-sm tracking-wide group-hover:text-white transition-colors duration-500",
      background: "absolute inset-0 bg-[#1D3557] -translate-y-full group-hover:translate-y-0 transition-transform duration-500",
      icon: "group-hover:translate-x-2 transition-transform duration-500"
    }
  }
};

const NextConcert: React.FC = () => {
  return (
    <section className={CONFIG.styles.section}>
      <div className={CONFIG.styles.container}>
        <div className={CONFIG.styles.grid}>
          <div className={CONFIG.styles.title.wrapper}>
            <h3 className={CONFIG.styles.title.text}>
              {CONFIG.title}
              <div className={CONFIG.styles.title.accent} />
            </h3>
          </div>

          <div className={CONFIG.styles.content.wrapper}>
            <div className={CONFIG.styles.content.datetime.wrapper}>
              <Calendar size={16} className={CONFIG.styles.content.datetime.icon} />
              <span className={CONFIG.styles.content.datetime.text}>
                {CONFIG.event.date} • {CONFIG.event.time}
              </span>
            </div>
            <h4 className={CONFIG.styles.content.title}>{CONFIG.event.title}</h4>
            <p className={CONFIG.styles.content.description}>{CONFIG.event.description}</p>
          </div>

          <div className={CONFIG.styles.button.wrapper}>
            <button 
              disabled
              className={`${CONFIG.styles.button.base} opacity-50 cursor-not-allowed`}
            >
              <span className={CONFIG.styles.button.container}>
                <span className={CONFIG.styles.button.text}>
                  {CONFIG.button.text}
                  <ArrowRight size={14} className={CONFIG.styles.button.icon} />
                </span>
              </span>
              <div className={CONFIG.styles.button.background} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextConcert;