import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplication } from '../hooks/useApplication';
import Header from '../components/Header';

const CONFIG = {
  title: "My Account",
  description: "Track your application status and manage your account.",
  newApplication: {
    title: "Start Your Application",
    description: "Begin your journey with the Atlantic Music Festival by submitting your application.",
    button: "Start Application"
  },
  existingApplication: {
    title: "Application Status",
    button: {
      draft: "Continue Application",
      submitted: "View Application"
    }
  },
  styles: {
    wrapper: "min-h-screen bg-gray-50",
    container: "max-w-4xl mx-auto px-4 py-12",
    header: {
      title: "text-4xl font-serif mb-2",
      description: "text-gray-600 mb-8"
    },
    content: "space-y-8",
    card: {
      wrapper: "bg-white p-8 rounded-lg shadow-sm border border-gray-100",
      title: "text-2xl font-serif mb-4",
      description: "text-gray-600 mb-6",
      button: {
        primary: "px-6 py-3 bg-[#1D3557] text-white hover:bg-[#1D3557]/90 transition-colors",
        secondary: "px-6 py-3 border border-[#1D3557] text-[#1D3557] hover:bg-[#1D3557] hover:text-white transition-colors"
      }
    }
  }
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { application, loading } = useApplication();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#1D3557]"></div>
      </div>
    );
  }

  return (
    <div className={CONFIG.styles.wrapper}>
      <Header />
      <div className={CONFIG.styles.container}>
        <div className={CONFIG.styles.header}>
          <h1 className={CONFIG.styles.header.title}>{CONFIG.title}</h1>
          <p className={CONFIG.styles.header.description}>{CONFIG.description}</p>
        </div>

        <div className={CONFIG.styles.content}>
          <div className={CONFIG.styles.card.wrapper}>
            {!application ? (
              <>
                <h2 className={CONFIG.styles.card.title}>{CONFIG.newApplication.title}</h2>
                <p className={CONFIG.styles.card.description}>{CONFIG.newApplication.description}</p>
                <button
                  onClick={() => navigate('/account/application')}
                  className={CONFIG.styles.card.button.primary}
                >
                  {CONFIG.newApplication.button}
                </button>
              </>
            ) : (
              <>
                <h2 className={CONFIG.styles.card.title}>{CONFIG.existingApplication.title}</h2>
                <p className={CONFIG.styles.card.description}>
                  Status: <span className="font-medium">{application.status?.state || 'Draft'}</span>
                </p>
                <button
                  onClick={() => navigate('/account/application')}
                  className={CONFIG.styles.card.button.secondary}
                >
                  {application.status?.state === 'submitted' 
                    ? CONFIG.existingApplication.button.submitted 
                    : CONFIG.existingApplication.button.draft}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;