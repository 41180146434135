import React from 'react';
import { ArrowRight, Twitter, Facebook, Instagram } from 'lucide-react';
import NewsletterForm from './newsletter/NewsletterForm';

const CONFIG = {
  social: {
    text: "Follow Us",
    links: [
      { icon: Twitter, url: "https://twitter.com/atlanticmusicfestival", label: "Twitter" },
      { icon: Facebook, url: "https://facebook.com/atlanticmusicfestival", label: "Facebook" },
      { icon: Instagram, url: "https://instagram.com/atlanticmusicfestival", label: "Instagram" }
    ]
  },
  styles: {
    section: "py-16 md:py-32",
    container: "max-w-screen-2xl mx-auto px-8 md:px-16",
    grid: "grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-16",
    title: {
      wrapper: "col-span-1 md:col-span-5",
      text: "font-serif text-4xl md:text-6xl relative inline-block mb-4 md:mb-0",
      accent: "absolute -left-4 md:-left-8 -bottom-4 md:-bottom-8 w-12 md:w-16 h-12 md:h-16 bg-[#F6BD60] opacity-20 -z-10"
    },
    form: {
      wrapper: "col-span-1 md:col-span-7 space-y-8"
    },
    divider: {
      wrapper: "flex items-center gap-4",
      line: "block w-8 h-[1px] bg-[#1D3557]/20",
      text: "text-sm tracking-wide text-[#1D3557]/40"
    },
    social: {
      wrapper: "flex flex-wrap items-center gap-4",
      text: "text-sm tracking-wide text-[#1D3557]/60",
      link: "text-[#1D3557]/60 hover:text-[#E63946] transition-colors p-2"
    }
  }
};

const Newsletter: React.FC = () => {
  return (
    <section className={CONFIG.styles.section}>
      <div className={CONFIG.styles.container}>
        <div className={CONFIG.styles.grid}>
          {/* Title */}
          <div className={CONFIG.styles.title.wrapper}>
            <div className="space-y-8">
              <h3 className={CONFIG.styles.title.text}>
                Stay Connected
                <div className={CONFIG.styles.title.accent} />
              </h3>
            </div>
          </div>

          {/* Form */}
          <div className={CONFIG.styles.form.wrapper}>
            <NewsletterForm type="patron" />

            <div className={CONFIG.styles.divider.wrapper}>
              <span className={CONFIG.styles.divider.line} />
              <span className={CONFIG.styles.divider.text}>or</span>
              <span className={CONFIG.styles.divider.line} />
            </div>

            <div className={CONFIG.styles.social.wrapper}>
              <span className={CONFIG.styles.social.text}>{CONFIG.social.text}</span>
              {CONFIG.social.links.map((link, index) => {
                const Icon = link.icon;
                return (
                  <a 
                    key={index}
                    href={link.url}
                    className={CONFIG.styles.social.link}
                    aria-label={link.label}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon size={18} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;