import React from 'react';
import { getAssetUrl } from '../utils/directus';

interface PageHeaderProps {
  title: string;
  description?: string;
  imageId?: string;
  variant?: 'dark' | 'light';
  children?: React.ReactNode;
}

const CONFIG = {
  styles: {
    wrapper: "relative -mt-[72px]",
    background: {
      wrapper: "absolute inset-0 h-[45vh] overflow-hidden border-b border-[#1D3557]/10",
      image: "w-full h-full object-cover object-center",
      overlay: {
        dark: "absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-transparent",
        light: "absolute inset-0 bg-gradient-to-b from-white/90 via-white/80 to-transparent"
      }
    },
    content: {
      wrapper: "relative z-10 pt-[calc(72px+6rem)] pb-16 max-w-screen-2xl mx-auto px-8 md:px-16",
      title: {
        dark: "font-serif text-6xl md:text-8xl text-white mb-12",
        light: "font-serif text-6xl md:text-8xl text-[#1D3557] mb-12"
      },
      description: {
        dark: "font-light text-xl text-white/90 max-w-2xl leading-relaxed",
        light: "font-light text-xl text-[#1D3557]/80 max-w-2xl leading-relaxed"
      }
    }
  },
  image: {
    width: 1920,
    height: 1080,
    quality: 90,
    fit: 'cover' as const
  }
};

const PageHeader: React.FC<PageHeaderProps> = ({ 
  title, 
  description,
  imageId,
  variant = 'dark',
  children 
}) => {
  return (
    <div className={CONFIG.styles.wrapper}>
      {imageId && (
        <div className={CONFIG.styles.background.wrapper}>
          <img
            src={getAssetUrl(imageId, CONFIG.image)}
            alt=""
            className={CONFIG.styles.background.image}
          />
          <div className={CONFIG.styles.background.overlay[variant]} />
        </div>
      )}
      
      <div className={CONFIG.styles.content.wrapper}>
        <h1 className={CONFIG.styles.content.title[variant]}>{title}</h1>
        {description && (
          <p className={CONFIG.styles.content.description[variant]}>{description}</p>
        )}
        {children}
      </div>
    </div>
  );
};

export default PageHeader;