import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { NavigationItem } from '../types/navigation';
import { APPLICATION_CONFIG } from '../config/application';

interface MobileMenuProps {
  isOpen: boolean;
  navigationItems: NavigationItem[];
  applicationDeadline: string;
  applicationDeadlineLabel: string;
  variant?: 'light' | 'dark';
}

const CONFIG = {
  styles: {
    menu: {
      wrapper: "fixed inset-x-0 top-[72px] z-40 border-t border-[#1D3557]/10 transition-all duration-300 ease-in-out overflow-hidden",
      light: "bg-white",
      dark: "bg-[#1D3557]"
    },
    nav: {
      wrapper: "px-8 py-6 space-y-6",
      section: {
        wrapper: "space-y-4",
        title: "text-sm font-medium opacity-60",
        list: "space-y-4 pl-4"
      }
    },
    button: {
      base: "flex items-center justify-between w-full group",
      text: {
        light: "text-xl font-serif text-[#1D3557] group-hover:text-[#E63946]",
        dark: "text-xl font-serif text-white group-hover:text-[#F6BD60]"
      },
      icon: "transition-transform duration-300"
    },
    link: {
      base: "block text-lg font-serif",
      light: "text-[#1D3557] hover:text-[#E63946]",
      dark: "text-white hover:text-[#F6BD60]"
    },
    sublink: {
      base: "block text-lg font-serif",
      light: "text-[#1D3557] hover:text-[#E63946]",
      dark: "text-white hover:text-[#F6BD60]"
    },
    footer: {
      wrapper: "px-8 pt-8 mt-8 border-t border-[#1D3557]/10",
      deadline: {
        label: "block text-[10px] tracking-[0.2em] opacity-40 mb-1",
        date: "block text-sm font-light"
      },
      button: {
        wrapper: "mt-6 relative overflow-hidden group w-full",
        container: "block px-6 py-4 border-2 text-center",
        text: "relative z-10 text-sm tracking-wide transition-colors duration-500"
      }
    }
  }
};

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  navigationItems,
  applicationDeadline,
  applicationDeadlineLabel,
  variant = 'light'
}) => {
  const [openSubmenu, setOpenSubmenu] = useState<number | null>(null);

  const textColorClass = variant === 'light' ? 'text-[#1D3557]' : 'text-white';
  const bgColorClass = variant === 'light' ? 'bg-white' : 'bg-[#1D3557]';
  const borderColorClass = variant === 'light' ? 'border-[#1D3557]' : 'border-white';

  const renderProgramsSubmenu = () => (
    <div className="pl-4 pt-2 space-y-8">
      {/* Pre-College Program */}
      <div className={CONFIG.styles.nav.section.wrapper}>
        <h3 className={`${CONFIG.styles.nav.section.title} ${textColorClass}`}>
          Pre-College Program
        </h3>
        <div className={CONFIG.styles.nav.section.list}>
          <a 
            href="/the-institute/pre-college"
            className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}
          >
            Pre-College Program
          </a>
        </div>
      </div>

      {/* Collegiate & Professional Programs */}
      <div className={CONFIG.styles.nav.section.wrapper}>
        <h3 className={`${CONFIG.styles.nav.section.title} ${textColorClass}`}>
          Collegiate & Professional Programs
        </h3>
        <div className={CONFIG.styles.nav.section.list}>
          <a href="/the-institute/programs/composition-program" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Composition Program
          </a>
          <a href="/the-institute/programs/conducting-program" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Conducting Program
          </a>
          <a href="/the-institute/programs/instrumental-program" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Instrumental Program
          </a>
          <a href="/the-institute/programs/piano-institute-and-seminar" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Piano Institute
          </a>
          <a href="/the-institute/programs/vocal-institute" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Vocal Arts Institute
          </a>
        </div>
      </div>

      {/* Fellowship Programs */}
      <div className={CONFIG.styles.nav.section.wrapper}>
        <h3 className={`${CONFIG.styles.nav.section.title} ${textColorClass}`}>
          Fellowship Programs
        </h3>
        <div className={CONFIG.styles.nav.section.list}>
          <a href="/the-institute/programs/fellowship-program" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Resident Artist Fellowship
          </a>
          <a href="/the-institute/programs/fellowship-program/contemporary-ensemble" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Contemporary Ensemble
          </a>
        </div>
      </div>

      {/* Resources */}
      <div className={CONFIG.styles.nav.section.wrapper}>
        <h3 className={`${CONFIG.styles.nav.section.title} ${textColorClass}`}>
          Resources
        </h3>
        <div className={CONFIG.styles.nav.section.list}>
          <a href="/the-institute/online-application" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Online Application
          </a>
          <a href="/the-institute/financial-aid" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Financial Aid
          </a>
          <a href="/the-institute/residency-guidelines" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Residency Guidelines
          </a>
          <a href="/the-institute/fees-dates-and-deadlines" className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}>
            Fees & Deadlines
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div 
      className={`${CONFIG.styles.menu.wrapper} ${CONFIG.styles.menu[variant]}
                ${isOpen ? 'h-[calc(100vh-72px)] opacity-100' : 'h-0 opacity-0'}`}
    >
      <div className="h-full overflow-y-auto">
        <nav className={CONFIG.styles.nav.wrapper}>
          {navigationItems.map((item, index) => (
            <div key={index}>
              {item.items ? (
                <div className="space-y-4">
                  <button 
                    onClick={() => setOpenSubmenu(openSubmenu === index ? null : index)}
                    className={CONFIG.styles.button.base}
                  >
                    <span className={CONFIG.styles.button.text[variant]}>
                      {item.label}
                    </span>
                    <ChevronDown 
                      className={`${CONFIG.styles.button.icon} ${textColorClass}
                              ${openSubmenu === index ? 'rotate-180' : ''}`}
                      size={16}
                    />
                  </button>
                  
                  <div 
                    className={`overflow-hidden transition-all duration-300 ease-in-out
                            ${openSubmenu === index ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
                  >
                    {item.label === 'Programs' ? renderProgramsSubmenu() : (
                      <div className="pl-4 pt-2 space-y-8">
                        {item.items.map((section, idx) => (
                          <div key={idx} className={CONFIG.styles.nav.section.wrapper}>
                            <h3 className={`${CONFIG.styles.nav.section.title} ${textColorClass}`}>
                              {section.section}
                            </h3>
                            <div className={CONFIG.styles.nav.section.list}>
                              {section.items.map((subItem, subIdx) => (
                                <a 
                                  key={subIdx} 
                                  href={subItem.url}
                                  className={CONFIG.styles.sublink.base + ' ' + CONFIG.styles.sublink[variant]}
                                >
                                  {subItem.label}
                                </a>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <a 
                  href={item.url} 
                  className={CONFIG.styles.link.base + ' ' + CONFIG.styles.link[variant]}
                >
                  {item.label}
                </a>
              )}
            </div>
          ))}
        </nav>

        <div className={CONFIG.styles.footer.wrapper}>
          <div className={textColorClass}>
            <span className={CONFIG.styles.footer.deadline.label}>
              {applicationDeadlineLabel}
            </span>
            <span className={CONFIG.styles.footer.deadline.date}>
              {applicationDeadline}
            </span>
          </div>

          <a 
            href={APPLICATION_CONFIG.status.isOpen ? "/the-institute/online-application" : "/the-institute"}
            className={CONFIG.styles.footer.button.wrapper}
          >
            <span className={`${CONFIG.styles.footer.button.container} ${borderColorClass}`}>
              <span className={`${CONFIG.styles.footer.button.text} ${textColorClass} 
                           group-hover:text-${variant === 'light' ? 'white' : '[#1D3557]'}`}>
                {APPLICATION_CONFIG.status.isOpen ? 'Apply Now' : 'The Institute'}
              </span>
            </span>
            <div className={`absolute inset-0 ${variant === 'light' ? 'bg-[#1D3557]' : 'bg-white'} 
                         -translate-y-full group-hover:translate-y-0 transition-transform duration-500`} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;