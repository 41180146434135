import React from 'react';

const IntroSection = () => {
  return (
    <section className="py-24 md:py-32">
      <div className="max-w-screen-2xl mx-auto px-8 md:px-16">
        <div className="max-w-4xl mx-auto">
          <p className="text-2xl leading-relaxed text-[#1D3557]/80 first-letter:text-7xl first-letter:font-serif first-letter:text-[#E63946] first-letter:float-left first-letter:mr-3 first-letter:mt-1">
            The Vocal Arts Institute is a transformative summer program for young singers. Participants immerse themselves in honing their vocal technique, interpretive skills, and dramatic abilities under the guidance of world-renowned faculty.
          </p>
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 text-lg text-[#1D3557]/70 leading-relaxed">
            <div>
              <h3 className="font-serif text-xl mb-4">Week 1</h3>
              <p>At the beginning of each session, singers undergo assessments and intensive training in diverse musical styles. Each singer sets goals for their progress throughout the session.</p>
            </div>
            <div>
              <h3 className="font-serif text-xl mb-4">Week 2</h3>
              <p>Concert performance focusing on art songs and arias. With expert coaching, singers develop their abilities in diction, text, phrasing, and emotional expression.</p>
            </div>
            <div>
              <h3 className="font-serif text-xl mb-4">Week 3</h3>
              <p>Lyric theater performance culminating from preparation in acting workshops, character development, and staging sessions.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;