import React from 'react';

interface PaymentContainerProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const PaymentContainer: React.FC<PaymentContainerProps> = ({
  title,
  description,
  children
}) => {
  return (
    <div className="mt-12 p-6 bg-[#1D3557] text-white rounded-lg">
      <h3 className="font-serif text-xl mb-2">{title}</h3>
      <p className="text-sm text-white/60 mb-6">{description}</p>
      {children}
    </div>
  );
};