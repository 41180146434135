import React from 'react';
import { useArtistContext } from '../../contexts/ArtistContext';
import ArtistCard from './ArtistCard';
import ArtistModal from './ArtistModal';
import { ARTIST_CONFIG } from '../../config/artists';

const ArtistGrid = () => {
  const { filteredArtists } = useArtistContext();

  return (
    <>
      {filteredArtists.length > 0 ? (
        <div className={ARTIST_CONFIG.styles.grid}>
          {filteredArtists.map((artist) => (
            <ArtistCard key={artist.id} artist={artist} />
          ))}
        </div>
      ) : (
        <p className={ARTIST_CONFIG.styles.empty}>{ARTIST_CONFIG.emptyMessage}</p>
      )}
      <ArtistModal />
    </>
  );
};

export default ArtistGrid;