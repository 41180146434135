import React, { useCallback } from 'react';
import AcademicBackground from './AcademicBackground';
import HonorsAchievements from './HonorsAchievements';
import ResumeUpload from './ResumeUpload';

interface EducationProps {
  formData: {
    academic?: Array<{
      currentlyEnrolled: boolean;
      yearsAttended: string;
      schoolName: string;
      degree: string;
    }>;
    honorsAchievements?: string[];
    resumeUrl?: string;
  };
  onChange: (data: Partial<EducationProps['formData']>, isUpload?: boolean) => void;
}

const defaultEducation = {
  academic: [{
    currentlyEnrolled: false,
    yearsAttended: '',
    schoolName: '',
    degree: ''
  }],
  honorsAchievements: [''],
  resumeUrl: ''
};

const Education: React.FC<EducationProps> = ({ 
  formData = defaultEducation,
  onChange 
}) => {
  const handleAcademicChange = useCallback((academic: typeof defaultEducation.academic) => {
    onChange({ ...formData, academic });
  }, [formData, onChange]);

  const handleHonorsChange = useCallback((honorsAchievements: string[]) => {
    onChange({ ...formData, honorsAchievements });
  }, [formData, onChange]);

  const handleResumeChange = useCallback((resumeUrl: string, isUpload: boolean = false) => {
    console.log('[Education] Resume URL updated:', { resumeUrl, isUpload });
    onChange({ 
      ...formData,
      resumeUrl 
    }, isUpload);
  }, [formData, onChange]);

  return (
    <div className="space-y-12">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-6">Education</h3>
        <div className="space-y-8">
          <AcademicBackground
            academic={formData.academic || defaultEducation.academic}
            onChange={handleAcademicChange}
          />
          <HonorsAchievements
            honors={formData.honorsAchievements || defaultEducation.honorsAchievements}
            onChange={handleHonorsChange}
          />
          <ResumeUpload
            resumeUrl={formData.resumeUrl}
            onChange={handleResumeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Education;