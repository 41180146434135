import React from 'react';
import { useApplication } from '../../../hooks/useApplication';
import { APPLICATION_CONFIG } from '../../../config/application';
import { PaymentProcessor } from '../../payments/PaymentProcessor';
import { PaymentContainer } from '../../payments/PaymentContainer';

const CONFIG = {
  title: "Application Fee",
  description: "A non-refundable application fee is required to submit your application."
};

export const ApplicationPayment: React.FC = () => {
  const { saveApplication } = useApplication();

  const handleBeforeCheckout = async () => {
    // Update application status before initiating checkout
    await saveApplication({
      status: 'pending_payment',
      lastUpdated: new Date().toISOString()
    });
  };

  const handleError = async () => {
    // Reset application status on error
    await saveApplication({
      status: 'draft',
      lastUpdated: new Date().toISOString()
    });
  };

  return (
    <PaymentContainer
      title={CONFIG.title}
      description={CONFIG.description}
    >
      <PaymentProcessor
        amount={APPLICATION_CONFIG.fees.application}
        currency={APPLICATION_CONFIG.fees.currency}
        onBeforeCheckout={handleBeforeCheckout}
        onError={handleError}
      />
    </PaymentContainer>
  );
};